import orderBy from 'lodash/orderBy'
import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'

export const SOURCE_ICONS = {
  Airbnb: '$airbnb',
  'Booking.com': '$booking',
  manual: '$manual',
}

export const orderTasks = tasks => {
  return orderBy(
    tasks,
    ['allDay', 'scheduled_at', 'priorityOrder'],
    ['asc', 'asc', 'asc']
  )
}

export const mapTaskOrder = tasks => {
  const orderedTasks = orderTasks(tasks)
  return orderedTasks.reduce((acc, task) => {
    const contractor = task.assigned_contractor_id || 'unassigned'
    acc[contractor]
      ? acc[contractor].push(task.id)
      : (acc[contractor] = [task.id])
    return acc
  }, {})
}

export const FILTER_OPTIONS = [
  {
    value: 'today_checkins',
    text: 'Today Checkins',
  },
  {
    value: 'today_checkouts',
    text: 'Today Checkouts',
  },
  {
    value: 'free_today',
    text: 'Free Today',
  },
]

export const getTaskOrder = (task, taskOrder) => {
  if (!taskOrder[task.assigned_contractor_id]) return
  const order = taskOrder[task.assigned_contractor_id].indexOf(task.id)
  return order + 1
}

export const processEvents = (data, from, to) => {
  const { reservations, listings } = data
  const events = reservations.map(r => {
    const title = r.source === 'Owner' ? 'Owner Vacation' : r.guest?.full_name
    return {
      ...r,
      resourceId: r.listing_id,
      title,
      textColor: 'white',
      start: moment(r.check_in).tz('UTC').format('YYYY-MM-DD'),
      end: moment(r.check_out).tz('UTC').format('YYYY-MM-DD'),
      balanceDue: !!r.balance_due && r.is_merchant,
      classNames: [
        'reservation-bar',
        'ellipsis-1',
        'event-reservation',
        r.status,
      ],
    }
  })

  // Generate date range array between from and to
  const dates = []
  let currentDate = moment(from)
  const endDate = moment(to)
  while (currentDate.isBefore(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'))
    currentDate.add(1, 'days')
  }

  // Process listings and their availability
  listings?.forEach(l => {
    if (!l.days_rates) return

    let ind = 0
    while (ind < dates.length) {
      const d = dates[ind]
      const currentRate = l.days_rates[d]
      if (!currentRate) {
        ind++
        continue
      }

      const isSmartRules =
        currentRate.note && currentRate.note.includes('Smart Rules')
      if (!isSmartRules && currentRate.status === 'unavailable') {
        const startDay = d
        let currentDay = d
        while (
          l.days_rates[currentDay] &&
          l.days_rates[currentDay].status === 'unavailable' &&
          l.days_rates[startDay].note === l.days_rates[currentDay].note &&
          l.days_rates[currentDay]['block_type'] ===
            l.days_rates[startDay]['block_type']
        ) {
          ind++
          currentDay = dates[ind]
        }

        const isOverlap = reservations.some(
          res =>
            res.listing_id === l.id &&
            moment(res.check_in).startOf('day').isSameOrBefore(startDay) &&
            moment(res.check_out).isSameOrAfter(dates[ind] || dates[ind - 1])
        )

        if (isOverlap) continue

        const otaBlock =
          l.days_rates[dates[ind - 1]].block_type &&
          l.days_rates[dates[ind - 1]].block_type === 'ota_auto_block'
        const smartBlock =
          l.days_rates[dates[ind - 1]].block_type &&
          l.days_rates[dates[ind - 1]].block_type === 'smart_rules'

        const blockClass =
          otaBlock || smartBlock ? 'availability-block' : 'block-bg'
        events.push({
          title: l.days_rates[dates[ind - 1]].note || 'N/A',
          resourceId: l.id,
          listingId: l.id,
          viewType: 'reservation',
          isBlocked: true,
          isOtaBlock: otaBlock,
          start: startDay,
          end: dates[ind] || dates[ind - 1],
          classNames: [blockClass, 'ellipsis-1', 'event-reservation'],
          id: Math.random(),
        })
        continue
      }
      ind++
    }
  })

  return events
}

export const processResources = listings => {
  const resourceMap = new Map()
  const topLevel = []

  // First pass: Create all resource objects
  listings.forEach(l => {
    resourceMap.set(l.id, {
      id: l.id,
      title: l.nickname,
      extendedProps: {
        color: l.color || '#009788',
        tags: l.tags,
        region: l.region_id,
        listed: l.listed,
        source: l.source,
      },
      children: [],
    })
  })

  // Second pass: Build hierarchy
  listings.forEach(listing => {
    const resource = resourceMap.get(listing.id)

    if (listing.hotel_id && resourceMap.has(listing.hotel_id)) {
      const hotel_listing = resourceMap.get(listing.hotel_id)
      hotel_listing.children.push(resource)
      hotel_listing.extendedProps.isHotel = true
    } else if (
      listing.container_multi_id &&
      resourceMap.has(listing.container_multi_id)
    ) {
      const multi_listing = resourceMap.get(listing.container_multi_id)
      multi_listing.children.push(resource)
      multi_listing.extendedProps.isMultiUnit = true
    } else {
      topLevel.push(resource)
    }
  })

  return topLevel.map(resource => {
    let resourceOrder = 1
    if (resource.children.some(c => c.children.length)) resourceOrder = 3
    else if (resource.children.length) resourceOrder = 2
    return { ...resource, resourceOrder }
  })
}

export const filterResources = (initialResources, filters) => {
  const { searchTerm, selectedTags, region, listedOnly, listingId } = filters
  let filteredResources = cloneDeep(initialResources)

  if (listingId) {
    filteredResources = filteredResources.filter(resource => {
      return resource.id === listingId
    })
  }

  if (searchTerm) {
    filteredResources = filteredResources.filter(resource => {
      return (
        resource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resource.children.some(child =>
          child.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    })
  }

  if (selectedTags.length) {
    filteredResources = filteredResources.filter(resource => {
      return (
        resource.extendedProps.tags.some(tag => selectedTags.includes(tag)) ||
        resource.children.some(child =>
          child.extendedProps.tags.some(tag => selectedTags.includes(tag))
        )
      )
    })
  }
  if (region) {
    filteredResources = filteredResources.filter(resource => {
      return resource.extendedProps.region === region
    })
  }
  if (!listedOnly) return filteredResources

  const filterChildren = children =>
    children.filter(child => child.extendedProps.listed)

  filteredResources = filteredResources.filter(resource => {
    // If the resource itself isn't listed and has no listed children, filter it out
    if (!resource.extendedProps.listed) {
      if (resource.extendedProps.isHotel) {
        // For hotels, check if any multi-unit or its children are listed
        const listedMultiUnits = resource.children.filter(
          multiUnit =>
            multiUnit.extendedProps.listed ||
            multiUnit.children.some(unit => unit.extendedProps.listed)
        )
        if (listedMultiUnits.length === 0) return false

        // Filter multi-units to only keep those that are listed or have listed children
        resource.children = listedMultiUnits.map(multiUnit => ({
          ...multiUnit,
          children: filterChildren(multiUnit.children),
        }))
      } else if (resource.extendedProps.isMultiUnit) {
        // For multi-units, check if any children are listed
        const listedChildren = filterChildren(resource.children)
        if (listedChildren.length === 0) return false
        resource.children = listedChildren
      } else {
        return false
      }
    }
    // If we get here, either the resource is listed or it has listed children
    return true
  })
  return filteredResources
}

export const initializeScroller = ({
  calendarId,
  calApi,
  onVisibleResourcesChange,
}) => {
  const el = document.getElementById(calendarId)

  el.addEventListener('scroll', () => {
    const resourceCells = el.querySelectorAll('.fc-resource')
    const visibleResources = []

    const scrollTop = el.scrollTop
    const viewportHeight = el.clientHeight
    const bufferZone = viewportHeight * 0.5
    const viewportBottom = scrollTop + viewportHeight + bufferZone

    resourceCells.forEach(cell => {
      const rect = cell.getBoundingClientRect()
      const resourceTop = rect.top + scrollTop
      const resourceBottom = resourceTop + rect.height

      if (resourceBottom > scrollTop && resourceTop < viewportBottom) {
        const resourceId =
          cell.getAttribute('role') === 'gridcell' &&
          cell.getAttribute('data-resource-id')
        const resource = calApi.getResourceById(resourceId)
        if (resource) {
          visibleResources.push({
            id: resourceId,
            title: resource.title,
            extendedProps: resource.extendedProps,
          })
        }
      }
    })
    onVisibleResourcesChange(visibleResources)
    return visibleResources
  })
}
