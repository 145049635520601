import { render, staticRenderFns } from "./sample-reservation-data.vue?vue&type=template&id=ea2b2d10&scoped=true&"
import script from "./sample-reservation-data.vue?vue&type=script&lang=js&"
export * from "./sample-reservation-data.vue?vue&type=script&lang=js&"
import style0 from "./sample-reservation-data.vue?vue&type=style&index=0&id=ea2b2d10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea2b2d10",
  null
  
)

export default component.exports