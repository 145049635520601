<template>
  <v-card class="sample-reservation-data" outlined>
    <v-card-title class="text-subtitle-1 px-4 py-2">
      <div class="d-flex align-center w-100">
        <span>{{ $t('Sample Reservation Data') }}</span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" x-small class="ml-2" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ $t('Values used for formula calculations') }}</span>
        </v-tooltip>
        <v-spacer />
        <v-btn
          x-small
          icon
          @click="expanded = !expanded"
          :aria-label="expanded ? 'Collapse' : 'Expand'"
        >
          <v-icon x-small>
            {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <v-expand-transition>
      <v-card-text v-show="expanded" class="px-4 py-2">
        <v-list dense class="transparent">
          <template v-for="(variable, index) in groupedVariables">
            <v-subheader
              v-if="variable.category"
              :key="variable.category"
              class="px-0 text-caption font-weight-medium primary--text"
            >
              {{ variable.category }}
            </v-subheader>
            <template v-for="item in variable.items">
              <v-list-item
                :key="item.value"
                dense
                class="px-0"
                :class="{ 'mt-2': variable.category }"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-caption">
                    {{ item.value }}
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" x-small class="ml-1" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>{{ item.description }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="text-right">
                  <span class="text-caption">
                    {{ formatValue(sampleData[item.value]) }}
                  </span>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < formulaVariables.length - 1"
                :key="`div-${item.value}`"
                class="my-1"
              />
            </template>
          </template>
        </v-list>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'SampleReservationData',

  mixins: [FormattersMixin],

  props: {
    formulaVariables: {
      type: Array,
      required: true,
    },
    sampleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      expanded: false,
    }
  },

  computed: {
    groupedVariables() {
      const groups = this.formulaVariables.reduce((acc, variable) => {
        const category = variable.category || 'Other'
        if (!acc[category]) {
          acc[category] = {
            category,
            items: [],
          }
        }
        acc[category].items.push(variable)
        return acc
      }, {})

      return Object.values(groups)
    },
  },
  methods: {
    formatValue(value) {
      if (typeof value === 'number') {
        if (value % 1 === 0) {
          return value.toString()
        }
        return value
      }
      return value
    },
  },
}
</script>

<style lang="scss" scoped>
.sample-reservation-data {
  .v-list-item {
    min-height: 24px !important;
  }

  .v-subheader {
    height: 24px;
  }
}
</style>
