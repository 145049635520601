<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <div class="text-h6 font-weight-bold primary--text">
          Chart of Accounts
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mr-4"
        ></v-text-field>
        <ledger-account-create-modal @created="fetchAccounts" />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="processedAccounts"
        :search="search"
        :loading="loading"
        class="elevation-1"
        :sort-by="['code']"
        :sort-desc="[false]"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
      >
        <template #item.code="{ item }">
          <div :class="{ 'pl-4': item.level === 1, 'pl-8': item.level === 2 }">
            {{ item.code }}
          </div>
        </template>

        <template #item.name="{ item }">
          <div :class="{ 'pl-4': item.level === 1, 'pl-8': item.level === 2 }">
            {{ item.name }}
          </div>
        </template>

        <template #item.balance="{ item }">
          <span
            :class="[
              getBalanceClass(item),
              { 'pl-4': item.level === 1, 'pl-8': item.level === 2 },
            ]"
          >
            {{ $options.filters.currency(item.balance) }}
          </span>
        </template>

        <template #item.account_type="{ item }">
          <v-chip
            small
            :color="getAccountTypeColor(item.account_type)"
            :text-color="getAccountTypeTextColor(item.account_type)"
            label
            outlined
            class="ml-4"
          >
            {{ item.account_type }}
          </v-chip>
        </template>

        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="primary"
                v-bind="attrs"
                class="mr-2"
                @click="viewTransactions(item)"
                v-on="on"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('ledger.view_transactions') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="info"
                v-bind="attrs"
                class="mr-2"
                @click="editAccount(item)"
                v-on="on"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('ledger.edit') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="error"
                v-bind="attrs"
                @click="confirmDelete(item)"
                v-on="on"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('common.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t('ledger.confirm_delete_title') }}
        </v-card-title>
        <v-card-text>
          {{
            $t('ledger.confirm_delete_message', { name: selectedAccount?.name })
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            color="error"
            text
            :loading="deleteLoading"
            @click="deleteAccount"
          >
            {{ $t('common.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Edit Dialog -->
    <v-dialog v-model="editDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t('ledger.edit_account') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm" v-model="editFormValid">
            <v-text-field
              v-model="editedAccount.code"
              :label="$t('ledger.account_code')"
              :rules="[v => !!v || $t('common.required')]"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedAccount.name"
              :label="$t('ledger.account_name')"
              :rules="[v => !!v || $t('common.required')]"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="editDialog = false">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="editLoading"
            :disabled="!editFormValid"
            @click="updateAccount"
          >
            {{ $t('common.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import axios from 'axios'
import LedgerAccountCreateModal from './ledger-account-create-modal'
import Toaster from '@/utils/toaster'

export default {
  name: 'LedgerAccounts',
  components: {
    LedgerAccountCreateModal,
  },
  mixins: [CommonFunctions],

  filters: {
    currency(value) {
      if (!value) return '$0.00'
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    },
  },

  data: () => ({
    search: '',
    loading: false,
    deleteDialog: false,
    deleteLoading: false,
    selectedAccount: null,
    accounts: [],
    headers: [
      { text: 'Code', value: 'code', width: '120' },
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'account_type', width: '150' },
      { text: 'Balance', value: 'balance', sortable: true, width: '150' },
      { text: 'Actions', value: 'actions', sortable: false, width: '150' },
    ],
    editDialog: false,
    editLoading: false,
    editFormValid: true,
    editedAccount: {
      id: null,
      code: '',
      name: '',
    },
  }),

  computed: {
    processedAccounts() {
      // Group accounts by their parent codes
      const accountGroups = {}
      const processedAccounts = []

      // First pass: identify parent accounts and create groups
      this.accounts.forEach(account => {
        const parentCode = this.getParentCode(account.code)
        if (parentCode) {
          if (!accountGroups[parentCode]) {
            accountGroups[parentCode] = []
          }
          accountGroups[parentCode].push({ ...account, level: 1 })
        } else {
          processedAccounts.push({ ...account, level: 0 })
        }
      })

      // Second pass: add child accounts under their parents
      processedAccounts.forEach(account => {
        const children = accountGroups[account.code] || []
        const index = processedAccounts.indexOf(account)
        if (index !== -1 && children.length > 0) {
          processedAccounts.splice(index + 1, 0, ...children)
        }
      })

      return processedAccounts
    },
  },

  methods: {
    getParentCode(code) {
      // Check if this is a sub-account by looking at the last two digits
      const lastTwoDigits = code.slice(-2)
      if (lastTwoDigits === '00') return null // This is a parent account

      // Get the parent code by replacing the last two digits with '00'
      const parentCode = code.slice(0, -2) + '00'
      return this.accounts.some(a => a.code === parentCode) ? parentCode : null
    },

    async fetchAccounts() {
      this.loading = true
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts
      } catch (error) {
        console.error('Error fetching accounts:', error)
        this.$store.dispatch('showError', 'Failed to load accounts')
      } finally {
        this.loading = false
      }
    },

    getAccountTypeColor(type) {
      const colors = {
        asset: 'light-blue',
        liability: 'pink',
        equity: 'purple',
        income: 'light-green',
        expense: 'deep-orange',
      }
      return colors[type] ? `${colors[type]} lighten-4` : 'grey lighten-4'
    },

    getAccountTypeTextColor(type) {
      const colors = {
        asset: 'light-blue darken-2',
        liability: 'pink darken-2',
        equity: 'purple darken-2',
        income: 'light-green darken-2',
        expense: 'deep-orange darken-2',
      }
      return colors[type] || 'grey darken-2'
    },

    getBalanceClass(account) {
      const balance = parseFloat(account.balance)
      if (balance === 0) return ''

      switch (account.account_type) {
        case 'asset':
        case 'expense':
        case 'income':
          return balance > 0 ? 'success--text' : 'error--text'
        case 'liability':
        case 'equity':
          return balance > 0 ? 'error--text' : 'success--text'
        default:
          return ''
      }
    },

    viewTransactions(account) {
      this.$router.push(`/dashboard/management/ledger-accounts/${account.id}`)
    },

    confirmDelete(account) {
      this.selectedAccount = account
      this.deleteDialog = true
    },

    async deleteAccount() {
      this.deleteLoading = true
      try {
        await axios.delete(`/api/ledger-accounts/${this.selectedAccount.id}`)
        this.$store.dispatch('showSuccess', this.$t('ledger.delete_success'))
        this.deleteDialog = false
        this.fetchAccounts()
      } catch (error) {
        console.error('Error deleting account:', error)
        this.$store.dispatch('showError', this.$t('ledger.delete_error'))
      } finally {
        this.deleteLoading = false
      }
    },

    editAccount(account) {
      this.editedAccount = {
        id: account.id,
        code: account.code,
        name: account.name,
      }
      this.editDialog = true
    },

    async updateAccount() {
      if (!this.$refs.editForm.validate()) return

      this.editLoading = true
      try {
        await axios.patch(`/api/ledger-accounts/${this.editedAccount.id}`, {
          ledger_account: {
            code: this.editedAccount.code,
            name: this.editedAccount.name,
          },
        })

        Toaster.show([
          { type: 'success', text: this.$t('ledger.update_success') },
        ])
        this.editDialog = false
        this.fetchAccounts()
      } catch (error) {
        console.error('Error updating account:', error)
        const errorMessage =
          error.response?.data?.error || this.$t('ledger.update_error')
        Toaster.show([{ type: 'error', text: errorMessage }])
      } finally {
        this.editLoading = false
      }
    },
  },

  created() {
    this.fetchAccounts()
  },
}
</script>

<style scoped>
.v-card {
  width: 100%;
}

.v-data-table {
  width: 100%;
}

.v-data-table ::v-deep tbody tr:hover {
  background-color: var(--v-primary-lighten5) !important;
}
</style>
