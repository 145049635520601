<template>
  <div v-show="!loading">
    <div class="grey lighten-4">
      <v-container fluid class="max-w-1600 pb-0">
        <v-tabs
          v-model="activeTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
          :grow="isMobile"
        >
          <v-tab v-if="showModule('accounting')" href="#general-ledger">
            <v-icon class="mr-md-2">mdi-file-document-multiple-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize">
              General Ledger
            </span>
          </v-tab>
          <v-tab v-if="showModule('accounting')" href="#ledger">
            <v-icon class="mr-md-2">mdi-book-open-variant</v-icon>
            <span class="d-none d-md-inline text-capitalize">
              Chart of Accounts
            </span>
          </v-tab>
          <v-tab v-if="showModule('accounting')" href="#ledger-lines">
            <v-icon class="mr-md-2">mdi-cog-transfer-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize">
              Account Lines
            </span>
          </v-tab>
          <v-tab v-if="showModule('accounting')" href="#pnl-report">
            <v-icon class="mr-md-2">mdi-chart-line</v-icon>
            <span class="d-none d-md-inline text-capitalize">P&L Report</span>
          </v-tab>
        </v-tabs>
      </v-container>
    </div>

    <div>
      <v-tabs-items v-model="activeTab" touchless>
        <v-tab-item value="ledger">
          <ledger-accounts />
        </v-tab-item>
        <v-tab-item value="general-ledger">
          <general-ledger />
        </v-tab-item>
        <v-tab-item value="ledger-lines">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <ledger-account-lines ref="ledgerLines" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="pnl-report">
          <pnl-report />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import DeviceMixin from 'components/mixins/device-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import LedgerAccounts from './ledger-accounts'
import GeneralLedger from './general-ledger'
import LedgerAccountLines from './ledger-account-lines'
import PnlReport from './pnl-report'

export default {
  name: 'LedgerWallet',

  components: {
    LedgerAccounts,
    GeneralLedger,
    LedgerAccountLines,
    PnlReport,
  },

  mixins: [DeviceMixin, CommonFunctions, PermissionsMixin],

  data: () => ({
    loading: false,
    activeTab: 'general-ledger',
  }),

  methods: {
    refreshLines() {
      if (this.$refs.ledgerLines) {
        this.$refs.ledgerLines.fetchLedgerLines()
      }
    },
  },
}
</script>
