<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="close">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <template v-if="action === 'transfer'">
              <v-col cols="12">
                <v-select
                  v-model="form.toAccountId"
                  :items="accounts"
                  item-text="name"
                  item-value="id"
                  :label="$t('accounting.bulk_transfer.to_account')"
                  :rules="[
                    v => !!v || $t('common.required'),
                    v =>
                      !sourceAccountIds.includes(v) ||
                      $t('accounting.manual_transfer.different_account'),
                  ]"
                  outlined
                  required
                >
                  <template #item="{ item }">
                    <div>
                      {{ item.name }}
                      <span class="grey--text text--darken-1 ml-2">
                        ({{ item.code }})
                      </span>
                    </div>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="form.postedAt"
                      :label="$t('accounting.bulk_transfer.posted_at')"
                      readonly
                      outlined
                      :rules="[v => !!v || $t('common.required')]"
                      required
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.postedAt"
                    no-title
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  :label="$t('accounting.bulk_transfer.description')"
                  :rules="[v => !!v || $t('common.required')]"
                  outlined
                  required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th>
                          {{ $t('accounting.bulk_transfer.from_account') }}
                        </th>
                        <th>{{ $t('accounting.bulk_transfer.amount') }}</th>
                        <th>{{ $t('accounting.bulk_transfer.line_code') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="line in selectedLines" :key="line.id">
                        <td>{{ line.ledger_account_name }}</td>
                        <td>{{ formatCurrency(line.amount) }}</td>
                        <td>{{ line.line_code }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </template>
            <!-- Add other action templates here -->
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="submit"
        >
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import Toaster from '@/utils/toaster'

export default {
  name: 'BulkActionDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array,
      required: true,
    },
    selectedLines: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
      validator: value => ['transfer'].includes(value), // Add more actions as needed
    },
  },

  data() {
    return {
      valid: false,
      loading: false,
      dateMenu: false,
      form: {
        toAccountId: null,
        postedAt: new Date().toISOString().substr(0, 10),
        description: null,
      },
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    sourceAccountIds() {
      return this.selectedLines.map(line => line.ledger_account_id)
    },

    filteredAccounts() {
      return this.accounts.filter(
        account => !this.sourceAccountIds.includes(account.id)
      )
    },
  },

  methods: {
    formatCurrency(amount) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount)
    },

    async submit() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      try {
        if (this.action === 'transfer') {
          const transfers = this.selectedLines.map(line => ({
            fromAccountId: line.ledger_account_id,
            amount: line.amount,
            lineId: line.id,
          }))

          await axios.post('/api/ledger-transactions/bulk_manual_transfer', {
            ...this.form,
            transfers,
          })
        }
        // Add other action handlers here

        this.$emit('success')
        this.close()
        Toaster.show([
          {
            type: 'success',
            text: this.$t(`accounting.bulk_${this.action}.success`),
          },
        ])
      } catch (error) {
        Toaster.show([
          {
            type: 'error',
            text: error.response?.data?.error || this.$t('common.error'),
          },
        ])
      } finally {
        this.loading = false
      }
    },

    close() {
      this.dialog = false
      this.$refs.form.reset()
    },
  },
}
</script>
