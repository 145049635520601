<template>
  <v-data-table
    :sort-by="['name']"
    :items="pricingStrategies"
    :headers="strategyHeaders"
    :search="searchTerm"
    v-on="$listeners"
  >
    <template #top>
      <v-row class="px-3 align-center">
        <v-col cols="6" md="2">
          <v-text-field
            v-model="searchTerm"
            :label="'Search by name...'"
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn
            color="primary"
            class="text-capitalize"
            @click="addPricingStrategy"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Pricing Strategy
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #item="{ item }">
      <tr class="align-center">
        <td>
          <div class="font-weight-medium">{{ item.name }}</div>
        </td>
        <td>
          <div>
            <span class="font-weight-medium">{{ item.listings.length }}</span>
            <span class="text-capitalize">&nbsp;Listings</span>
          </div>
        </td>
        <td class="text-sm">
          <v-btn
            color="primary"
            class="text-capitalize"
            icon
            elevation="0"
            @click="editPricingStrategy(item)"
          >
            <v-icon size="25">mdi-pencil</v-icon>
          </v-btn>
          <confirmation-modal
            :title="'Delete Pricing Strategy'"
            :text="
              'Are you sure you want to delete strategy &quot;' +
              item.name +
              '&quot;?'
            "
            @action="deletePricingStrategy(item.id)"
          >
            <template #activator="{ on }">
              <v-btn
                color="warning"
                class="text-capitalize"
                :disabled="item.listings.length > 0"
                icon
                elevation="0"
                v-on="on"
              >
                <v-icon size="25">$trash</v-icon>
              </v-btn>
            </template>
          </confirmation-modal>
          <confirmation-modal
            :title="'Re-apply Strategy'"
            :text="
              'Are you sure you want to re-apply strategy &quot;' +
              item.name +
              '&quot;?'
            "
            @action="applyPricingStrategy(item.id)"
          >
            <template #activator="{ on }">
              <v-btn
                color="green"
                class="text-capitalize"
                :disabled="item.listings.length === 0"
                icon
                elevation="0"
                v-on="on"
              >
                <v-icon size="25">mdi-sync</v-icon>
              </v-btn>
            </template>
          </confirmation-modal>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'PricingStrategyTable',

  components: {
    ConfirmationModal,
  },

  props: ['headers', 'fetchAction', 'items'],

  data() {
    return {
      searchTerm: '',
    }
  },

  async created() {
    await this.getPricingStrategies({
      include_listings: true,
      include_default: true,
    })
  },

  methods: {
    ...mapActions('pricingStrategy', [
      'getPricingStrategies',
      'deletePricingStrategy',
      'applyPricingStrategy',
    ]),

    addPricingStrategy() {
      this.$store.commit('showModal', {
        name: 'PricingStrategyModal',
        props: { strategy: null, defaultFactors: this.defaultFactors },
        isPersistent: true,
        maxWidth: 900,
      })
    },

    editPricingStrategy(item) {
      this.$store.commit('showModal', {
        name: 'PricingStrategyModal',
        props: { strategy: item },
        isPersistent: true,
        maxWidth: 900,
      })
    },
  },

  computed: {
    ...mapState('pricingStrategy', ['pricingStrategies', 'defaultFactors']),

    strategyHeaders() {
      return [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Listings',
          value: 'listings_ids',
          sortable: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
}
</script>
