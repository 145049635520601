<template>
  <v-card elevation="0" class="text-center">
    <div v-if="!emailSent">
      <h3 class="text-h4 font-weight-bold text-center mb-5">
        Forgot your password?
      </h3>
      <p class="mb-10">
        Enter your email address and we will send you a link to reset your
        password.
      </p>
      <v-form ref="forgotPassword" lazy-validation @submit="forgotPassword">
        <div v-if="emailSent" class="text-body-1 success--text">
          Email sent successfully to {{ emailPassword }}
        </div>
        <v-text-field
          v-else
          v-model="emailPassword"
          name="email"
          :rules="emailRules"
          :error-messages="[errorMessage]"
          label="Email"
          type="text"
          outlined
          dense
          @input="errorMessage = ''"
        />
        <v-select
          v-if="tenants.length"
          v-model="selectedTenant"
          :items="tenants"
          item-text="name"
          item-value="id"
          :rules="[required]"
          label="Select Tenant"
          outlined
          dense
        />

        <v-row class="flex-row-reverse mt-3">
          <v-col cols="6" class="py-0">
            <v-btn
              class="text-capitalize"
              color="primary"
              type="submit"
              :loading="emailLoading"
              block
              elevation="0"
              large
            >
              Confirm
            </v-btn>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-btn
              class="text-capitalize"
              color="primary"
              block
              outlined
              large
              @click="onClose"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-else class="text-center">
      <v-icon :size="isMobile ? 180 : 260">$email_sent</v-icon>
      <h3 class="text-h5 text-sm-h4 font-weight-bold mb-5">
        Email has been sent
      </h3>
      <p class="mb-6">
        Please, check your <b>{{ emailPassword }}</b> inbox/spam and click the
        received link to reset a password.
      </p>
      <v-btn
        class="text-capitalize mb-4"
        color="primary"
        elevation="0"
        block
        large
        @click="onClose"
      >
        OK
      </v-btn>
      <div class="d-flex justify-center align-center">
        Didn't receive the link?
        <v-btn
          color="primary"
          class="text-capitalize"
          text
          @click="emailSent = false"
        >
          Resend
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import formRules from './mixins/form-rules-mixin'
import deviceMixin from './mixins/device-mixin'
import get from 'lodash/fp/get'
export default {
  name: 'ForgotPassword',
  mixins: [formRules, deviceMixin],
  data() {
    return {
      dialog: false,
      emailPassword: '',
      errorMessage: '',
      emailSent: false,
      emailLoading: false,
      tenants: [],
      selectedTenant: null,
    }
  },
  methods: {
    forgotPassword(e) {
      e.preventDefault()
      if (this.$refs.forgotPassword.validate()) {
        this.emailLoading = true
        axios
          .post('/api/forgot-password', {
            email: this.emailPassword,
            tenant_id: this.selectedTenant,
          })
          .then(({ data }) => {
            if (data.tenants) {
              this.tenants = data.tenants
            } else {
              this.emailSent = true
            }
            this.emailLoading = false
          })
          .catch(error => {
            this.errorMessage = get('response.data.error', error)
            this.emailLoading = false
            this.emailSent = false
            console.log(error)
          })
      }
    },
    onClose() {
      this.emailPassword = ''
      this.emailLoading = false
      this.emailSent = false
      this.$emit('on-forgot-password-close')
    },
  },
}
</script>

<style scoped></style>
