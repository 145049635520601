<template>
  <v-data-table
    :sort-by="['name']"
    :items="ratePlans"
    :headers="headers"
    :search="searchTerm"
    :loading="loading"
  >
    <template #top>
      <v-row class="px-3 align-center">
        <v-col v-if="listing" cols="12">
          <h3 class="text-h6 mb-4">Rate Plans for {{ listing.nickname }}</h3>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field
            v-model="searchTerm"
            label="Search rate plans..."
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn color="primary" class="text-capitalize" @click="addRatePlan">
            <v-icon left>mdi-plus</v-icon>
            Add Rate Plan
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template #item="{ item }">
      <tr class="align-center">
        <td>
          <div class="font-weight-medium">
            {{ item.name }}
          </div>
        </td>
        <td>{{ item.code }}</td>
        <td>{{ item.pricing_offset }}</td>
        <td>
          <v-chip
            :color="item.is_property_specific ? 'primary' : 'grey'"
            small
            label
          >
            {{ item.is_property_specific ? 'Property Specific' : 'Global' }}
          </v-chip>
        </td>
        <td>
          <div>
            <span class="font-weight-medium">{{
              item.listings?.length || 0
            }}</span>
            <span class="text-capitalize">&nbsp;Listings</span>
          </div>
        </td>
        <td class="text-sm">
          <v-btn
            color="primary"
            class="text-capitalize"
            icon
            elevation="0"
            @click="editRatePlan(item)"
          >
            <v-icon size="25">mdi-pencil</v-icon>
          </v-btn>
          <confirmation-modal
            title="Delete Rate Plan"
            :text="
              'Are you sure you want to delete rate plan &quot;' +
              item.name +
              '&quot;?'
            "
            @action="deleteRatePlan(item.id)"
          >
            <template #activator="{ on }">
              <v-btn
                color="warning"
                class="text-capitalize"
                icon
                elevation="0"
                v-on="on"
              >
                <v-icon size="25">$trash</v-icon>
              </v-btn>
            </template>
          </confirmation-modal>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'RatePlansTable',

  components: {
    ConfirmationModal,
  },

  props: {
    listing: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      searchTerm: '',
      loading: false,
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Code', value: 'code', sortable: true },
        { text: 'Price Offset (%)', value: 'pricing_offset', sortable: true },
        { text: 'Scope', value: 'is_property_specific', sortable: true },
        { text: 'Listings', value: 'listings', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },

  computed: {
    ...mapState('ratePlan', ['ratePlans', 'availableListings']),
  },

  async created() {
    this.loading = true
    try {
      await this.getRatePlans({
        include_listings: true,
        listing_id: this.listing?.id,
      })
      await this.getAvailableListings()
    } catch (error) {
      console.error('Error initializing rate plans:', error)
    }
    this.loading = false
  },

  methods: {
    ...mapActions('ratePlan', [
      'getRatePlans',
      'getAvailableListings',
      'deleteRatePlan',
    ]),

    addRatePlan() {
      this.$store.commit('showModal', {
        name: 'RatePlanModal',
        props: { ratePlan: null },
        isPersistent: true,
        maxWidth: 800,
      })
    },

    editRatePlan(ratePlan) {
      this.$store.commit('showModal', {
        name: 'RatePlanModal',
        props: { ratePlan },
        isPersistent: true,
        maxWidth: 800,
      })
    },

    async deleteRatePlan(id) {
      await this.$store.dispatch('ratePlan/deleteRatePlan', {
        id,
        listing_id: this.listing?.id,
      })
    },
  },
}
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-x: auto;
}
</style>
