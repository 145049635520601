<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn-toggle
          v-model="selectedSettings"
          class="flex-column d-flex py-4 px-2"
          color="primary"
        >
          <v-btn :disabled="!tenantSettings" value="billing">Billing</v-btn>
          <v-btn :disabled="!tenantSettings" value="bi">BI</v-btn>
          <v-btn :disabled="!tenantSettings" value="messaging">Messaging</v-btn>
          <v-btn :disabled="!tenantSettings" value="import">Import</v-btn>
          <v-btn :disabled="!tenantSettings" value="ai">AI</v-btn>
          <v-btn :disabled="!tenantSettings" value="bulk_actions"
            >Bulk Actions</v-btn
          >
          <v-btn :disabled="!tenantSettings" value="add_ons">Add-ons</v-btn>
          <v-btn :disabled="!tenantSettings" value="info">Tenant Info</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="9">
        <v-row class="w-100 pa-2">
          <div class="d-flex align-center justify-space-between w-100">
            <div class="d-flex flex-center">
              <v-autocomplete
                v-model="selectedTenant"
                :items="tenants"
                :label="$t('Tenant')"
                dense
                :disabled="!tenants.length"
                item-text="name"
                item-value="id"
                hide-details
                return-object
                outlined
                class="d-inline-block my-2"
                @change="fetchTenantSettings"
              />
              <div v-if="selectedSettings === 'billing'" class="ms-6">
                <div class="pa-2">
                  <tenants-table />
                </div>
              </div>
            </div>
            <div class="mt-4 mb-2">
              <v-btn
                v-if="selectedTenant.id"
                color="primary"
                depressed
                @click="save"
              >
                Save</v-btn
              >
            </div>
          </div>
        </v-row>
        <div v-if="loading" class="w-100 text-center">
          <v-progress-circular
            :width="2"
            :size="25"
            class="ml-2"
            indeterminate
            color="primary"
          />
        </div>
        <v-row v-else class="w-100 pa-2">
          <tenant-billing
            v-if="selectedSettings === 'billing' && billing"
            v-model="billing"
            @fetch-settings="fetchTenantSettings"
          />
          <tenant-bi v-if="selectedSettings === 'bi'" v-model="bi" />
          <tenant-messaging
            v-if="selectedSettings === 'messaging'"
            v-model="messaging"
            :tenant-id="selectedTenant.id"
          />

          <div v-if="selectedSettings === 'import'" class="w-100">
            <v-row>
              <v-col cols="12" class="mb-4">
                <v-card>
                  <v-card-title>Import Listings</v-card-title>
                  <v-card-text>
                    <div class="pa-4">
                      <div class="d-flex flex-column">
                        <v-btn-toggle
                          v-model="selectedImportType"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn value="create">Create New Listings</v-btn>
                          <v-btn value="update">Update Existing Listings</v-btn>
                        </v-btn-toggle>

                        <v-btn
                          color="primary"
                          class="mb-6"
                          :loading="loadingTemplate"
                          :disabled="loadingTemplate"
                          @click="
                            selectedImportType === 'create'
                              ? downloadTemplate(selectedImportType)
                              : (showColumnDialog = true)
                          "
                        >
                          <v-icon left>mdi-download</v-icon>
                          Download
                          {{
                            selectedImportType === 'create'
                              ? 'Create'
                              : 'Update'
                          }}
                          Template
                        </v-btn>

                        <v-file-input
                          v-model="listingsFile"
                          :label="`Upload ${
                            selectedImportType === 'create' ? 'New' : 'Update'
                          } Listings Excel or CSV`"
                          accept=".xlsx,.csv"
                          :loading="uploadingListings"
                          outlined
                          dense
                          @change="
                            () => handleListingsFileUpload(selectedImportType)
                          "
                        />
                      </div>
                    </div>

                    <div v-if="listingsImportErrors.length" class="mt-4 pa-4">
                      <p
                        class="red--text text-subtitle-1 font-weight-bold mb-2"
                      >
                        Import Errors:
                      </p>
                      <ul class="error-list">
                        <li
                          v-for="(error, i) in listingsImportErrors"
                          :key="i"
                          class="red--text mb-1"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <tenant-reservations :tenant="selectedTenant.id" />
              </v-col>
              <v-col cols="12">
                <tenant-users :tenant="selectedTenant.id" />
              </v-col>
            </v-row>
          </div>

          <tenant-ai
            v-if="selectedSettings === 'ai'"
            :settings="tenantSettings"
            :tenant-id="selectedTenant.id"
          />
          <bulk-actions
            v-if="selectedSettings === 'bulk_actions'"
            :tenant="selectedTenant.id"
          />
          <add-ons v-if="selectedSettings === 'add_ons'" v-model="addOns" />
          <tenant-info
            v-if="selectedSettings === 'info'"
            :tenant="selectedTenant"
            @deactivate="deactivateTenant"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showValidationDialog" max-width="800">
      <v-card>
        <v-card-title>Import Validation Results</v-card-title>
        <v-card-text>
          <div v-if="validationResults.header_errors?.length" class="mb-4">
            <p class="red--text font-weight-bold">Header Errors:</p>
            <ul>
              <li
                v-for="(error, i) in validationResults.header_errors"
                :key="'h' + i"
                class="red--text"
              >
                {{ error }}
              </li>
            </ul>
          </div>

          <div v-if="validationResults.invalid_rows?.length" class="mb-4">
            <p class="red--text font-weight-bold">Row Errors:</p>
            <ul>
              <li
                v-for="error in validationResults.invalid_rows"
                :key="error.row"
                class="red--text"
              >
                Row {{ error.row }}: {{ error.error }}
              </li>
            </ul>
          </div>

          <p class="success--text">
            Valid rows: {{ validationResults.valid_rows?.length || 0 }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!validationResults.valid_rows?.length"
            @click="proceedWithValidRows"
          >
            Import Valid Rows Only
          </v-btn>
          <v-btn
            color="warning"
            :disabled="
              validationResults.header_errors?.length > 0 ||
              !validationResults.valid_rows?.length
            "
            @click="proceedWithAllRows"
          >
            Import All Rows
          </v-btn>
          <v-btn @click="showValidationDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showColumnDialog" max-width="1000">
      <v-card>
        <v-card-title>Select Template Columns</v-card-title>
        <v-card-text class="height-500">
          <v-row class="align-center">
            <v-col cols="2">
              <v-checkbox
                v-model="selectAllColumns"
                label="Select All"
                class="mt-0"
                @change="toggleAllColumns"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="columnSearch"
                outlined
                dense
                label="Search columns"
                prepend-inner-icon="mdi-magnify"
                clearable
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="6" />
          </v-row>
          <v-row class="overflow-y-auto" style="max-height: 400px">
            <v-col
              v-for="column in filteredColumns"
              :key="column.value"
              cols="4"
            >
              <v-checkbox
                v-model="column.selected"
                :hide-details="true"
                :disabled="['External ID*', 'Nickname'].includes(column.label)"
                :label="column.label"
                @change="updateSelectAll"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showColumnDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="proceedWithColumnSelection"
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TenantBi from './tenant-bi.vue'
import tenantBilling from 'components/tenant-settings/tenant-billing.vue'
import TenantMessaging from 'components/tenant-settings/tenant-messaging.vue'
import axios from '@/axios/config'
import customAxios from '@/axios/config'
import TenantsTable from 'components/tenant-settings/tenants-table.vue'
import TenantReservations from 'components/tenant-settings/tenant-reservations.vue'
import TenantUsers from 'components/tenant-settings/tenant-users.vue'
import TenantAi from './tenant-ai.vue'
import Toaster from '@/utils/toaster'
import BulkActions from 'components/tenant-settings/bulk-actions.vue'
import AddOns from 'components/tenant-settings/add-ons.vue'
import { downloadFile } from '@/utils/common-utils'
import TenantInfo from 'components/tenant-settings/tenant-info.vue'
import { TEMPLATE_COLUMNS } from '@/consts'

export default {
  components: {
    TenantInfo,
    tenantBilling,
    TenantBi,
    TenantMessaging,
    TenantsTable,
    TenantReservations,
    TenantAi,
    BulkActions,
    AddOns,
    TenantUsers,
  },
  name: 'TenantSettings',
  data() {
    return {
      loading: false,
      selectedSettings: 'billing',
      tenants: [],
      tenantSettings: null,
      selectedTenant: {},
      reportsItems: [],
      reports: [],
      approvedModules: [],
      bi: null,
      billing: null,
      messaging: null,
      tenantsStatus: false,
      ai: null,
      addOns: {
        invoices: {
          isActiveSpaceInvoice: false,
          isSpaceInvoiceAutoGeneration: false,
        },
      },
      listingsFile: null,
      uploadingListings: false,
      listingsImportErrors: [],
      loadingTemplate: false,
      showValidationDialog: false,
      validationResults: {},
      importType: null,
      selectedImportType: 'create',
      listingsCount: 0,
      showColumnDialog: false,
      selectAllColumns: false,
      columns: TEMPLATE_COLUMNS,
      columnSearch: '',
    }
  },
  mounted() {
    this.fetchTenants()
  },
  methods: {
    ...mapActions('tenants', ['getTenants', 'getTenantSettings']),
    ...mapActions('billing', ['prevMonthInvoice']),

    async fetchTenants() {
      const { data } = await this.getTenants()
      this.tenants = data
    },
    async fetchTenantSettings(tenant) {
      if (!tenant) return
      this.loading = true
      const { data: settings } = await this.getTenantSettings(tenant.id)

      this.listingsCount = settings.listings_count
      this.tenantSettings = settings

      const { data: invoiceData } = await this.prevMonthInvoice({
        tenant_id: settings.tenant_id,
      })

      this.billing = {
        ...settings.boom_payment_model,
        billing_info: settings.billing_info,
        approved_modules: settings.approved_modules,
        tenant_id: settings.tenant_id,
        company_name: settings.company_name,
        paymentMethodId: settings.payment_method_id,
        last4: settings.payment_method && settings.payment_method.last4,
        invoice: {
          lastInvoice: invoiceData.invoice,
          charge: invoiceData.charge,
          invoiceYear: invoiceData.year,
          invoiceMonth: invoiceData.month,
        },
      }
      this.messaging = {
        twilio_credentials: settings.twilio_credentials,
        twilio_phone: settings.twilio_phone,
        messaging_email: settings.messaging_email,
      }

      this.addOns = {
        invoices: {
          isActiveSpaceInvoice: settings.is_active_space_invoice,
          isSpaceInvoiceAutoGeneration:
            settings.is_space_invoice_auto_generation,
        },
      }
      await this.fetchBiData()
      this.loading = false
    },
    async fetchBiData() {
      const {
        data: { reports },
      } = await axios.get('/api/bi/reports/management')
      const appReportsKeys = this.tenantSettings.bi_reports.map(r => r.key)

      this.bi = {
        selectedReports: reports
          .filter(r => appReportsKeys.indexOf(r.key) !== -1)
          .map(r => r.key),
        reportItems: reports,
        approvedModules: this.tenantSettings.approved_modules,
      }
    },

    async save() {
      const payload = {
        modules: this.bi.approvedModules,
        per_door: this.billing.per_door,
        calculation: this.billing.calculation,
        twilio_credentials: this.messaging.twilio_credentials,
        twilio_phone: this.messaging.twilio_phone,
        messaging_email: this.messaging.messaging_email,
        is_active_space_invoice: this.addOns.invoices.isActiveSpaceInvoice,
        is_space_invoice_auto_generation:
          this.addOns.invoices.isSpaceInvoiceAutoGeneration,
      }

      if (this.bi.selectedReports && this.bi.selectedReports.length > 0) {
        payload.bi_reports = this.bi.selectedReports
      }

      axios.patch(`/api/settings/${this.selectedTenant.id}/reports`, payload)
    },
    async deactivateTenant() {
      try {
        await axios.post(`/api/tenants/${this.selectedTenant.id}/deactivate`)

        // Refresh tenant settings to show updated status
        await this.getTenants()

        this.$store.dispatch('showSnackbar', {
          text: 'Tenant successfully deactivated',
          color: 'success',
        })
      } catch (error) {
        this.$store.dispatch('showSnackbar', {
          text: 'Error deactivating tenant',
          color: 'error',
        })
        console.error('Error deactivating tenant:', error)
      }
    },
    async downloadTemplate(type, selectedColumns) {
      if (!this.selectedTenant?.id) {
        this.$store.dispatch('showError', 'Please select a tenant first')
        return
      }

      this.loadingTemplate = true
      try {
        if (type === 'update' && this.listingsCount > 200) {
          const response = await customAxios.post(
            '/api/listings-import/template/email',
            {
              tenant_id: this.selectedTenant.id,
              type,
              columns: selectedColumns,
            }
          )
          Toaster.show([
            {
              type: 'info',
              text: response.data.message,
            },
          ])
        } else {
          const formData = new FormData()
          formData.append('tenant_id', this.selectedTenant.id)
          formData.append('type', type)
          formData.append('columns', selectedColumns)

          const response = await customAxios.post(
            '/api/listings-import/template',
            formData,
            {
              responseType: 'blob',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          downloadFile(response.data, `listings_${type}_template.xlsx`)
        }
      } catch (error) {
        console.error('Error downloading template:', error)
        Toaster.show([
          {
            type: 'error',
            text: `Failed to download ${type} template`,
          },
        ])
      } finally {
        this.loadingTemplate = false
      }
    },

    async handleListingsFileUpload(type) {
      if (!this.listingsFile) return

      this.uploadingListings = true
      this.listingsImportErrors = []
      this.importType = type

      try {
        const formData = new FormData()
        formData.append('file', this.listingsFile)
        formData.append('type', type)

        // First validate the file
        const validationResponse = await customAxios.post(
          `/api/listings-import/${this.selectedTenant.id}/validate`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        this.validationResults = validationResponse.data
        this.showValidationDialog = true
      } catch (error) {
        console.error('Validation error:', error)
        this.$store.dispatch('showError', 'Failed to validate import file')
      } finally {
        this.uploadingListings = false
      }
    },

    async proceedWithValidRows() {
      await this.performImport(true)
    },

    async proceedWithAllRows() {
      await this.performImport(false)
    },

    async performImport(skipInvalid) {
      this.uploadingListings = true
      try {
        const formData = new FormData()
        formData.append('file', this.listingsFile)
        formData.append('type', this.importType)
        formData.append('valid_rows_only', skipInvalid.toString())

        const response = await customAxios.post(
          `/api/listings/${this.selectedTenant.id}/import`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (response.data.errors) {
          this.listingsImportErrors = response.data.errors
        } else if (response.data.async) {
          Toaster.show([
            {
              type: 'info',
              text: response.data.message,
            },
          ])
        } else {
          Toaster.show([
            {
              type: 'success',
              text: `Listings imported successfully: ${response.data.processed} processed`,
            },
          ])
        }
      } finally {
        this.uploadingListings = false
        this.listingsFile = null
        this.showValidationDialog = false
      }
    },

    toggleAllColumns(value) {
      this.columns.forEach(column => {
        column.selected = value
      })
      this.columns[0].selected = true
    },

    updateSelectAll() {
      this.selectAllColumns = this.columns.every(column => column.selected)
    },

    async proceedWithColumnSelection() {
      const selectedColumns = this.columns
        .filter(col => col.selected)
        .map(col => col.label)

      this.showColumnDialog = false
      await this.downloadTemplate(this.selectedImportType, selectedColumns)
    },
  },
  computed: {
    mappedReportsItems() {
      return this.reportsItems.map(r => ({ text: r.title, value: r.key }))
    },
    filteredColumns() {
      if (!this.columnSearch) return this.columns
      const searchTerm = this.columnSearch.toLowerCase()
      return this.columns.filter(column =>
        column.label.toLowerCase().includes(searchTerm)
      )
    },
  },
}
</script>

<style scoped>
.error-list {
  list-style-type: disc;
  padding-left: 20px;
}
.height-500 {
  height: 500px;
}
</style>
