<template>
  <div class="white my-2 mx-2 rounded-lg">
    <v-dialog v-model="dialog" max-width="500px">
      <template #activator="{ on }">
        <v-col cols="12" class="pa-0" v-on="!tooltip && !disabled && on">
          <v-row class="ml-0 mr-0 task-row">
            <span :class="['pa-0', 'availability', availabilityClass]" />
            <v-col cols="12" md="9" class="pr-1">
              <div class="font-weight-bold d-flex mb-1 justify-space-between">
                <div class="ellipsis-1 mr-2">
                  <div>{{ task.mini_extra_data.listing_name }}</div>
                </div>
                <span
                  v-if="pendinTaskView || isOpenScope || isWeekScope"
                  class="mr-2"
                  >{{ parseDate(task.scheduled_at, 'ddd, MMM Do') }}</span
                >
              </div>
              <div class="ellipsis-3 text-start">
                {{ task.description }}
              </div>
            </v-col>
            <v-col cols="12" md="3" class="text-end text-caption px-0">
              <task-reservation-tooltip
                v-if="task.mini_extra_data.next_res_info"
                :tooltip.sync="tooltip"
                :reservation="task.mini_extra_data.next_res_info"
                @show-tooltip="showTooltip"
                @hide-tooltip="hideTooltip"
              />
              <div class="text--secondary mx-1">
                <tag
                  :class="['d-inline-block ml-1']"
                  color="black"
                  bg-color="lightgrey"
                >
                  {{ availabilityText }}
                </tag>
              </div>
            </v-col>
            <v-col class="pt-0 pb-2 px-2 mx-1" cols="12">
              <div class="text-caption d-flex">
                <v-icon class="mr-1" :color="priorityColor(task.priority)" small
                  >mdi-circle</v-icon
                >
                <span class="mr-3">
                  {{ task.priority }}
                </span>
                <span v-if="task.allDay" class="font-light">All Day</span>
                <span v-else class="font-light">
                  {{ startTime }} - {{ endTime }}</span
                >
                <v-spacer></v-spacer>
                <tag
                  v-if="!task.require_accept"
                  class="mr-1"
                  :bg-color="listingTaskStatusColor(task.status)"
                  :class="['d-inline-block ml-1']"
                  color="white"
                >
                  {{ task.status }}
                </tag>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <v-card>
        <v-card-title class="text-body-1 word-break d-flex align-baseline">
          <div class="flex-grow-1">{{ task.mini_extra_data.listing_name }}</div>
          <div class="text-end" style="margin-bottom: -20px">
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-subtitle class="text-body-1 pt-1">
          {{ task.description }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-center">
              <v-btn
                depressed
                color="primary"
                class="task-button"
                @click="openTask"
              >
                <v-icon x-large>mdi-clipboard-text-outline</v-icon>
              </v-btn>
              <div class="font-weight-bold mt-2">Service Call</div>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn
                depressed
                color="yellow"
                class="task-button"
                @click="directionsTo(task)"
              >
                <v-icon x-large>mdi-google-maps</v-icon>
              </v-btn>
              <div class="font-weight-bold mt-2">Directions</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-dialog v-if="task.otherSameDay" v-model="dialog2" max-width="800">
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="float-right text-caption mb-2 error--text"
              v-on="on"
            >
              <v-icon class="" small v-on="on">$info_circle</v-icon>
              {{ task.otherSameDay.length }} tasks same day
            </div>
          </template>
          <v-card>
            <v-card-text class="pa-6">
              <div class="task-list">
                <today-task-modal
                  :key="task.id"
                  :task="task"
                  :mtasks="task.otherSameDay"
                  :disabled.sync="tooltip"
                  :show-pending="true"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col
        v-if="
          task.require_accept && !task.accepted_by_id && isAssignedContractor
        "
        cols="12"
        class="pa-0"
      >
        <div class="float-right text-center">
          <v-btn
            color="primary"
            class="mr-1 ml-1 float-right"
            x-medium
            @click="acceptRejectOffer(true)"
            >Accept
          </v-btn>
          <v-btn
            color="red"
            class="mr-1 ml-1 float-right white--text"
            x-medium
            @click="acceptRejectOffer(false)"
            >Reject
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import ColorsMixin from 'components/mixins/colors-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import orderBy from 'lodash/orderBy'
import TodayTaskModal from 'components/todays-tasks/today-task-modal'

import DeviceMixin from 'components/mixins/device-mixin'
import Tag from 'components/common/tag.vue'
import TaskReservationTooltip from 'components/todays-tasks/task-reservation-tooltip.vue'
import axios from '@/axios/config'
import customAxios from '@/axios/config'

export default {
  components: { Tag, TaskReservationTooltip, TodayTaskModal },
  mixins: [ColorsMixin, DeviceMixin, FormattersMixin, CommonFunctions, LtMixin],
  props: ['task', 'disabled', 'pendinTaskView', 'scope'],
  data() {
    return {
      dialog2: null,
      dialog: null,
      tooltip: false,
      accepted: false,
      otherScs: [],
    }
  },
  computed: {
    ...mapState(['listingTasks', 'listingLoading']),
    availabilityText() {
      const availabilityText =
        this.availabilityTextMap[this.task.add_today_avialability_text]
      if (this.checkOutType) {
        return `${this.checkOutType} ${availabilityText}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${availabilityText}`
      } else {
        return availabilityText
      }
    },
    isAssignedContractor() {
      const currentUserId = this.$store.state.user.id
      return [
        this.task.assigned_contractor1_id,
        this.task.assigned_contractor2_id,
        this.task.assigned_contractor3_id,
        this.task.assigned_contractor4_id,
      ].includes(currentUserId)
    },
    totalCountForDay() {
      const count = this.$store.state.listingTaskTotalCount.filter(
        date => date[0] == this.parseDate(this.task.scheduled_at)
      )[0][1]
      return `You have ${count} job(s) for this day`
    },
    startTime() {
      return this.parseDate(this.task.scheduled_at, 'h:mm')
    },
    endTime() {
      return this.parseDate(this.task.scheduled_at_end, 'h:mm')
    },
    plannedDeparture() {
      return this.extraData.late_checkout_purchase
    },
    plannedArrival() {
      return this.extraData.early_check_in_purchase
    },
    originArrival() {
      return this.extraData.origin_check_in_time
    },
    originDeparture() {
      return this.extraData.origin_check_out_time
    },
    checkInType() {
      if (this.plannedArrival && this.plannedArrival !== this.originArrival) {
        return +this.plannedArrival.split(':')[0] <
          +this.originArrival.split(':')[0]
          ? 'ECI'
          : 'LCI'
      }
      return ''
    },
    checkOutType() {
      if (
        this.plannedDeparture &&
        this.plannedDeparture !== this.originDeparture
      ) {
        return +this.plannedDeparture.split(':')[0] >
          +this.originDeparture.split(':')[0]
          ? 'LCO'
          : 'ECO'
      }
      return ''
    },
    availabilityClass() {
      if (this.checkOutType) {
        return `${this.checkOutType} ${this.task.add_today_avialability_text}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${this.task.add_today_avialability_text}`
      } else {
        return this.task.add_today_avialability_text
      }
    },
    extraData() {
      return this.task.mini_extra_data || this.task.extra_data || {}
    },
    isOpenScope() {
      return this.scope === 'open'
    },
    isWeekScope() {
      return this.scope === 'week'
    },
  },
  methods: {
    ...mapActions(['getListingTasks']),
    openTask() {
      if (this.isMobile) {
        const url = `/dashboard/listing-task/${this.task.id}`
        this.$router.push(url)
      } else {
        const url = `${window.location.origin}/dashboard/listing-task/${this.task.id}`
        window.open(url, '_blank')
      }
    },
    getTasks(date) {
      this.getListingTasks({
        filters: {
          date: this.parseDate(date),
          contractor_id: this.contractorId,
          list_view: true,
          status: [
            'New',
            'In Progress',
            'Done',
            'Rejected',
            'Pending Approval',
          ],
        },
      })
    },
    showTooltip() {
      this.tooltip = true
      this.$emit('update:tooltip', true)
    },
    hideTooltip() {
      this.tooltip = false
      this.$emit('update:tooltip', false)
    },
    async acceptRejectOffer(accepted) {
      try {
        const payload = {
          id: this.task.id,
          accepted: accepted,
        }
        await customAxios.post(`/api/listing-tasks/accept-reject`, payload)
      } catch (e) {
        console.log(e)
      } finally {
        this.$emit('getTasks')
        this.getTasks(this.task.scheduled_at)
      }
      this.$emit('getTasks')
    },
  },
}
</script>
<style scoped>
.task-button {
  height: 80px !important;
  width: 80px !important;
}
.word-break {
  word-break: initial;
}
.task-row {
  position: relative;
}
.availability {
  width: 4px;
  height: 100%;
  position: absolute;
}
</style>
