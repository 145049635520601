import get from 'lodash/fp/get'
export default {
  computed: {
    isDesignedVr() {
      const app = this.$store.state.app
      return (
        app &&
        app.configuration &&
        app.configuration.company_name === 'DesignedVR'
      )
    },
    isNox() {
      const app = this.$store.state.app
      return (
        app && app.configuration && app.configuration.company_name === 'Nox'
      )
    },
    isChannelManagerOn() {
      return get('app.configuration.is_channel_manager', this.$store.state)
    },
    activePms() {
      return get('app.configuration.active_pms', this.$store.state)
    },
    isOptima() {
      return get('app.configuration.active_pms', this.$store.state) === 'optima'
    },
    isHostaway() {
      return (
        get('app.configuration.active_pms', this.$store.state) === 'hostaway'
      )
    },
    isDemoTenant() {
      const app = this.$store.state.app
      return (
        app && app.configuration && app.configuration.company_name === 'demo'
      )
    },
    isAdminTenant() {
      const app = this.$store.state.app
      return (
        app && app.configuration && app.configuration.company_name === 'Admin'
      )
    },
    i18nEnabled() {
      const app = this.$store.state.app
      if (process.env.NODE_ENV === 'production') {
        return (
          app &&
          app.configuration &&
          (app.configuration.company_name === 'Isrentals' ||
            app.configuration.company_name === 'YalaRent')
        )
      } else {
        return true
      }
    },
    isRtl() {
      return this.$vuetify.rtl
    },
    paramsTab: {
      get() {
        return this.$route.params.paramsTab
      },
      set(val) {
        if (
          (!this.$route.params.paramsTab ||
            this.$route.params.paramsTab === ':paramsTab') &&
          val
        ) {
          this.$router.replace({
            params: { ...this.$route.params, paramsTab: val },
          })
        } else if (this.$route.params.paramsTab !== val && val) {
          this.$router.push({
            params: { ...this.$route.params, paramsTab: val || '' },
          })
        }
      },
    },
  },
  methods: {
    openUrl(url, target = '_blank') {
      window.open(url, target)
    },
    addToQuery(query) {
      this.$router.push({
        query: { ...this.$route.query, ...query },
      })
    },
  },
}
