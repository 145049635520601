<template>
  <v-dialog v-model="show" max-width="700">
    <v-card>
      <v-card-title class="text-h5">Transfer Details</v-card-title>
      <v-card-text>
        <template v-if="details?.transfer_type === 'bulk'">
          <v-row>
            <v-col cols="6">
              <div class="text-subtitle-2 mb-1">Date</div>
              <div>
                {{ new Date(details.transfer_info.date).toLocaleDateString() }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="text-subtitle-2 mb-1">Total Amount</div>
              <div>
                {{
                  $options.filters.currency(details.transfer_info.total_amount)
                }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <div class="text-subtitle-2 mb-1">Description</div>
              <div>{{ details.transfer_info.description }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <div class="text-subtitle-2 mb-1">To Account</div>
              <div>{{ details.transfer_info.to_account.name }}</div>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <div class="text-subtitle-1 mb-4">Transfer Breakdown</div>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>From Account</th>
                  <th class="text-right">Amount</th>
                  <th>Description</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="transfer in details.transfer_info.transfers"
                  :key="transfer.source_account.id"
                >
                  <td>{{ transfer.source_account.name }}</td>
                  <td class="text-right">
                    {{ $options.filters.currency(transfer.amount) }}
                  </td>
                  <td>{{ transfer.description }}</td>
                  <td>
                    {{ new Date(transfer.posted_at).toLocaleDateString() }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <template v-else-if="details?.transfer_type === 'manual'">
          <v-row>
            <v-col cols="6">
              <div class="text-subtitle-2 mb-1">Date</div>
              <div>
                {{
                  new Date(details.transfer_info.posted_at).toLocaleDateString()
                }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="text-subtitle-2 mb-1">Amount</div>
              <div>
                {{ $options.filters.currency(details.transfer_info.amount) }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <div class="text-subtitle-2 mb-1">Description</div>
              <div>{{ details.transfer_info.description }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="6">
              <div class="text-subtitle-2 mb-1">From Account</div>
              <div>{{ details.transfer_info.source_account.name }}</div>
            </v-col>
            <v-col cols="6">
              <div class="text-subtitle-2 mb-1">To Account</div>
              <div>{{ details.transfer_info.destination_account.name }}</div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LedgerFunctions from 'components/mixins/ledger-functions-mixin'

export default {
  name: 'TransferDetailsDialog',

  mixins: [LedgerFunctions],

  filters: {
    currency(value) {
      if (!value) return '$0.00'
      const amount = parseFloat(value)
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount)
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default: null,
    },
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    closeDialog() {
      this.show = false
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },

    formatCurrency(amount) {
      return this.$options.filters.currency(amount)
    },
  },
}
</script>

<style scoped>
.v-card__text {
  padding-top: 20px;
}
</style>
