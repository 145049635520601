<template>
  <v-dialog v-model="showMultiEdit" persistent white max-width="560px">
    <v-card class="overflow-auto">
      <v-card-title class="text-center">
        <v-row wrap align-end>
          <v-col cols="8">
            <span class="center-text grey-text"
              >{{ selected.length }} selected</span
            >
          </v-col>
          <v-col cols="4">
            <v-btn
              fab
              small
              dark
              color="teal"
              class="float-right"
              @click="closeSnack"
            >
              <v-icon dark>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-col class="white">
          <v-row class="flex-column">
            <v-col cols="12" column pa-2>
              <v-text-field
                v-model="multiChanges.description"
                dense
                flat
                outlined
                hide-details
                label="Description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" column pa-2>
              <v-text-field
                v-model="multiChanges.amount"
                dense
                flat
                outlined
                hide-details
                label="Price per visit"
              ></v-text-field>
            </v-col>
            <v-col cols="12" column pa-2>
              <v-text-field
                v-model="multiChanges.planned_duration"
                dense
                flat
                outlined
                hide-details
                label="Planned duration"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <catalog-quick-select :filter-change="quickPickChange" />
            </v-col>
            <div class="comp-wrapper">
              <div class="text-subtitle-1">Assigned person:</div>
              <v-radio-group v-model="byContractor" row>
                <v-radio label="Contractor" value="contractor">{{
                  $t('By Contractor')
                }}</v-radio>
                <v-radio label="Personnel" value="personnel">{{
                  $t('By personnel')
                }}</v-radio>
              </v-radio-group>
              <v-select
                v-if="byContractor === 'personnel'"
                v-model="multiChanges.by_personnel"
                dense
                clearable
                persistent-hint
                hide-details
                :placeholder="$t('Choose Personnel')"
                outlined
                :items="personnelRoles"
              />
              <div v-if="byContractor === 'contractor'">
                <contractor-select
                  :value.sync="multiChanges.assigned_contractor_id"
                />
              </div>
            </div>

            <!-- Time Settings Section -->
            <v-col cols="12">
              <v-divider class="my-4"></v-divider>
              <div class="text-subtitle-1 mb-4">{{ $t('Time Settings') }}</div>

              <!-- Period Selection -->
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="multiChanges.rules.every_priod"
                    :items="everyPriodOptions"
                    dense
                    outlined
                    hide-details
                    :label="$t('Period')"
                    @change="everyPriodChange"
                  />
                </v-col>
                <v-col
                  v-if="multiChanges.rules.every_priod && !reservationRelated"
                  cols="6"
                >
                  <v-select
                    v-model="multiChanges.rules.every_number"
                    :items="everyRuleNumbers"
                    dense
                    outlined
                    hide-details
                    :label="$t('Every')"
                  />
                </v-col>
              </v-row>

              <!-- Exclusion Options -->
              <v-row v-if="!reservationRelated" class="mt-4">
                <v-col cols="12">
                  <v-select
                    v-model="multiChanges.rules.exclude_list"
                    :items="[
                      'check-in',
                      'checkout',
                      'check-out&in',
                      'while-staying',
                    ]"
                    dense
                    outlined
                    multiple
                    chips
                    small-chips
                    hide-details
                    :label="$t('Exclude on')"
                  />
                </v-col>
              </v-row>

              <!-- Days without cleaning -->
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-text-field
                    v-model="multiChanges.rules.days_without_cleaning"
                    type="number"
                    dense
                    outlined
                    hide-details
                    :label="$t('Days without cleaning tasks')"
                  />
                </v-col>
              </v-row>

              <!-- Min/Max Days Between -->
              <v-row class="mt-4">
                <v-col cols="6">
                  <v-text-field
                    v-model="multiChanges.rules.min_days_between"
                    type="number"
                    dense
                    outlined
                    hide-details
                    :label="$t('Min days between visits')"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="multiChanges.rules.max_days_between"
                    type="number"
                    dense
                    outlined
                    hide-details
                    :label="$t('Max days between visits')"
                  />
                </v-col>
              </v-row>

              <!-- Allowed Visit Days -->
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-select
                    v-model="multiChanges.rules.allowed_days"
                    :items="[
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday',
                    ]"
                    dense
                    outlined
                    multiple
                    chips
                    small-chips
                    hide-details
                    :label="$t('Allowed visit days')"
                  />
                </v-col>
              </v-row>

              <!-- Start Date -->
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="multiChanges.rules.start_date"
                        :label="$t('Start date')"
                        readonly
                        dense
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="multiChanges.rules.start_date"
                      no-title
                      @input="saveDate"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <!-- Time Picker -->
              <v-row class="mt-4">
                <v-col cols="12">
                  <vue-timepicker
                    v-model="multiChanges.rules.hour"
                    format="hh:mm A"
                    :label="$t('Time')"
                    dense
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="white">
          <v-btn text class="mr-3 pa-2 warning" @click="activate(false)">
            Deactivate all
          </v-btn>
        </v-col>
        <v-col class="white">
          <v-btn text class="mr-3 pa-2 success" @click="activate(true)">
            Activate all
          </v-btn>
        </v-col>
        <v-col class="white">
          <v-btn color="red" text @click="deleteAutoListingTasks">
            Delete all
          </v-btn>
        </v-col>
      </v-card-text>
      <v-card-actions class="white">
        <v-spacer></v-spacer>
        <v-btn
          color="cyan"
          text
          class="mr-3 pa-2"
          :disabled="isEmpty(multiChanges)"
          @click="changeSelected"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'
import ContractorSelect from 'components/contractor-select.vue'
import CatalogQuickSelect from 'components/catalog-quick-select'
import { PERSONNEL_ROLES } from '@/consts'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    CatalogQuickSelect,
    ContractorSelect,
    VueTimepicker,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['afterSaveFunc', 'showMultiEdit', 'hide'],
  data() {
    return {
      menu: false,
      dialog: false,
      byContractor: null,
      checklist: [],
      personnelRoles: PERSONNEL_ROLES,
      multiChanges: {
        assigned_contractor_id: null,
        by_personnel: null,
        rules: {
          every_priod: null,
          every_number: null,
          start_date: null,
          simple_mode: true,
          exclude_list: [],
          days_without_cleaning: null,
          min_days_between: null,
          max_days_between: null,
          allowed_days: [],
          hour: null,
        },
      },
    }
  },
  computed: {
    selected: {
      get() {
        return this.$store.state.autoRulesSelectedIDS
      },
      set(val) {
        this.$store.commit('updateAutoRulesSelectedIDS', val)
      },
    },
    channels() {
      return [
        'Airbnb',
        'Booking.com',
        'Website',
        'Expedia',
        'Google',
        'HomeAway',
        'HomeToGo',
        'Marriott',
        'PlumGuide',
        'TripAdvisor',
        'Vacation',
        'Owner',
      ]
    },
    daysArray() {
      return Array.from({ length: 200 }, (_, i) => i + 1)
    },
    reservationRelated() {
      return (
        this.multiChanges.rules.every_priod === 'check-in' ||
        this.multiChanges.rules.every_priod === 'checkout' ||
        this.multiChanges.rules.every_priod === 'while-staying'
      )
    },
    everyPriodOptions() {
      return [
        'check-in',
        'checkout',
        'while-staying',
        'garbage-days',
        'days',
        'weeks',
        'months',
        'years',
      ]
    },
    everyRuleNumbers() {
      if (this.multiChanges.rules.every_priod === 'days') {
        return this.daysArray
      } else if (this.multiChanges.rules.every_priod === 'weeks') {
        return Array.from({ length: 25 }, (_, i) => i + 1)
      } else {
        return Array.from({ length: 12 }, (_, i) => i + 1)
      }
    },
  },
  methods: {
    quickPickChange(change) {
      this.multiChanges.task_type = change.department
      this.multiChanges.sub_topic = change.sub_topic
      this.multiChanges.planned_duration = change.avg_duration || 1
      this.multiChanges.amount = change.avg_price_total
      this.multiChanges.listing_task_catalog_item_id = change.id
      this.multiChanges.listing_task_catalog_item_name = change.description
      if (!this.isEmpty(change.checklist)) {
        this.checklist = change.checklist
      }
      if (change.user_id) {
        this.multiChanges.assigned_contractor_id = change.user_id
      }
    },
    closeSnack() {
      this.multiChanges = { assigned_contractor_id: null, by_personnel: null }
      this.hide()
    },

    deleteAutoListingTasks() {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        this.$store.commit('updateLoading', true)

        if (this.selected.length > 0) {
          axios
            .post('/api/auto-listing-tasks/multi-edit/group', {
              delete_list: true,
              ids: this.selected,
            })
            .then(() => {
              this.$store.commit('updateLoading', false)

              this.selected = []
              this.multiChanges = {
                assigned_contractor_id: null,
                by_personnel: null,
              }
              this.changedDate = null
              this.afterSaveFunc()
            })
            .catch(error => {
              this.$store.commit('updateLoading', false)

              this.multiChanges = {
                assigned_contractor_id: null,
                by_personnel: null,
              }
              console.log(error)
              alert(error)
            })
        }
      }
    },
    activate(active) {
      if (this.selected.length > 0) {
        this.$store.commit('updateLoading', true)

        axios
          .post('/api/auto-listing-tasks/multi-edit/group', {
            ids: this.selected,
            active_all: active,
            rules: this.multiChanges.rules,
          })
          .then(() => {
            this.$store.commit('updateLoading', false)
            this.selected = []
            this.multiChanges = {
              assigned_contractor_id: null,
              by_personnel: null,
              rules: {
                every_priod: null,
                start_date: null,
                simple_mode: true,
                exclude_list: [],
                days_without_cleaning: null,
                min_days_between: null,
                max_days_between: null,
                allowed_days: [],
                hour: null,
              },
            }
            this.afterSaveFunc()
          })
          .catch(error => {
            this.$store.commit('updateLoading', false)
            console.error('Error activating rules:', error)
            alert(error.response?.data?.message || 'Error activating rules')
          })
      }
    },
    everyPriodChange(val) {
      const picks = ['every_priod', 'simple_mode']
      this.multiChanges.rules = {
        ...this.multiChanges.rules,
        every_priod: val,
        simple_mode: true,
      }
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    changeSelected() {
      if (this.selected.length > 0) {
        this.$store.commit('updateLoading', true)

        // Transform hour format if needed
        if (this.multiChanges.rules?.hour) {
          this.multiChanges.rules.hour = this.transformHourToString(
            this.multiChanges.rules.hour
          )
        }

        // Convert string numbers to integers for min/max days
        if (
          this.multiChanges.rules.min_days_between !== null &&
          this.multiChanges.rules.min_days_between !== ''
        ) {
          this.multiChanges.rules.min_days_between = parseInt(
            this.multiChanges.rules.min_days_between
          )
        }
        if (
          this.multiChanges.rules.max_days_between !== null &&
          this.multiChanges.rules.max_days_between !== ''
        ) {
          this.multiChanges.rules.max_days_between = parseInt(
            this.multiChanges.rules.max_days_between
          )
        }
        if (
          this.multiChanges.rules.days_without_cleaning !== null &&
          this.multiChanges.rules.days_without_cleaning !== ''
        ) {
          this.multiChanges.rules.days_without_cleaning = parseInt(
            this.multiChanges.rules.days_without_cleaning
          )
        }

        // Remove empty numeric fields to prevent sending empty strings
        if (!this.multiChanges.rules.min_days_between) {
          delete this.multiChanges.rules.min_days_between
        }
        if (!this.multiChanges.rules.max_days_between) {
          delete this.multiChanges.rules.max_days_between
        }
        if (!this.multiChanges.rules.days_without_cleaning) {
          delete this.multiChanges.rules.days_without_cleaning
        }

        axios
          .post('/api/auto-listing-tasks/multi-edit/group', {
            ids: this.selected,
            task_type: this.multiChanges.task_type,
            sub_topic: this.multiChanges.sub_topic,
            listing_task_catalog_item_id:
              this.multiChanges.listing_task_catalog_item_id,
            listing_task_catalog_item_name:
              this.multiChanges.listing_task_catalog_item_name,
            description: this.multiChanges.description,
            assigned_contractor_id: this.multiChanges.assigned_contractor_id,
            by_personnel: this.multiChanges.by_personnel,
            amount: this.multiChanges.amount,
            planned_duration: this.multiChanges.planned_duration,
            rules: this.multiChanges.rules,
          })
          .then(() => {
            this.$store.commit('updateLoading', false)
            this.selected = []
            this.multiChanges = {
              assigned_contractor_id: null,
              by_personnel: null,
              rules: {
                every_priod: null,
                every_number: null,
                start_date: null,
                simple_mode: true,
                exclude_list: [],
                days_without_cleaning: null,
                min_days_between: null,
                max_days_between: null,
                allowed_days: [],
                hour: null,
              },
            }
            this.afterSaveFunc()
          })
          .catch(error => {
            this.$store.commit('updateLoading', false)
            console.error('Error updating rules:', error)
            // Use alert instead of snackbar since it's not available
            alert(error.response?.data?.message || 'Error updating rules')
          })
      }
    },
  },
}
</script>
