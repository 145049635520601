<template>
  <v-container id="reservation-manage" fluid class="pa-4">
    <v-row v-if="!overbooking && !overbookingOnly" class="align-center">
      <v-col cols="4" sm="2">
        <v-select
          v-model="dateBy"
          label="By"
          outlined
          dense
          hide-details
          :items="['check_in', 'check_out', 'confirmed_at']"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <date-picker
          v-model="from"
          label="From"
          :max="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() + 24))
            ).substr(0, 10)
          "
          :min="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() - 20))
            ).substr(0, 10)
          "
          @change="saveFrom"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <date-picker
          v-model="to"
          label="To"
          :max="
            parseISODateWithOffset(
              new Date().setMonth(new Date().getMonth() + 24)
            ).substr(0, 10)
          "
          :min="from"
          @change="saveTo"
        />
      </v-col>
      <v-col v-if="!listingId" cols="6" sm="auto">
        <v-autocomplete
          v-model="filteredListingId"
          label="Listing"
          clearable
          hide-details
          :items="listingsPicker"
          item-text="nickname"
          item-value="id"
          dense
          outlined
          @change="fetchReservations({})"
        />
      </v-col>
      <v-spacer />
      <v-col cols="6" sm="auto">
        <v-btn
          color="primary"
          class="white--text"
          depressed
          @click="showExtraFilters = !showExtraFilters"
        >
          <v-icon :class="showExtraFilters ? 'fa-rotate-90' : ''" left small
            >$filters
          </v-icon>
          Filters
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showExtraFilters && !overbookingOnly">
      <v-col cols="6" sm="4" lg="2">
        <filters-select
          :value.sync="channel"
          label="Channel"
          :items="channelsItems"
          :selection-func="item => item.text"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <v-select
          v-model="status"
          :label="$t('Status')"
          hide-details
          outlined
          dense
          :items="['all', 'confirmed', 'closed', 'canceled']"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <v-select
          v-model="typeOfPayment"
          label="Type of payment"
          outlined
          dense
          clearable
          hide-details
          :items="['Refunds', 'Need to charge']"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <v-text-field
          v-model="fromPrice"
          prepend-inner-icon="money"
          dense
          outlined
          hide-details
          label="From price"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <v-text-field
          v-model="toPrice"
          dense
          outlined
          hide-details
          prepend-inner-icon="money"
          label="To price"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <v-text-field
          v-model="confirmationCode"
          dense
          outlined
          hide-details
          label="Confirmation code"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="6" sm="auto">
        <v-text-field
          v-model="guestSearchTerm"
          dense
          outlined
          hide-details
          placeholder="Name, Email, Phone"
          label="Guest search"
          @change="fetchReservations({})"
        />
      </v-col>
    </v-row>
    <v-row v-if="showExtraFilters && !overbookingOnly" class="align-center">
      <v-col cols="auto">
        <v-checkbox
          v-model="overbooking"
          dense
          label="Show Overbookings"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col v-if="!overbooking" cols="auto">
        <v-checkbox
          v-model="isMoc"
          dense
          label="Only MOC"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col v-if="!overbooking" cols="auto">
        <v-checkbox
          v-model="isMismatch"
          dense
          label="Show Mismatch"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn
          small
          color="success"
          @click="fetchReservations({ excel: true })"
        >
          <v-icon small left>fas fa-table</v-icon> Export
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="isAdmin && !overbookingOnly">
      <investment-card
        :height="60"
        :cols="isMobile ? 12 : isIPad ? 6 : 3"
        :card="{
          icon: 'fas fa-money-bill-alt',
          value: totalAccommodation,
          color: '#F2EBF7',
          label: 'Accommodation',
          tooltip:
            'Total accommodation fee collected from selected reservations',
          iconColor: 'purple',
          textColor: '#624774',
        }"
      />
      <investment-card
        :height="60"
        :cols="isMobile ? 12 : isIPad ? 6 : 3"
        :card="{
          icon: 'fas fa-broom',
          color: '#ffeed0',
          textColor: '#746039',
          iconColor: 'orange',
          value: totalCleaning,
          label: 'Cleaning',
          tooltip: 'Total cleaning fee collected from selected reservations',
        }"
      />
      <investment-card
        :height="60"
        :cols="isMobile ? 12 : isIPad ? 6 : 3"
        :card="{
          icon: 'fas fa-user-tie',
          value: totalManagementCommission,
          label: 'Management',
          tooltip: ' Total management commission made from reservations',
          color: '#EAF5ED',
          textColor: '#396646',
          iconColor: 'green',
        }"
      />
    </v-row>

    <reservations
      v-if="managedReservations"
      :is-mismatch="isMismatch"
      hide-reconcile="true"
      :headers="headers"
      @pagination="paginationChanged"
      @refetch="fetchReservations"
    />
  </v-container>
</template>

<script>
import Reservations from './reservations.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiltersSelect from 'components/form-fields/filters-select'
import DatePicker from 'components/form-fields/date-picker'
import InvestmentCard from 'components/investment-info/investment-card'
export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['listingId', 'overbookingOnly'],
  data() {
    return {
      filteredListingId: this.listingId,
      from: this.parseISODateWithOffset(new Date()).substr(0, 10),
      to: this.parseISODateWithOffset(new Date()).substr(0, 10),
      menu: false,
      fromPrice: null,
      showExtraFilters: false,
      toPrice: null,
      futureInquiries: null,
      dateBy: 'check_in',
      typeOfPayment: null,
      menu2: false,
      isMoc: false,
      isMismatch: false,
      overbooking: this.overbookingOnly || false,
      datesChange: false,
      status: 'all',
      reviewsStatus: ['New', 'In Progress'],
      channel: [],
      confirmationCode: '',
      guestSearchTerm: '',
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
    }
  },
  mounted() {
    this.initializeListingDateRange()
    this.fetchReservations()
    this.fetchChannels()
  },
  computed: {
    ...mapGetters('reservation', ['channelsItems']),
    ...mapState(['totalManagedReservations', 'managedReservations']),
    ...mapGetters(['listingsPicker']),
    headers() {
      const columns = [
        {
          text: 'Notes',
          value: 'dvr_notes',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Listing',
          value: 'nickname',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Reconciled',
          value: 'reconciled',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Reconciled Amount',
          align: 'center',
          value: 'reconciled_amount',
          sortable: false,
        },
        {
          text: 'Owner Paid At',
          align: 'center',
          value: 'owner_paid_at',
          sortable: false,
        },
        {
          text: 'Price',
          value: 'price',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Client Price',
          value: 'client_price',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Balance',
          value: 'balance',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Link',
          value: 'link',
          align: 'center',
          sortable: false,
        },
      ]

      if (this.isDesignedVr) {
        columns.push({
          text: 'Chat',
          align: 'center',
          value: 'chat_link',
          sortable: false,
        })
      }
      return columns.concat([
        {
          text: 'Merchant',
          value: 'merchant',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Channel',
          value: 'channel',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Guest',
          value: 'guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Account',
          value: 'account',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check-in',
          value: 'check_in',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check-out',
          value: 'check_out',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Owner profit',
          value: 'client_profit',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Net income',
          value: 'net_income',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Cleaning',
          value: 'cleaning_fee',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Management',
          value: 'management_commission',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Processing',
          value: 'processing_fee',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Channel Fee',
          value: 'host_channel_fee',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Fare Accommodation',
          value: 'fare_accommodation',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Taxes',
          value: 'total_taxes',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Synced',
          value: 'pms_unsync',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Nights',
          value: 'nights_count',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Confirmation',
          value: 'payment_confirmation',
          align: 'center',
          sortable: false,
        },
      ])
    },
    totalCleaning() {
      return this.totalManagedReservations.cleaning_fee
    },
    totalAccommodation() {
      return this.totalManagedReservations.fare_accommodation
    },
    totalManagementCommission() {
      return this.totalManagedReservations.management_commission
    },
    parsedFrom() {
      return this.from ? this.$moment(this.from).format('YYYY/MM/DD') : ''
    },
    parsedTo() {
      return this.to ? this.$moment(this.to).format('YYYY/MM/DD') : ''
    },
    currentListingId() {
      return this.listingId || this.filteredListingId
    },
  },
  methods: {
    ...mapActions('reservation', ['fetchChannels']),
    ...mapActions(['getManagedReservations']),
    saveFrom() {
      if (this.from && this.to) {
        this.fetchReservations()
      }
    },
    saveTo() {
      if (this.from && this.to) {
        this.fetchReservations()
      }
    },
    paginationChanged(data) {
      this.pagination = { ...this.pagination, ...data }
      this.fetchReservations(this.pagination)
    },
    fetchReservations(addons = {}) {
      const { itemsPerPage } = this.pagination
      if (this.overbooking) {
        this.getManagedReservations({
          page: 1,
          per_page: itemsPerPage,
          overbooking: this.overbooking,
          ...addons,
        })
      } else {
        this.getManagedReservations({
          page: 1,
          per_page: itemsPerPage,
          from: this.from,
          to: this.to,
          date_by: this.dateBy,
          status: this.status,
          futureInquiries: this.futureInquiries,
          is_merchant: this.isMoc,
          is_mismatch: this.isMismatch,
          overbooking: this.overbooking,
          typeOfPayment: this.typeOfPayment,
          channel: this.channel,
          listingId: this.filteredListingId,
          fromPrice: this.fromPrice,
          toPrice: this.toPrice,
          guestSearchTerm: this.guestSearchTerm,
          confirmationCode: this.confirmationCode,
          ...addons,
        })
      }
    },
    initializeListingDateRange() {
      if (!this.listingId) return

      this.filteredListingId = this.listingId

      const startDate = new Date()
      const endDate = new Date()

      startDate.setDate(1)
      this.from = this.parseISODateWithOffset(startDate).substr(0, 10)

      endDate.setMonth(endDate.getMonth() + 1)
      endDate.setDate(0)
      this.to = this.parseISODateWithOffset(endDate).substr(0, 10)
    },
  },
  components: {
    InvestmentCard,
    DatePicker,
    FiltersSelect,
    reservations: Reservations,
  },
}
</script>
