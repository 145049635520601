<template>
  <form-dialog
    ref="createLedgerAccount"
    :title="$t('Add Ledger Account')"
    :max-width="600"
    @submit="createLedgerAccount"
  >
    <template #activator="{ on }">
      <v-btn color="primary" class="text-capitalize" elevation="0" v-on="on">
        {{ $t('Add Ledger Account') }}
      </v-btn>
    </template>

    <v-select
      v-model="selectedAccount"
      outlined
      dense
      clearable
      :items="filteredAccounts"
      item-text="label"
      item-value="value"
      :label="$t('Select Account')"
      required
      @change="handleAccountSelection"
    >
      <template #prepend-item>
        <v-text-field
          v-model="accountSearch"
          dense
          outlined
          hide-details
          clearable
          class="mx-2 mt-2"
          :placeholder="$t('Search by name or code')"
          @click:clear="accountSearch = ''"
          @input="$refs.select.blur()"
        >
          <template #prepend-inner>
            <v-icon small class="mr-2">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </template>

      <template #item="{ item }">
        <div
          :class="{ 'pl-4': item.level === 1, 'pl-8': item.level === 2 }"
          class="d-flex align-center"
        >
          <div class="flex-grow-1">{{ item.label }}</div>
          <v-chip
            small
            :color="getAccountTypeColor(item.value.account_type.toLowerCase())"
            :text-color="
              getAccountTypeTextColor(item.value.account_type.toLowerCase())
            "
            label
            outlined
            class="ml-4"
          >
            {{ item.value.account_type }}
          </v-chip>
        </div>
      </template>
      <template #selection="{ item }">
        <div class="d-flex align-center">
          <div class="flex-grow-1">{{ item.label }}</div>
          <v-chip
            small
            :color="getAccountTypeColor(item.value.account_type.toLowerCase())"
            :text-color="
              getAccountTypeTextColor(item.value.account_type.toLowerCase())
            "
            label
            outlined
            class="ml-4"
          >
            {{ item.value.account_type }}
          </v-chip>
        </div>
      </template>
    </v-select>

    <v-text-field v-model="account.code" outlined dense :label="$t('Code')" />

    <v-text-field v-model="account.name" outlined dense :label="$t('Name')" />

    <v-select
      v-model="account.account_type"
      outlined
      dense
      :items="accountTypes"
      :label="$t('Account Type')"
      required
      :rules="[required]"
    >
      <template #selection="{ item }">
        <v-chip
          small
          :color="getAccountTypeColor(item.value)"
          :text-color="getAccountTypeTextColor(item.value)"
          label
          outlined
        >
          {{ item.text }}
        </v-chip>
      </template>
    </v-select>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import axios from 'axios'

export default {
  name: 'LedgerAccountCreateModal',
  components: { FormDialog },
  mixins: [FormRules],

  data: () => ({
    selectedAccount: null,
    accountSearch: '',
    account: {
      name: null,
      code: null,
      account_type: null,
    },
    accountTypes: [
      { text: 'Asset', value: 'asset' },
      { text: 'Liability', value: 'liability' },
      { text: 'Equity', value: 'equity' },
      { text: 'Income', value: 'income' },
      { text: 'Expense', value: 'expense' },
    ],
    accountStructure: {
      assets: {
        current: {
          1000: {
            name: 'Cash',
            account_type: 'Asset',
            subAccounts: {
              1001: { name: 'Operating Account', account_type: 'Asset' },
              1002: { name: 'Reserve Account', account_type: 'Asset' },
              1003: { name: 'Trust Account', account_type: 'Asset' },
            },
          },
          1100: {
            name: 'Accounts Receivable',
            account_type: 'Asset',
            subAccounts: {
              1101: { name: 'Guest Receivables', account_type: 'Asset' },
              1102: { name: 'Channel Receivables', account_type: 'Asset' },
              1103: { name: 'Other Receivables', account_type: 'Asset' },
            },
          },
          1200: {
            name: 'Other Current Assets',
            account_type: 'Asset',
            subAccounts: {
              1201: { name: 'Prepaid Expenses', account_type: 'Asset' },
              1202: { name: 'Security Deposits', account_type: 'Asset' },
            },
          },
        },
        fixed: {
          1500: {
            name: 'Property & Equipment',
            account_type: 'Asset',
            subAccounts: {
              1501: { name: 'Furniture and Fixtures', account_type: 'Asset' },
              1502: { name: 'Equipment', account_type: 'Asset' },
              1503: { name: 'Accumulated Depreciation', account_type: 'Asset' },
            },
          },
        },
      },
      liabilities: {
        current: {
          2000: {
            name: 'Accounts Payable',
            account_type: 'Liability',
            subAccounts: {
              2001: { name: 'Vendor Payables', account_type: 'Liability' },
              2002: { name: 'Owner Payments Due', account_type: 'Liability' },
            },
          },
          2100: {
            name: 'Guest Deposits',
            account_type: 'Liability',
            subAccounts: {
              2101: {
                name: 'Security Deposits Held',
                account_type: 'Liability',
              },
              2102: {
                name: 'Advance Rent Deposits',
                account_type: 'Liability',
              },
            },
          },
          2200: {
            name: 'Accrued Expenses',
            account_type: 'Liability',
            subAccounts: {
              2201: { name: 'Accrued Payroll', account_type: 'Liability' },
              2202: { name: 'Accrued Taxes', account_type: 'Liability' },
            },
          },
        },
        longTerm: {
          2500: {
            name: 'Long Term Liabilities',
            account_type: 'Liability',
            subAccounts: {
              2501: { name: 'Long Term Loans', account_type: 'Liability' },
              2502: { name: 'Equipment Financing', account_type: 'Liability' },
            },
          },
        },
      },
      equity: {
        3000: {
          name: "Owner's Equity",
          account_type: 'Equity',
          subAccounts: {
            3001: { name: 'Capital Contributions', account_type: 'Equity' },
            3002: { name: 'Retained Earnings', account_type: 'Equity' },
          },
        },
        3100: {
          name: 'Distributions',
          account_type: 'Equity',
          subAccounts: {
            3101: { name: 'Owner Distributions', account_type: 'Equity' },
            3102: { name: 'Dividend Payments', account_type: 'Equity' },
          },
        },
      },
      revenue: {
        rental: {
          4000: {
            name: 'Rental Revenue',
            account_type: 'Income',
            subAccounts: {
              4001: { name: 'Airbnb Revenue', account_type: 'Income' },
              4002: { name: 'VRBO Revenue', account_type: 'Income' },
              4003: { name: 'Booking.com Revenue', account_type: 'Income' },
              4004: { name: 'Direct Booking Revenue', account_type: 'Income' },
            },
          },
          4100: {
            name: 'Fee Revenue',
            account_type: 'Income',
            subAccounts: {
              4101: { name: 'Cleaning Fees', account_type: 'Income' },
              4102: { name: 'Service Fees', account_type: 'Income' },
              4103: { name: 'Late Check-out Fees', account_type: 'Income' },
              4104: { name: 'Pet Fees', account_type: 'Income' },
            },
          },
        },
        other: {
          4500: {
            name: 'Other Revenue',
            account_type: 'Income',
            subAccounts: {
              4501: { name: 'Damage Recovery', account_type: 'Income' },
              4502: { name: 'Forfeited Deposits', account_type: 'Income' },
              4503: { name: 'Miscellaneous Income', account_type: 'Income' },
            },
          },
        },
      },
      expenses: {
        operational: {
          5000: {
            name: 'Property Operations',
            account_type: 'Expense',
            subAccounts: {
              5001: { name: 'Cleaning Services', account_type: 'Expense' },
              5002: { name: 'Maintenance & Repairs', account_type: 'Expense' },
              5003: { name: 'Supplies', account_type: 'Expense' },
            },
          },
          5100: {
            name: 'Property Services',
            account_type: 'Expense',
            subAccounts: {
              5101: { name: 'Utilities', account_type: 'Expense' },
              5102: { name: 'Internet & Cable', account_type: 'Expense' },
              5103: { name: 'Security Services', account_type: 'Expense' },
            },
          },
        },
        administrative: {
          6000: {
            name: 'Personnel Expenses',
            account_type: 'Expense',
            subAccounts: {
              6001: { name: 'Salaries & Wages', account_type: 'Expense' },
              6002: { name: 'Payroll Taxes', account_type: 'Expense' },
              6003: { name: 'Employee Benefits', account_type: 'Expense' },
            },
          },
          6100: {
            name: 'Professional Services',
            account_type: 'Expense',
            subAccounts: {
              6101: { name: 'Legal Fees', account_type: 'Expense' },
              6102: { name: 'Accounting Fees', account_type: 'Expense' },
              6103: { name: 'Consulting Fees', account_type: 'Expense' },
            },
          },
        },
        marketing: {
          6500: {
            name: 'Marketing',
            account_type: 'Expense',
            subAccounts: {
              6501: { name: 'Online Advertising', account_type: 'Expense' },
              6502: { name: 'Marketing Materials', account_type: 'Expense' },
            },
          },
          6600: {
            name: 'Channel Commissions',
            account_type: 'Expense',
            subAccounts: {
              6601: { name: 'Airbnb comissions', account_type: 'Expense' },
              6602: { name: 'BDC comissions', account_type: 'Expense' },
              6603: { name: 'VRBO comissions', account_type: 'Expense' },
            },
          },
        },
      },
    },
  }),

  computed: {
    filteredAccounts() {
      if (!this.accountSearch) return this.flattenedAccounts

      const search = this.accountSearch.toLowerCase()
      return this.flattenedAccounts.filter(account => {
        const matchesCode = account.value.code.toLowerCase().includes(search)
        const matchesName = account.value.name.toLowerCase().includes(search)
        return matchesCode || matchesName
      })
    },

    flattenedAccounts() {
      const accounts = []

      const flattenAccounts = (obj, parentPath = [], level = 0) => {
        Object.entries(obj).forEach(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            if (value.name) {
              // This is an account with a name (could be parent or leaf)
              const isParent =
                value.subAccounts || typeof value.total !== 'undefined'
              const code = key
              accounts.push({
                label: `${code} - ${value.name}`,
                value: {
                  code,
                  name: value.name,
                  category: parentPath.join('/'),
                  account_type: value.account_type,
                },
                level: isParent ? 0 : 1,
              })

              // Process subaccounts if they exist
              if (value.subAccounts) {
                Object.entries(value.subAccounts).forEach(
                  ([subKey, subValue]) => {
                    // Check if subValue has its own subAccounts
                    if (subValue.subAccounts) {
                      accounts.push({
                        label: `${subKey} - ${subValue.name}`,
                        value: {
                          code: subKey,
                          name: subValue.name,
                          category: [...parentPath, key].join('/'),
                          account_type: subValue.account_type,
                        },
                        level: 1,
                      })

                      // Process nested subaccounts
                      Object.entries(subValue.subAccounts).forEach(
                        ([nestedKey, nestedValue]) => {
                          accounts.push({
                            label: `${nestedKey} - ${nestedValue.name}`,
                            value: {
                              code: nestedKey,
                              name: nestedValue.name,
                              category: [...parentPath, key, subKey].join('/'),
                              account_type: nestedValue.account_type,
                            },
                            level: 2,
                          })
                        }
                      )
                    } else {
                      accounts.push({
                        label: `${subKey} - ${subValue.name}`,
                        value: {
                          code: subKey,
                          name: subValue.name,
                          category: [...parentPath, key].join('/'),
                          account_type: subValue.account_type,
                        },
                        level: 1,
                      })
                    }
                  }
                )
              }
            } else if (!value.name) {
              // This is a category, recurse into it
              flattenAccounts(value, [...parentPath, key], level + 1)
            }
          }
        })
      }

      flattenAccounts(this.accountStructure)

      // Sort by code for better organization
      return accounts.sort((a, b) => a.value.code.localeCompare(b.value.code))
    },
  },

  methods: {
    getAccountTypeColor(type) {
      const colors = {
        asset: 'light-blue',
        liability: 'pink',
        equity: 'purple',
        income: 'light-green',
        expense: 'deep-orange',
      }
      return colors[type] ? `${colors[type]} lighten-4` : 'grey lighten-4'
    },

    getAccountTypeTextColor(type) {
      const colors = {
        asset: 'light-blue darken-2',
        liability: 'pink darken-2',
        equity: 'purple darken-2',
        income: 'light-green darken-2',
        expense: 'deep-orange darken-2',
      }
      return colors[type] || 'grey darken-2'
    },

    handleAccountSelection(selection) {
      if (selection) {
        this.account.code = selection.code
        this.account.name = selection.name
        // Convert account type to lowercase and ensure it matches our accountTypes values
        const accountType =
          selection.account_type?.toLowerCase() ||
          this.determineAccountType(selection.code)
        this.account.account_type = accountType
      } else {
        // Clear all fields when selection is cleared
        this.account.code = null
        this.account.name = null
        this.account.account_type = null
      }
    },

    determineAccountType(code) {
      const numCode = parseInt(code)
      if (numCode >= 1000 && numCode < 2000) {
        return 'asset'
      } else if (numCode >= 2000 && numCode < 3000) {
        return 'liability'
      } else if (numCode >= 3000 && numCode < 4000) {
        return 'equity'
      } else if (numCode >= 4000 && numCode < 5000) {
        return 'income'
      } else if (numCode >= 5000) {
        return 'expense'
      }
      return null
    },

    async createLedgerAccount() {
      this.$store.commit('updateLoading', true)
      try {
        await axios.post('/api/ledger-accounts', {
          ledger_account: this.account,
        })
        this.$emit('created')
        this.$refs.createLedgerAccount.close()
      } catch (error) {
        this.$store.dispatch('showError', 'Failed to create ledger account')
      } finally {
        this.$store.commit('updateLoading', false)
      }
    },
  },
}
</script>
