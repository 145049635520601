<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t('accounting.manual_transfer.title') }}
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="form.fromAccountId"
                :items="accounts"
                item-text="name"
                item-value="id"
                :label="$t('accounting.manual_transfer.from_account')"
                :rules="[v => !!v || $t('common.required')]"
                outlined
                required
              >
                <template #item="{ item }">
                  <div>
                    {{ item.name }}
                    <span class="grey--text text--darken-1 ml-2">
                      ({{ item.code }})
                    </span>
                  </div>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="form.toAccountId"
                :items="accounts"
                item-text="name"
                item-value="id"
                :label="$t('accounting.manual_transfer.to_account')"
                :rules="[
                  v => !!v || $t('common.required'),
                  v =>
                    v !== form.fromAccountId ||
                    $t('accounting.manual_transfer.different_account'),
                ]"
                outlined
                required
              >
                <template #item="{ item }">
                  <div>
                    {{ item.name }}
                    <span class="grey--text text--darken-1 ml-2">
                      ({{ item.code }})
                    </span>
                  </div>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model.number="form.amount"
                :label="$t('accounting.manual_transfer.amount')"
                type="number"
                step="0.01"
                :rules="[
                  v => !!v || $t('common.required'),
                  v =>
                    v > 0 || $t('accounting.manual_transfer.positive_amount'),
                ]"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="form.postedAt"
                    :label="$t('accounting.manual_transfer.posted_at')"
                    readonly
                    outlined
                    :rules="[v => !!v || $t('common.required')]"
                    required
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.postedAt"
                  no-title
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="form.description"
                :label="$t('accounting.manual_transfer.description')"
                :rules="[v => !!v || $t('common.required')]"
                outlined
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="submit"
        >
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import Toaster from '@/utils/toaster'

export default {
  name: 'ManualTransferDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      loading: false,
      dateMenu: false,
      form: {
        fromAccountId: null,
        toAccountId: null,
        amount: null,
        postedAt: new Date().toISOString().substr(0, 10),
        description: null,
      },
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      try {
        await axios.post('/api/ledger-transactions/manual_transfer', {
          from_account_id: this.form.fromAccountId,
          to_account_id: this.form.toAccountId,
          amount: this.form.amount,
          description: this.form.description,
          posted_at: this.form.postedAt,
        })

        this.$emit('success')
        this.dialog = false
        this.$refs.form.reset()
        Toaster.show([
          {
            type: 'success',
            text: this.$t('accounting.manual_transfer.success'),
          },
        ])
      } catch (error) {
        Toaster.show([
          {
            type: 'error',
            text: error.response?.data?.error || this.$t('common.error'),
          },
        ])
      } finally {
        this.loading = false
      }
    },

    close() {
      this.dialog = false
      this.$refs.form.reset()
    },
  },
}
</script>
