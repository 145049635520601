<template>
  <form-dialog
    v-model="dialog"
    :title="$t('Upload Expenses CSV')"
    persistent
    :disable-submit="uploading"
    :max-width="isMobile ? '100%' : '50%'"
    @close="close"
    @submit="save"
  >
    <template #activator="{ on }">
      <div class="d-flex">
        <v-btn color="primary" outlined v-on="on" @click="$emit('open')">
          <v-icon left>mdi-file-upload</v-icon>
          {{ $t('Upload CSV') }}
        </v-btn>
      </div>
    </template>

    <v-btn color="primary" text @click="downloadTemplate">
      <v-icon left>mdi-file-download</v-icon>
      {{ $t('Download Template') }}
    </v-btn>

    <v-file-input
      v-model="file"
      :label="$t('Select CSV file')"
      accept=".csv"
      outlined
      dense
      :rules="[v => !!v || 'CSV file is required']"
      @change="previewFile"
    />

    <v-alert v-if="error" type="error" dense outlined class="mb-4">
      {{ error }}
    </v-alert>

    <v-alert
      v-if="uploadStatus"
      :type="uploadStatus.status"
      dense
      outlined
      class="mb-4"
    >
      {{ uploadStatus.message }}
    </v-alert>

    <div v-if="preview.length" class="mt-4">
      <v-simple-table dense>
        <template #default>
          <thead>
            <tr>
              <th>Description</th>
              <th>{{ $t('Short Reason') }} *</th>
              <th>{{ $t('Amount') }} *</th>
              <th>{{ $t('Source') }} *</th>
              <th>Listing ID</th>
              <th>Reservation ID</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in preview.slice(0, 5)" :key="i">
              <td>{{ row.description }}</td>
              <td>{{ row.short_reason }}</td>
              <td>{{ row.amount }}</td>
              <td>{{ row.source }}</td>
              <td>{{ row.listing_id }}</td>
              <td>{{ row.reservation_id }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="preview.length > 5" class="text-caption grey--text mt-2">
        Showing first 5 of {{ preview.length }} rows
      </div>
    </div>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import customAxios from '@/axios/config'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'ExpenseCsvUploadModal',

  components: {
    FormDialog,
  },

  mixins: [DeviceMixin],

  data() {
    return {
      dialog: false,
      file: null,
      preview: [],
      error: null,
      uploading: false,
      uploadStatus: null,
    }
  },

  methods: {
    close() {
      this.dialog = false
      this.file = null
      this.preview = []
      this.error = null
      this.uploadStatus = null
    },

    previewFile() {
      if (!this.file) {
        this.preview = []
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        try {
          const rows = this.parseCSV(e.target.result)
          this.validateRows(rows)
          this.preview = rows
          this.error = null
        } catch (err) {
          this.error = err.message
          this.preview = []
        }
      }
      reader.readAsText(this.file)
    },

    parseCSV(content) {
      const rows = content
        .split('\n')
        .map(row => row.split(','))
        .filter(row => row.some(cell => cell.trim()))

      const headers = rows[0].map(h => h.trim().toLowerCase())

      const required = ['short_reason*', 'amount*', 'source*']
      const missing = required.filter(field => !headers.includes(field))

      if (missing.length) {
        throw new Error(
          `Missing required columns: ${missing.join(
            ', '
          )}. Please use the template provided.`
        )
      }

      return rows
        .slice(1)
        .map(row => {
          const expense = {}
          headers.forEach((header, i) => {
            // Remove the * from header names when creating the object
            const cleanHeader = header.replace('*', '')
            expense[cleanHeader] = row[i]?.trim() || ''
          })
          return expense
        })
        .filter(row => Object.values(row).some(v => v))
    },

    validateRows(rows) {
      rows.forEach((row, i) => {
        const cleanAmount = (row.amount || '')
          .toString()
          .trim()
          .replace(/[^0-9.-]/g, '') // Remove any currency symbols or spaces

        if (!cleanAmount || isNaN(Number(cleanAmount))) {
          throw new Error(
            `Invalid amount "${row.amount}" in row ${
              i + 2
            }. Amount must be a valid number.`
          )
        }

        // Validate required fields
        if (!row.short_reason?.trim()) {
          throw new Error(
            `Missing required field "Short Reason" in row ${i + 2}`
          )
        }

        if (!row.source?.trim()) {
          throw new Error(`Missing required field "Source" in row ${i + 2}`)
        }

        // Update the row with cleaned amount
        row.amount = cleanAmount
      })
    },

    async save() {
      if (!this.file) return

      this.uploading = true
      this.error = null
      this.uploadStatus = null

      const formData = new FormData()
      formData.append('file', this.file)

      try {
        const response = await customAxios.post(
          '/api/expenses/multi-upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        this.uploadStatus = {
          status: 'success',
          message: response.data.payload.data.message,
        }

        setTimeout(() => {
          this.close()
          this.$store.dispatch(
            'getExpensesByFilter',
            this.$store.state.lastExpensesFilter
          )
        }, 5000)
      } catch (err) {
        this.error = err.response?.data?.payload?.error || 'Upload failed'
        this.uploading = false
      } finally {
        this.uploading = false
      }
    },

    downloadTemplate() {
      const headers = [
        'description',
        'short_reason*',
        'amount*',
        'source*',
        'listing_id',
        'reservation_id',
        'paid_at',
      ]
      const sampleRow = [
        'Sample expense',
        'Maintenance',
        '100',
        'Manual',
        '12345',
        '67890',
        this.$moment().format('YYYY-MM-DD'),
      ]

      const csvContent = [headers.join(','), sampleRow.join(',')].join('\n')

      const blob = new Blob([csvContent], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', 'expenses_template.csv')
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>
