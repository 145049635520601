<template>
  <v-dialog v-model="dialog" max-width="560px">
    <template #activator="{ on }">
      <v-btn :color="buttonColor" :block="buttonBlock" x-small v-on="on">
        {{ type }}</v-btn
      >
    </template>
    <v-card>
      <v-form ref="refundForm" @submit="submit">
        <v-card-title class="text-h5 text-capitalize grey lighten-2">
          {{ type }}
        </v-card-title>
        <v-card-text>
          <div v-if="type === 'refund'" class="text-body-1 py-3">
            {{ $t('Refund Processing Time') }}
          </div>
          <div v-if="isRefundOrCapture">{{ $t('Maximum') }}: {{ total }}</div>
          <v-text-field
            v-if="isRefundOrCapture"
            v-model="amount"
            dense
            outlined
            class="mt-3"
            :rules="[required, min(0), max(total)]"
            type="number"
            :label="$t('Amount')"
          />
          <v-checkbox
            v-if="type === 'refund'"
            v-model="createInvoice"
            :label="$t('Create Invoice For Refund')"
            class="mt-2"
          />
          <v-select
            v-if="isRefundOrCancel && resortsZone"
            v-model="reason"
            outlined
            class="mt-3"
            :hint="shouldCreateExpense ? $t('Expense Creation Warning') : null"
            :persistent-hint="true"
            :items="reasons"
            :rules="[required]"
            :label="$t('Reason')"
          />
          <div v-if="shouldCreateExpense" class="mt-2">
            <v-textarea
              v-model="note"
              placeholder="Explain the refund reason..."
              dense
              outlined
              :rules="[required]"
            />
          </div>
          <div v-if="error" class="error--text">{{ error }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :disabled="loading" outlined color="primary" @click="onClose">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            type="submit"
            color="primary"
          >
            {{ $t('Apply') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from '../mixins/form-rules-mixin'
import axios from '@/axios/config'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'RefundDialog',
  mixins: [formRules],
  props: ['type', 'total', 'intentId', 'metadata', 'buttonBlock'],
  data() {
    return {
      dialog: false,
      note: '',
      reason: '',
      loading: false,
      error: '',
      amount: this.total,
      createInvoice: true,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    buttonColor() {
      switch (this.type) {
        case 'refund':
          return 'error'
        case 'cancel':
          return 'warning'
        case 'capture':
          return 'success'
        default:
          return 'error'
      }
    },
    isRefundOrCancel() {
      return this.type === 'refund' || this.type === 'cancel'
    },
    resortsZone() {
      return !!this.currentUser.zone && this.currentUser.zone.name === 'Resorts'
    },
    isRefund() {
      return this.type === 'refund'
    },
    isRefundOrCapture() {
      return this.type === 'capture' || this.type === 'refund'
    },
    reasons() {
      if (this.type === 'cancel') {
        return [
          {
            text: 'Duplicate',
            value: 'duplicate',
          },
          {
            text: 'Fraudulent',
            value: 'fraudulent',
          },
          {
            text: 'Requested by customer',
            value: 'requested_by_customer',
          },
          {
            text: 'Abandoned',
            value: 'abandoned',
          },
        ]
      }
      return [
        {
          text: 'Guest canceled',
          value: 'canceled',
        },
        {
          text: 'Compensation for bad stay',
          value: 'compensation',
        },
        {
          text: 'Pricing error',
          value: 'pricing',
        },
      ]
    },
    shouldCreateExpense() {
      return this.type === 'refund' && this.reason === 'compensation'
    },
  },
  methods: {
    async submit(e) {
      e.preventDefault()

      if (this.$refs.refundForm.validate()) {
        try {
          this.loading = true
          const payload = {
            id: this.intentId,
            expense_create: this.shouldCreateExpense,
            note: this.note,
            create_invoice: this.createInvoice,
          }
          if (this.isRefundOrCapture) {
            payload.amount = (this.amount * 100).toFixed(0)
            payload.metadata = this.metadata || {}
          }
          await axios.post(`/api/payment/${this.type}`, payload)
          // Wait for 5000ms to ensure the response is received
          await new Promise(resolve => setTimeout(resolve, 5000))
          this.onSuccess()
        } catch ({ response }) {
          this.loading = false
          this.error = response.data.error
        }
      }
    },
    onSuccess() {
      this.$emit('success')
      this.onClose()
    },

    onClose() {
      this.dialog = false
      this.loading = false
      this.error = ''
    },
  },
}
</script>

<style scoped></style>
