<template>
  <v-card>
    <v-card-title class="px-6 pt-6">
      <div class="p-relative flex">
        <h2 class="text-h6">{{ $t('Edit Charge Profile') }}</h2>
        <v-icon class="close-button" @click="close">close</v-icon>
      </div>
    </v-card-title>
    <v-sheet class="px-4">
      <v-form
        ref="editTaxProfile"
        lazy-validation
        @submit.prevent="updateTaxProfile"
      >
        <v-text-field
          v-model="taxProfile.name"
          outlined
          dense
          :label="$t('Name')"
          required
        />
        <div v-for="(tax, ind) in taxProfile.taxes" :key="ind" class="pa-2">
          <v-row class="align-center">
            <v-col cols="5">
              <v-autocomplete
                v-model="tax.tax_type"
                outlined
                dense
                hide-details
                :items="taxTypes"
                label="Tax Type"
                :rules="[required]"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="tax.name"
                outlined
                dense
                hide-details
                :label="$t('Title')"
                required
                :rules="[required]"
              />
            </v-col>
            <v-col cols="2"> </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="tax.amount"
                type="number"
                outlined
                dense
                hide-details
                :label="$t('Amount')"
                required
                :rules="[required]"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="tax.units"
                outlined
                dense
                hide-details
                :items="taxUnits"
                label="Unit"
                :rules="[required]"
              />
            </v-col>
            <v-col v-if="tax.units === 'fixed'" cols="5">
              <v-select
                v-model="tax.quantifier"
                outlined
                dense
                hide-details
                :items="taxQuantifier"
                label="Quantifier"
                :rules="[required]"
              />
            </v-col>
            <v-col v-if="tax.units === 'percentage'" cols="5">
              <v-select
                v-model="tax.applicable_to"
                outlined
                dense
                hide-details
                multiple
                small-chips
                :items="taxApplicableTo"
                label="Tax applicable to"
                :rules="[required]"
              />
            </v-col>
            <v-col cols="1">
              <v-icon
                class="ml-auto"
                small
                color="error"
                @click="removeTax(ind)"
                >$trash</v-icon
              >
            </v-col>
            <v-col
              v-if="tax?.tax_type?.toLowerCase()?.includes('fee')"
              cols="12"
            >
              <v-radio-group
                v-model="tax.tax_relation"
                class="pb-2 ma-0"
                hide-details
                row
              >
                <v-radio label="Belongs to Owner" value="owner" />
                <v-radio label="Belongs to PMC" value="pmc" />
              </v-radio-group>
            </v-col>
            <v-col v-if="tax.tax_type === 'VAT'" cols="12">
              <v-checkbox
                v-model="tax.inclusive"
                :label="$t('Price inclusive VAT')"
                hide-details
                dense
              />
            </v-col>
          </v-row>
          <v-divider class="black" />
        </div>
        <div>
          <v-btn outlined class="my-3" color="info" @click="addTax"
            ><v-icon left>fas fa-plus</v-icon> Add Tax
          </v-btn>
        </div>
        <v-card-actions class="justify-end">
          <v-btn color="primary" outlined @click="close"
            >{{ $t('Cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit">{{ $t('Save') }} </v-btn>
        </v-card-actions>
      </v-form>
    </v-sheet>
  </v-card>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TaxProfileEditModal',
  mixins: [FormRules, FormattersMixin],
  props: ['taxProfile'],
  data() {
    return {
      taxProfiles: {
        name: null,
        taxes: [],
      },
      toRemove: [],
    }
  },
  computed: {
    ...mapState({
      taxDataSet: state => state.taxProfile.taxProfiles,
    }),
    taxTypes() {
      if (this.taxDataSet !== null) {
        const selectedTaxTypes = this.taxProfile.taxes.map(tax => tax.tax_type)

        return Object.keys(this.taxDataSet.tax_types).map(taxTypeKey => {
          return {
            text: taxTypeKey,
            value: taxTypeKey,
            disabled: selectedTaxTypes.includes(taxTypeKey),
          }
        })
      }
      return []
    },
    taxUnits() {
      return [
        { text: this.currencySign, value: 'fixed' },
        { text: '%', value: 'percentage' },
      ]
    },
    taxQuantifier() {
      if (this.taxDataSet !== null) {
        return Object.entries(this.taxDataSet.tax_quantifier).map(
          ([key, value]) => ({
            text: value,
            value: key,
          })
        )
      }
      return []
    },
    taxApplicableTo() {
      const baseTaxes = [
        { text: 'Accommodation Fare', value: 'AF' },
        { text: 'Cleaning', value: 'CF' },
      ]

      const selectedTaxTypes = this.taxProfile.taxes
        .filter(tax => tax.units === 'fixed')
        .map(tax => tax.tax_type)

      const additionalTaxes = Object.entries(this.taxDataSet.tax_types)
        .filter(([key]) => selectedTaxTypes.includes(key))
        .filter(([key]) => key.toLowerCase().includes('fee'))
        .map(([key, value]) => {
          return { text: key, value: value }
        })

      return baseTaxes.concat(additionalTaxes)
    },
  },
  methods: {
    ...mapActions('taxProfile', ['updateTaxProfile']),
    close() {
      this.$store.commit('hideModal')
    },
    addTax() {
      this.taxProfile.taxes.push({
        tax_type: '',
        units: '',
        quantifier: 'per_stay',
        applicable_to: '',
        tax_relation: 'pmc',
        inclusive: false,
      })
    },
    removeTax(idx) {
      this.toRemove.push(this.taxProfile.taxes[idx].id)
      const removedTaxType = this.taxProfile.taxes[idx].tax_type
      const removedTaxKey = this.taxDataSet.tax_types[removedTaxType]

      this.taxProfile.taxes.splice(idx, 1)
      this.taxProfile.taxes.forEach(tax => {
        if (removedTaxKey && tax.applicable_to.includes(removedTaxKey)) {
          tax.applicable_to = tax.applicable_to.filter(
            applicable => applicable !== removedTaxKey
          )
        }
      })
    },
    async updateTaxProfile() {
      if (this.$refs.editTaxProfile.validate()) {
        this.$store.commit('updateLoading', true)
        await this.$store
          .dispatch('taxProfile/updateTaxProfile', {
            tax_profile: {
              id: this.taxProfile.id,
              name: this.taxProfile.name,
              taxes: this.taxProfile.taxes,
              tax_relation: this.taxProfile.tax_relation,
            },
            removed: this.toRemove,
          })
          .finally(() => {
            this.$store.commit('updateLoading', false)
            this.toRemove = []
          })
        this.close()
      }
    },
  },
}
</script>

<style scoped></style>
