<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block pa-6">
        <div class="d-flex align-center mb-6">
          <div class="text-h5 font-weight-bold primary--text">
            General Ledger
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="ml-4"
            @click="showManualTransfer = true"
          >
            <v-icon left>mdi-bank-transfer</v-icon>
            {{ $t('accounting.manual_transfer.button') }}
          </v-btn>
          <v-btn
            v-if="isSystemAdmin"
            outlined
            color="error"
            :loading="resetting"
            class="ml-4"
            @click="confirmReset"
          >
            Reset All Transactions
          </v-btn>
          <v-btn
            outlined
            color="primary"
            :loading="exporting"
            class="ml-4"
            @click="exportToExcel"
          >
            <v-icon left>mdi-download</v-icon>
            Export to Excel
          </v-btn>
          <v-btn
            outlined
            color="primary"
            class="ml-4"
            :loading="syncing"
            @click="showSyncDialog = true"
          >
            <v-icon left>mdi-sync</v-icon>
            Sync Transactions
          </v-btn>
        </div>

        <v-row>
          <v-col cols="4">
            <v-card outlined class="total-card">
              <v-card-text class="pa-4">
                <div class="text-subtitle-2 mb-2">Total Debit</div>
                <div class="text-h5 font-weight-bold primary--text">
                  {{ $options.filters.currency(meta.total_debit) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined class="total-card">
              <v-card-text class="pa-4">
                <div class="text-subtitle-2 mb-2">Total Credit</div>
                <div class="text-h5 font-weight-bold primary--text">
                  {{ $options.filters.currency(meta.total_credit) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined class="total-card">
              <v-card-text class="pa-4">
                <div class="text-subtitle-2 mb-2">Balance</div>
                <div class="text-h5 font-weight-bold primary--text">
                  {{ $options.filters.currency(meta.balance) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-menu
              v-model="fromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="filters.dateFrom"
                  label="From Date"
                  readonly
                  outlined
                  dense
                  clearable
                  v-on="on"
                  @click:clear="handleFilterChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dateFrom"
                no-title
                @input="handleFilterChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="2">
            <v-menu
              v-model="toMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="filters.dateTo"
                  label="To Date"
                  readonly
                  outlined
                  dense
                  clearable
                  v-on="on"
                  @click:clear="handleFilterChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dateTo"
                no-title
                @input="handleFilterChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="filters.accountId"
              :items="accounts"
              item-text="name"
              item-value="id"
              label="Account"
              outlined
              dense
              clearable
              class="account-select"
              @change="handleFilterChange"
            >
              <template #item="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
              <template #selection="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
            </v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-model="filters.accountType"
              :items="accountTypes"
              label="Account Type"
              outlined
              dense
              clearable
              @change="handleFilterChange"
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-model="filters.sourceType"
              :items="sourceTypes"
              label="Source"
              outlined
              dense
              clearable
              @change="handleFilterChange"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="bulk-actions-row mt-2">
          <v-col cols="12" class="d-flex align-center">
            <template v-if="selectedCount > 0">
              <div class="text-subtitle-1 mr-4">
                {{ selectedCount }} items selected
              </div>
              <v-btn
                color="primary"
                outlined
                class="mr-2"
                @click="showBulkTransferDialog"
              >
                <v-icon left>mdi-bank-transfer-in</v-icon>
                {{ $t('accounting.bulk_transfer.button') }}
              </v-btn>
            </template>
            <template v-else>
              <div class="text-subtitle-1 text--disabled">
                Select items to perform bulk actions
              </div>
            </template>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="transactions"
        :loading="loading"
        :options="paginationServer"
        :server-items-length="paginationServer.count"
        show-select
        :item-class="getRowClass"
        :selectable="isRowSelectable"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100, 200],
        }"
        class="elevation-1"
        @pagination="paginationChanged($event)"
      >
        <template #item="{ item, isSelected, select }">
          <tr :class="getRowClass(item)" :data-transaction-id="item.id">
            <td>
              <v-simple-checkbox
                v-if="isRowSelectable(item)"
                :value="isSelected"
                dense
                hide-details
                @input="select($event)"
              />
            </td>
            <td>{{ new Date(item.posted_at).toLocaleDateString() }}</td>
            <td>
              <v-btn
                small
                text
                color="primary"
                @click="viewAccount(item.ledger_account)"
              >
                {{ item.ledger_account.name }}
              </v-btn>
            </td>
            <td>{{ item.description }}</td>
            <td>
              <v-chip
                small
                :color="getTransactionTypeColor(item.transaction_type)"
                :text-color="getTransactionTypeTextColor(item.transaction_type)"
                label
                outlined
              >
                {{ item.transaction_type }}
              </v-chip>
            </td>
            <td>
              <span :class="getAmountClass(item)">
                {{ $options.filters.currency(item.amount) }}
              </span>
            </td>
            <td>
              <v-chip small>
                {{ formatTransactionableType(item) }}
              </v-chip>
            </td>
            <td>
              <v-btn
                v-if="
                  item.transactionable_type === 'Reservation' &&
                  item.transactionable?.source
                "
                small
                text
                color="grey darken-1"
                disabled
              >
                {{ item.transactionable.source }}
              </v-btn>
            </td>
            <td>
              <v-btn
                v-if="shouldShowTransferDetails(item)"
                small
                text
                color="primary"
                @click="viewTransferDetails(item)"
              >
                View Transfer Details
              </v-btn>
            </td>
            <td>
              <v-btn
                v-if="shouldShowReference(item)"
                small
                text
                :color="getReferenceColor(item)"
                :disabled="!item.reference_id"
                @click="item.reference_id && viewReference(item)"
              >
                {{ getReferenceText(item) }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="showResetDialog" max-width="500">
        <v-card>
          <v-card-title class="text-h5">Reset All Transactions?</v-card-title>
          <v-card-text>
            This will delete all existing ledger transactions and regenerate
            them for the past 2 months. This action cannot be undone.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showResetDialog = false">Cancel</v-btn>
            <v-btn
              color="error"
              :loading="resetting"
              @click="resetTransactions"
            >
              Reset Transactions
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <manual-transfer-dialog
        v-model="showManualTransfer"
        :accounts="accounts"
        @success="handleTransferSuccess"
      />

      <bulk-action-dialog
        v-model="showBulkTransfer"
        :accounts="accounts"
        :selected-lines="selectedTransactions"
        :title="$t('accounting.bulk_transfer.title')"
        action="transfer"
        @success="handleBulkTransferSuccess"
      />

      <!-- Transfer Details Modal -->
      <transfer-details-dialog
        v-model="showTransferDetailsDialog"
        :details="transferDetails"
      />

      <v-dialog v-model="showSyncDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6"> Sync Transactions </v-card-title>
          <v-card-text>
            <v-menu
              v-model="syncDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="syncFromDate"
                  label="Sync from date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="syncFromDate"
                @input="syncDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showSyncDialog = false"> Cancel </v-btn>
            <v-btn color="primary" :loading="syncing" @click="syncTransactions">
              Start Sync
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import LedgerFunctions from 'components/mixins/ledger-functions-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'
import Toaster from '@/utils/toaster'
import { downloadFile } from '@/utils/common-utils'
import ManualTransferDialog from './manual-transfer-dialog.vue'
import BulkActionDialog from './bulk-action-dialog.vue'
import TransferDetailsDialog from './transfer-details-dialog.vue'

export default {
  name: 'GeneralLedger',

  components: {
    ManualTransferDialog,
    BulkActionDialog,
    TransferDetailsDialog,
  },

  mixins: [CommonFunctions, LedgerFunctions, PermissionsMixin],
  // ... mixins and filters stay the same ...

  data: () => ({
    search: '',
    loading: false,
    transactions: [],
    fromMenu: false,
    toMenu: false,
    paginationServer: {
      page: 1,
      itemsPerPage: 100,
      count: 0,
    },
    filters: {
      dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .toISOString()
        .split('T')[0],
      dateTo: new Date().toISOString().split('T')[0],
      accountId: null,
      accountType: null,
      sourceType: null,
    },
    headers: [
      { text: 'Date', value: 'posted_at' },
      { text: 'Account', value: 'account' },
      { text: 'Description', value: 'description' },
      { text: 'Type', value: 'transaction_type' },
      { text: 'Amount', value: 'amount' },
      { text: 'Source Type', value: 'transactionable_type' },
      { text: 'Source', value: 'source' },
      { text: 'Transfer Status', value: 'transfer_status' },
      { text: 'Reference', value: 'reference' },
    ],
    accountTypes: [
      { text: 'Asset', value: 'asset' },
      { text: 'Liability', value: 'liability' },
      { text: 'Equity', value: 'equity' },
      { text: 'Income', value: 'income' },
      { text: 'Expense', value: 'expense' },
    ],
    sourceTypes: [
      { text: 'Expense', value: 'Expense' },
      { text: 'Reservation', value: 'Reservation' },
      { text: 'Bulk Transfer', value: 'BulkTransferLedgerTransaction' },
      { text: 'Manual Transfer', value: 'ManualTransferLedgerTransaction' },
    ],
    meta: {
      total_debit: 0,
      total_credit: 0,
      balance: 0,
    },
    resetting: false,
    showResetDialog: false,
    accounts: [],
    exporting: false,
    showManualTransfer: false,
    selected: [],
    showBulkTransfer: false,
    showTransferDetailsDialog: false,
    transferDetails: null,
    showSyncDialog: false,
    syncDateMenu: false,
    syncFromDate: new Date().toISOString().substr(0, 10),
    syncing: false,
  }),

  watch: {
    options: {
      handler() {
        this.fetchTransactions()
      },
      deep: true,
    },
  },

  computed: {
    isSuperAdmin() {
      return this.$store.state.user.super_admin
    },

    selectedCount() {
      return this.selected.filter(item => this.isRowSelectable(item)).length
    },

    selectedTransactions() {
      return this.selected
        .filter(item => this.isRowSelectable(item))
        .map(transaction => ({
          id: transaction.id,
          amount: transaction.amount,
          ledger_account_id: transaction.ledger_account.id,
          ledger_account_name: transaction.ledger_account.name,
          line_code: transaction.description,
        }))
    },
  },

  methods: {
    isRowSelectable(item) {
      // Don't allow selection if:
      // 1. It's a transfer-related transaction
      // 2. It's already part of a transfer
      // 3. It's a reversal transaction
      // 4. It's been reversed

      // Check transfer conditions
      const isTransferType = [
        'BulkTransferLedgerTransaction',
        'ManualTransferLedgerTransaction',
      ].includes(item.type)

      const isAlreadyTransferred =
        item.transactionable_type === 'LedgerTransfer' || item.transfer_info

      // Check reversal conditions
      const isReversal = item.reversal
      const hasBeenReversed = item.reversal_transaction

      return !(
        isTransferType ||
        isAlreadyTransferred ||
        isReversal ||
        hasBeenReversed
      )
    },

    getRowClass(item) {
      if (
        item.type === 'ManualTransferLedgerTransaction' ||
        (item.type === 'RegularLedgerTransaction' &&
          item.transactionable_type === 'LedgerTransfer')
      ) {
        return 'manual-transfer-row'
      }
      return ''
    },

    showBulkTransferDialog() {
      this.showBulkTransfer = true
    },

    paginationChanged(pagination) {
      if (this.paginationServer.itemsPerPage !== pagination.itemsPerPage) {
        this.paginationServer.itemsPerPage = pagination.itemsPerPage
        this.paginationServer.page = 1
        this.fetchTransactions()
      } else if (this.paginationServer.page !== pagination.page) {
        this.paginationServer.page = pagination.page
        this.fetchTransactions()
      }
    },

    handleFilterChange() {
      this.paginationServer.page = 1
      this.fetchTransactions()
    },

    async fetchAccounts() {
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts
      } catch (error) {
        console.error('Error fetching accounts:', error)
      }
    },

    async fetchTransactions() {
      this.loading = true
      try {
        const params = {
          page: this.paginationServer.page,
          per_page: this.paginationServer.itemsPerPage,
          search: this.search,
          ...this.filters,
        }

        const response = await axios.get('/api/ledger-transactions', {
          params,
        })
        this.transactions = response.data.transactions
        this.paginationServer.count = response.data.pagination.count
        this.meta = response.data.meta
      } catch (error) {
        console.error('Error fetching transactions:', error)
        this.$store.dispatch('showError', 'Failed to load transactions')
      } finally {
        this.loading = false
      }
    },

    confirmReset() {
      this.showResetDialog = true
    },

    async resetTransactions() {
      this.resetting = true
      try {
        await axios.post('/api/ledger-transactions/reset_transactions')
        this.showResetDialog = false
        Toaster.show([
          {
            type: 'success',
            text: 'Reset initiated. Transactions will be regenerated shortly.',
          },
        ])
        // Optionally refresh your data after a short delay
        setTimeout(() => {
          this.fetchTransactions()
        }, 5000)
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.resetting = false
      }
    },

    async exportToExcel() {
      this.exporting = true
      try {
        const response = await axios.get('/api/ledger-accounts/export', {
          params: {
            date_from: this.filters.dateFrom,
            date_to: this.filters.dateTo,
            account_type: this.filters.accountType,
            account_id: this.filters.accountId,
            source_type: this.filters.sourceType,
          },
          responseType: 'blob',
        })

        const filename =
          response.headers['content-disposition']
            ?.split('filename=')[1]
            ?.replace(/["']/g, '') || 'general_ledger.xlsx'

        downloadFile(response.data, filename)
      } catch (error) {
        console.error('Error exporting report:', error)
        Toaster.show([{ type: 'error', text: 'Failed to export report' }])
      } finally {
        this.exporting = false
      }
    },

    handleTransferSuccess() {
      this.fetchTransactions()
    },

    handleBulkTransferSuccess() {
      this.selected = []
      this.fetchTransactions()
    },

    async viewTransferDetails(item) {
      try {
        const id = item.transfer_info ? item.transfer_info.transfer_id : item.id
        const response = await axios.get(`/api/ledger-transactions/${id}`)
        this.transferDetails = response.data
        this.showTransferDetailsDialog = true
      } catch (error) {
        console.error('Error fetching transfer details:', error)
        Toaster.show([
          { type: 'error', text: 'Failed to load transfer details' },
        ])
      }
    },

    formatTransactionableType(item) {
      if (item.type === 'BulkTransferLedgerTransaction') {
        return 'Bulk Transfer'
      }
      if (item.type === 'ManualTransferLedgerTransaction') {
        return 'Manual Transfer'
      }
      if (item.transactionable_type) {
        return item.transactionable_type.replace(/([A-Z])/g, ' $1').trim()
      }
    },

    shouldShowTransferDetails(item) {
      return (
        item.type === 'BulkTransferLedgerTransaction' ||
        item.type === 'ManualTransferLedgerTransaction' ||
        item.transfer_info
      )
    },

    shouldShowReference(item) {
      return item.type !== 'BulkTransferLedgerTransaction'
    },

    getReferenceColor(item) {
      return item.reference_id ? 'primary' : 'grey darken-1'
    },

    getReferenceText(item) {
      if (item.transactionable_type === 'Reservation') {
        return `View Reservation`
      }
      if (item.transactionable_type === 'Expense') {
        return `View Expense`
      }
      return item.reference_id
    },

    async syncTransactions() {
      this.syncing = true
      try {
        await axios.post('/api/ledger-transactions/sync', {
          from_date: this.syncFromDate,
        })

        Toaster.show([
          {
            type: 'success',
            text: 'Sync initiated. Transactions will be processed shortly.',
          },
        ])
        this.showSyncDialog = false

        setTimeout(() => {
          this.fetchTransactions()
        }, 5000)
      } catch (error) {
        Toaster.show([{ type: 'error', text: 'Failed to sync transactions' }])
      } finally {
        this.syncing = false
      }
    },
  },

  created() {
    this.fetchAccounts()
    this.fetchTransactions()
  },
}
</script>

<style scoped>
.v-card__title {
  border-bottom: 1px solid #e0e0e0;
}

.account-text {
  font-size: 13px;
}

.account-select :deep(.v-select__selection) {
  font-size: 13px !important;
}

.total-card {
  height: 112px;
  border-color: var(--v-primary-base) !important;
}

.manual-transfer-row {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.manual-transfer-row td:first-child {
  width: 48px;
  padding: 0 !important;
}

.bulk-actions-row {
  min-height: 60px;
}

.highlighted-row {
  background-color: var(--v-primary-lighten-4) !important;
  transition: background-color 0.3s ease;
}
</style>
