<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center my-3">
      <div class="text-h6">{{ $t('PNL Settings') }}</div>
    </div>
    <v-card-text class="px-6">
      <v-row>
        <v-col cols="12">
          <v-select
            v-if="listing.optional_masters.length > 1"
            v-model="listing.master_guesty_id"
            outlined
            dense
            :items="listing.optional_masters"
            :label="$t('Main listing for owner display')"
            item-value="guesty_id"
            @change="save"
          >
            <template #selection="{ item }">
              {{ item.nickname }}
              <span
                class="pl-2"
                :class="item.listed ? 'primary--text' : 'error--text'"
                >{{ item.listed ? $t('Listed') : $t('Unlisted') }}</span
              >
            </template>
            <template #item="{ item }">
              {{ item.nickname }}
              <span
                class="pl-2"
                :class="item.listed ? 'primary--text' : 'error--text'"
                >{{ item.listed ? $t('Listed') : $t('Unlisted') }}</span
              >
            </template>
          </v-select>

          <v-autocomplete
            v-model="listing.second_guesty_id"
            :items="listingsPickerWithoutCurrent"
            item-value="guesty_id"
            item-text="nickname"
            clearable
            dense
            outlined
            :label="$t('Move reservations to (dup of)')"
            @change="save"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <div class="d-flex justify-space-between align-center my-3">
      <div class="text-h6">{{ $t('Smart Rules') }}</div>
    </div>
    <v-card-text class="px-6">
      <v-row>
        <v-col cols="12" sm="6">
          <smart-rules-create-modal
            :listing="listing"
            @fetchRules="fetchRules"
          />
        </v-col>
      </v-row>
      <div v-for="smartRule in listingRules.smart_rules" :key="smartRule.id">
        <div>
          <div class="d-flex align-center">
            <p class="text-subtitle-2">
              {{ ruleHeading() }}
            </p>
            <p class="pl-3 text-subtitle-2">
              {{ smartRule.target_name }}
            </p>
            <p class="pl-3 text-subtitle-2">
              <v-icon small color="warning" @click="removeRule(smartRule)"
                >fas fa-minus-circle</v-icon
              >
            </p>
            <v-spacer />
          </div>
          <v-divider />
        </div>
      </div>
    </v-card-text>
  </v-sheet>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import SmartRulesCreateModal from 'components/smart-rules/smart-rules-create-modal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ListingChannelAutomation',
  components: { SmartRulesCreateModal },
  mixins: [FormRules],
  props: ['listing'],
  data() {
    return {
      listingRules: [],
    }
  },
  async mounted() {
    this.fetchRules()
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    listingsPickerWithoutCurrent() {
      if (!this.listingsPicker) return []
      return this.listingsPicker.filter(
        e => e.guesty_id !== this.listing.guesty_id
      )
    },
  },
  methods: {
    save() {
      this.$store.commit('updateLoading', true)
      this.$store
        .dispatch('listings/updateListing', {
          id: this.listing.id,
          payload: {
            master_guesty_id: this.listing.master_guesty_id,
            second_guesty_id: this.listing.second_guesty_id,
          },
        })
        .finally(() => {
          this.$store.commit('updateLoading', false)
        })
    },
    ruleHeading() {
      return `When this listing is booked, block the same dates for: `
    },
    async fetchRules() {
      this.listingRules = await this.$store.dispatch(
        'smartRule/getSmartRules',
        this.listing.id
      )
    },
    async removeRule(rule) {
      this.$store.commit('updateLoading', true)
      await this.$store
        .dispatch('smartRule/deleteSmartRule', {
          id: rule.id,
          listing_id: this.listing.id,
        })
        .finally(this.fetchRules(), this.$store.commit('updateLoading', false))
      this.fetchRules()
    },
  },
}
</script>

<style lang="scss" scoped></style>
