<template>
  <div class="subtask-list-table">
    <div class="d-flex justify-space-between align-center pb-2">
      <div class="text--secondary text-subtitle-1">
        {{ title }}
        <span
          class="text-body-2 mx-3"
          :class="
            doneTasks === tasks.length ? 'success--text' : 'tertiary--text'
          "
        >
          {{ doneTasks }} / {{ tasks.length }} Done
        </span>
      </div>
      <div v-if="!isContractor && !isMobile">
        <v-menu v-if="selectedSubTasks.length" offset-y>
          <template #activator="{ on, attrs }">
            <v-btn color="darkpurple" text small v-bind="attrs" v-on="on">
              Actions <v-icon class="ml-2" small>fas fa-caret-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <LtFromSubtaskModal
              :selected="selectedSubTasks"
              :listing-task-id="listingTask.id"
              @onaction="resetTasks"
            >
              <template #activator="slotData">
                <v-list-item v-on="slotData.on">
                  <v-list-item-title
                    class="d-flex align-center darkpurple--text"
                  >
                    <v-icon class="mr-1" color="darkpurple" small
                      >fas fa-tasks</v-icon
                    >
                    New SC
                  </v-list-item-title>
                </v-list-item>
              </template>
            </LtFromSubtaskModal>
            <v-list-item v-if="selectedSubTasks.length === 1" @click="editTask">
              <v-list-item-title class="d-flex align-center info--text">
                <v-icon color="info" class="mr-1" small>edit</v-icon>
                Edit
              </v-list-item-title>
            </v-list-item>
            <ConfirmationModal
              text="Are you sure you want to delete those tasks ?"
              @action="deleteTask"
            >
              <template #activator="slotData">
                <v-list-item v-on="slotData.on">
                  <v-list-item-title class="d-flex align-center warning--text">
                    <v-icon small color="warning" class="mr-1">$trash</v-icon>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </template>
            </ConfirmationModal>
          </v-list>
        </v-menu>
        <LtSubtaskCreateModal
          v-if="!isMobileOrIPad && !readonly"
          :listing-task-id="listingTask.id"
          @created="onSubtaskCreated"
        >
          <template #activator="slotData">
            <v-btn class="white" elevation="0" small v-on="slotData.on">
              <v-icon small class="pr-1" color="darkpurple">fas fa-plus</v-icon>
              New
            </v-btn>
          </template>
        </LtSubtaskCreateModal>
      </div>
    </div>

    <v-simple-table class="subtask-table">
      <template #default>
        <thead>
          <tr>
            <th class="text-left" style="width: 40px">
              <v-checkbox
                v-model="selectAll"
                :indeterminate="
                  selectedSubTasks.length > 0 &&
                  selectedSubTasks.length < tasks.length
                "
                hide-details
                dense
                @click="toggleSelectAll"
              />
            </th>
            <th class="text-left">Description</th>
            <th class="text-left" style="width: 120px">Status</th>
            <th class="text-left" style="width: 80px">Files</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in sortedTasks" :key="task.id">
            <td>
              <v-checkbox
                :value="selectedSubTasks.includes(task.id)"
                hide-details
                dense
                @change="toggleTask(task.id)"
              />
            </td>
            <td>{{ task.description }}</td>
            <td>
              <lt-status
                :small="true"
                :listing-task="task"
                :is-sub-task="true"
                @after-change="onStatusChange"
                @change="updateTaskStatus"
              />
            </td>
            <td>
              <v-btn
                v-if="task.after_images?.length"
                icon
                x-small
                @click="showImages(task)"
              >
                <v-icon x-small>mdi-image</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import LtSubtaskCreateModal from './lt-subtask-create-modal'
import ConfirmationModal from 'components/modals/confirmation-modal'
import LtFromSubtaskModal from './lt-from-subtask-modal'
import DeviceMixin from 'components/mixins/device-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import LtStatus from 'components/listing-tasks/lt-status'

export default {
  name: 'LtSubtaskListTable',
  components: {
    LtFromSubtaskModal,
    ConfirmationModal,
    LtSubtaskCreateModal,
    LtStatus,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['tasks', 'listingTask', 'title', 'readonly'],
  data() {
    return {
      selectedSubTasks: [],
      selectAll: false,
    }
  },
  computed: {
    doneTasks() {
      return this.tasks.filter(
        t =>
          t.is_valid !== null || t.status === 'Done' || t.status === "Can't do"
      ).length
    },
    sortedTasks() {
      return [...this.tasks].sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    },
  },
  methods: {
    formatDate(date) {
      return this.parseDate(date)
    },
    getStatusColor(status) {
      const colors = {
        Done: 'success',
        'Working on it': 'warning',
        Stuck: 'error',
        New: 'info',
      }
      return colors[status] || 'grey'
    },
    toggleSelectAll() {
      if (this.selectedSubTasks.length === this.tasks.length) {
        this.selectedSubTasks = []
      } else {
        this.selectedSubTasks = this.tasks.map(t => t.id)
      }
    },
    toggleTask(taskId) {
      const index = this.selectedSubTasks.indexOf(taskId)
      if (index === -1) {
        this.selectedSubTasks.push(taskId)
      } else {
        this.selectedSubTasks.splice(index, 1)
      }
    },
    editTask() {
      this.$store.commit('showListingSubTaskModal', this.selectedSubTasks[0])
    },
    resetTasks() {
      this.selectedSubTasks = []
    },
    deleteTask() {
      this.$store.dispatch('deleteListingSubTask', {
        ids: this.selectedSubTasks,
        listingTaskId: this.listingTask.id,
      })
      this.selectedSubTasks = []
    },
    showImages(task) {
      // Implement image gallery view
    },
    showNotes(task) {
      // Implement notes view
    },
    onStatusChange() {
      this.$emit('status-changed')
    },
    onSubtaskCreated() {
      // Emit status-changed to refresh the parent
      this.$emit('status-changed')
      // Also refresh the Taskim section if we have a section_id
      if (this.listingTask.section_id) {
        this.$store.dispatch(
          'taskim/getSectionTasks',
          this.listingTask.section_id
        )
      }
    },
    updateTaskStatus(changes) {
      if (!changes || !changes.id) {
        console.error('No task ID provided for status update')
        return
      }
      this.$emit('status-changed')
      this.$store.dispatch(
        'taskim/getSectionTasks',
        this.listingTask.section_id
      )
    },
  },
}
</script>

<style scoped>
.subtask-list-table {
  width: 100%;
  padding-left: 0;
}

.subtask-table {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  margin-left: -8px; /* Align more to the left */
}

:deep(.v-data-table) {
  background-color: transparent;
}

:deep(.v-data-table > .v-data-table__wrapper > table) {
  padding: 0;
}

:deep(.v-data-table > .v-data-table__wrapper > table > thead > tr > th) {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  height: 40px;
  padding: 0 8px;
  font-weight: 500;
}

:deep(.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th:first-child),
:deep(.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:first-child) {
  padding-left: 16px; /* Add more padding to the first column */
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td) {
  height: 40px;
  padding: 0 8px;
  font-size: 0.875rem;
}

:deep(.v-input--selection-controls) {
  margin: 0;
  padding: 0;
}

:deep(.v-input--selection-controls__input) {
  margin: 0;
  padding: 0;
}

/* Adjust the header section alignment */
.d-flex.justify-space-between {
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
}
</style>
