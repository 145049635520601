<template>
  <div v-if="preCheckIn">
    <v-progress-linear
      v-show="checkinLoading"
      color="secondary"
      class="mb-1"
      :indeterminate="true"
    />
    <v-form ref="preCheckInForm" @submit.prevent="submitPreCheckin">
      <v-sheet
        v-if="preCheckIn"
        class="blue-grey--text"
        :class="flatView ? '' : 'comp-wrapper mt-4 mb-3'"
        :style="flatView ? '' : 'min-width: 400px'"
      >
        <v-row class="my-1" no-gutters>
          <v-col cols="5">Email:</v-col>
          <v-col cols="7" class="text--darken-2 font-weight-medium">
            <v-text-field
              v-model="preCheckIn.client_info.email"
              color="info"
              class="text-body-2 font-weight-medium info--text"
              :rules="emailRules"
              placeholder="Contact Email"
              dense
              @input="dirty = true"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="my-1" no-gutters>
          <v-col cols="5">Phone:</v-col>
          <v-col cols="7" class="text--darken-2 font-weight-medium">
            <phone-input
              v-model="preCheckIn.client_info.phone_number"
              @focus="dirty = true"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row v-if="preCheckIn.times_info" class="my-1" no-gutters>
          <v-col cols="5">Planned Arrival:</v-col>
          <v-col cols="7" class="text--darken-2 font-weight-medium">
            <datetime
              :value="preCheckIn.times_info?.requested_check_in_time"
              :minute-step="15"
              input-class="info--text text-decoration-underline pointer"
              format="HH:mm"
              type="time"
              :backdrop-click="false"
              @input="onTimeInput($event, 'requested_check_in_time')"
              @close="onTimeClose"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row v-if="preCheckIn.times_info" class="my-1" no-gutters>
          <v-col cols="5">Planned Departure:</v-col>
          <v-col cols="7" class="text--darken-2 font-weight-medium">
            <datetime
              :value="preCheckIn.times_info?.requested_check_out_time"
              :minute-step="15"
              input-class="info--text text-decoration-underline pointer"
              format="HH:mm"
              type="time"
              :backdrop-click="false"
              @input="onTimeInput($event, 'requested_check_out_time')"
              @close="onTimeClose"
            ></datetime>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="my-1" no-gutters>
          <v-col cols="5">Guest amount:</v-col>
          <v-col cols="7" class="text--darken-2 font-weight-medium">
            {{ guestAmount }}
          </v-col>
        </v-row>
        <v-divider />
        <h4 class="cyan--text mt-2">Guests</h4>
        <div v-if="preCheckIn.guests_details?.length">
          <div
            v-for="item in filteredGuests"
            :key="item.full_name"
            class="mb-2"
          >
            <v-row
              v-for="field in parsedGuest(item)"
              :key="field.key"
              class="my-2"
              no-gutters
            >
              <v-col cols="5">{{ field.key }}:</v-col>
              <v-col cols="7" class="text--darken-2 font-weight-medium">
                {{ field.val }}
              </v-col>
            </v-row>
            <v-divider />
          </div>
        </div>
        <div
          v-for="(item, index) in preCheckIn?.guest_count_info?.['guests'] ||
          []"
          :key="index"
          class="grey--text"
        >
          {{ item }}
        </div>
        <v-divider />
        <div
          v-if="
            preCheckIn?.has_assigned_parking ||
            preCheckIn.client_info?.car_amount
          "
        >
          <h4 class="cyan--text mt-2">Car info</h4>
          <span class="mt-1 grey--text">
            Needs parking: {{ preCheckIn.client_info.need_parking }}
          </span>
          <span v-if="preCheckIn.client_info.car_amount" class="mt-1 grey-text">
            Car amount: {{ preCheckIn.client_info.car_amount }}
          </span>
          <span v-if="preCheckIn.client_info.car_model" class="mt-1 grey--text">
            Car model: {{ preCheckIn.client_info.car_model }}
          </span>
          <span
            v-if="preCheckIn.client_info.licence_plate"
            class="mt-1 grey--text"
          >
            Licence plate: {{ preCheckIn.client_info.licence_plate }}
          </span>
        </div>
        <v-divider />
        <h4
          v-if="Object.keys(preCheckIn.special_requests).length === 0"
          class="cyan--text mt-2"
        >
          Special Requests
        </h4>
        <div
          v-for="(val, key) in preCheckIn.special_requests"
          v-show="isAllowedReq(key)"
          :key="key"
          class="grey--text"
        >
          {{ key }}
        </div>
        <div v-if="preCheckIn.id_pic" class="mt-2">
          <h4 class="cyan--text">ID image:</h4>
          <img
            id="id_pic"
            :src="preCheckIn.id_pic"
            class="comp-wrapper"
            style="max-width: 100%; max-height: 400px"
          />
        </div>
        <div v-if="preCheckIn.additional_id_images?.length" class="mt-2">
          <h4 class="cyan--text">Other guests ID images:</h4>
          <img
            v-for="(image, index) in preCheckIn.additional_id_images"
            id="additional_id_pics"
            :key="`additional-image-${index}`"
            :src="image"
            class="comp-wrapper"
            style="max-width: 100%; max-height: 400px"
          />
        </div>
        <div
          v-if="dirty"
          class="d-flex justify-space-between align-center mb-2"
        >
          <v-btn
            x-small
            color="success"
            class="font-weight-medium mt-3"
            depressed
            @click="submitPreCheckin"
            >Save</v-btn
          >
        </div>
      </v-sheet>
    </v-form>
  </div>
</template>

<script>
import { isNumber, pickBy, valuesIn, sum } from 'lodash'
import CommonFunctions from 'components/mixins/common_functions'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import { isEmpty } from 'lodash'
import PhoneInput from 'components/common/phone-input.vue'

const FIELDS = {
  full_name: 'Full name',
  email: 'Email',
  address: 'Address',
  phone_number: 'Phone',
  passport: 'Passport',
}

const STATUS_COLORS = {
  New: 'warning',
  'In Progress': 'amber',
  Done: 'success',
}

export default {
  components: { PhoneInput },
  mixins: [CommonFunctions, FormRulesMixin],
  props: ['preCheckIn', 'flatView', 'confirmationCode', 'editable'],
  data() {
    return {
      STATUS_COLORS,
      checkinLoading: false,
      dirty: false,
    }
  },
  computed: {
    guestsTypes() {
      return pickBy(this.preCheckIn.guest_count_info, isNumber)
    },
    guestAmount() {
      return sum(valuesIn(this.guestsTypes))
    },
    filteredGuests() {
      return this.preCheckIn.guests_details.filter(g => !isEmpty(g))
    },
  },
  methods: {
    isAllowedReq(reqName) {
      return (
        !reqName.includes('Long stay') &&
        this.preCheckIn.special_requests[reqName]
      )
    },
    parsedGuest(guest) {
      const fields = Object.entries(guest).map(([key, val]) => ({
        key: FIELDS[key] || key,
        val,
      }))
      const nameValue = fields.splice(
        fields.findIndex(f => f.key === FIELDS.full_name),
        1
      )
      fields.unshift(nameValue[0])
      return fields
    },
    onTimeInput(val, field) {
      this.preCheckIn.times_info[field] = this.$moment(val).format('HH:mm')
    },
    async onTimeClose() {
      this.dirty = true
    },
    async submitPreCheckin() {
      if (!this.$refs.preCheckInForm.validate()) return
      const { times_info, id } = this.preCheckIn
      await this.$store.dispatch('updatePreCheckIn', {
        id,
        client_info: this.preCheckIn.client_info,
        times_info: {
          requested_check_in_time: times_info.requested_check_in_time,
          requested_check_out_time: times_info.requested_check_out_time,
        },
      })
      this.dirty = false
    },
  },
}
</script>
<style scoped>
:deep() .v-text-field__slot > input {
  color: var(--v-info-base) !important;
}
:deep() .v-input__slot:before,
.v-input__slot:after {
  display: none;
}

:deep() .v-input__slot .vue-tel-input {
  border: none;
  font-size: 0.875rem;
}
:deep() .v-input__slot .vue-tel-input > input {
  color: unset !important;
}
</style>
