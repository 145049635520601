<template>
  <section class="listings">
    <v-container>
      <v-row
        v-if="managementRoles && !isMobileOrIPad"
        class="align-center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="3"
          class="d-flex d-sm-block justify-space-between justify-sm-start"
        >
          <v-btn v-if="!showTable" outlined large @click="exportListings">
            <v-icon class="mr-2" small>$download</v-icon>
            <span class="text-capitalize">{{ $t('Export') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="9"
          class="d-flex align-center justify-sm-end justify-space-between"
        >
          <v-btn-toggle
            v-model="listingType"
            class="d-inline-flex grey lighten-4 rounded mb-2 mb-sm-0 mr-sm-4 pa-1"
          >
            <v-btn
              v-if="isAdmin"
              value="active"
              elevation="0"
              @click="updateIsActiveListing(true)"
            >
              {{ $t('Active') }}
            </v-btn>
            <v-btn
              v-if="isAdmin"
              value="disabled"
              elevation="0"
              @click="updateIsActiveListing(false)"
            >
              {{ $t('Disabled') }}
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-if="isAdmin"
            v-model="dups"
            class="grey lighten-4 rounded mb-2 mb-sm-0 mr-sm-n1 pa-1"
            :mandatory="false"
          >
            <v-btn value="dups" elevation="0" @click="dupToggle">
              {{ $t('Dups') }}</v-btn
            >
          </v-btn-toggle>
          <v-btn-toggle
            v-model="multi"
            class="grey lighten-4 rounded mb-2 mb-sm-0 me-sm-4 pa-1"
            :mandatory="false"
          >
            <v-btn :value="true" elevation="0" @click="multiToggle">
              {{ $t('Multi') }}</v-btn
            >
          </v-btn-toggle>
          <div class="d-inline-flex grey lighten-4 rounded mb-2 mb-sm-0 pa-1">
            <v-btn
              :color="!showTable ? 'white' : ''"
              elevation="0"
              @click="showTable = false"
            >
              <v-icon small>$tiles</v-icon>
            </v-btn>
            <v-btn
              :color="showTable ? 'white' : ''"
              elevation="0"
              @click="displayTable"
            >
              <v-icon small>$table_view</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-container class="px-6 px-sm-3">
      <div class="d-flex align-center flex-column justify-center">
        <v-row>
          <v-text-field
            v-model="searchTerm"
            :label="$t('Search')"
            hide-details
            outlined
            append-icon="search"
            dense
            sm="6"
            cols="12"
            class="px-3 mt-2 mb-2"
          />
          <v-autocomplete
            v-if="managementRoles"
            v-model="regionFilter"
            :label="$t('Regions')"
            dense
            outlined
            hide-details
            clearable
            :items="listingRegions"
            class="col-sm-6 col-12 px-3 mt-2 mb-2"
          />
        </v-row>

        <v-pagination
          v-if="pagination && pagination.properties_count > 29 && !showTable"
          v-model="page"
          :length="Math.ceil(pagination.properties_count / pagination.per_page)"
          prev-icon="chevron_left"
          next-icon="chevron_right"
          class="mt-3 mb-3 flex"
          @input="updatePage"
        />
      </div>

      <v-row v-if="showTable" wrap mt-2>
        <v-col>
          <listings-table
            :get-properties="getProperties"
            :items="filteredListings"
          />
        </v-col>
      </v-row>
      <v-row v-show="!showTable">
        <v-col
          v-for="listing in filteredListings"
          :key="listing.id"
          class="d-flex px-6 px-sm-3"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <listing-card
            :id="listing.id"
            :listing="listing"
            :stage="listing.stage"
            :requests="listing.requests"
            :address="listing.title || listing.address"
            :active="listing.active"
            :occupancy-month="listing.yearly_stats['30_days']"
            :occupancy-three-month="listing.yearly_stats['90_days']"
            :occupancy-two-weeks="listing.yearly_stats['14_days']"
            :score="listing.yearly_stats.health"
            :listing-type="listing.listing_type"
            :image="listing.picture"
            @update-listing="updateListing"
          />
        </v-col>
      </v-row>
      <v-pagination
        v-if="pagination && pagination.properties_count > 29 && !showTable"
        v-model="page"
        :length="Math.ceil(pagination.properties_count / pagination.per_page)"
        prev-icon="chevron_left"
        next-icon="chevron_right"
        class="mt-3 mb-3"
        @input="updatePage"
      />
    </v-container>
  </section>
</template>

<script>
import ListingCard from './listing-card.vue'
import orderBy from 'lodash/orderBy'
import debounce from 'lodash/debounce'

import ListingsTable from './listings-table.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isEmpty } from 'lodash/fp'

export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  data: function () {
    const { activeListings } = this.$route.query
    return {
      page: +this.$route.query.page || 1,
      dups: this.$route.query.dups ? 'dups' : null,
      multi: false,
      searchTerm: '',
      regionFilter: null,
      showTable: false,
      listingType: 'active',
      showActiveListings: activeListings ? activeListings === 'true' : true,
      privateMode: false,
    }
  },
  methods: {
    ...mapActions('listings', ['getListings']),
    updatePage(val) {
      this.$router.push({
        query: { ...this.$route.query, page: val },
      })
    },
    updateIsActiveListing(val) {
      this.$router.push({
        query: { activeListings: val, page: 1 },
      })
    },
    dupToggle() {
      const { dups, ...rest } = this.$route.query
      if (dups) {
        this.$router.push({
          query: rest,
        })
      } else {
        this.$router.push({
          query: { page: 1, dups: 1 },
        })
      }
    },
    multiToggle() {
      const { multi, ...rest } = this.$route.query
      const query = { ...rest }
      if (!multi) {
        query.multi = true
        query.page = 1
      }
      this.$router.push({ query })
    },
    displayTable() {
      this.showTable = true
    },
    exportListings() {
      this.$store.commit('updateListingLoading', true)
      axios
        .get(`/api/listings/all/export-to-excel`, {
          responseType: 'blob',
        })
        .then(response => {
          this.$store.commit('updateListingLoading', false)
          this.autoDownloadBlobData(response.data, 'listings-report.xlsx')
        })
        .catch(error => {
          alert(error)
        })
    },
    getProperties: function (extra = {}) {
      let defaultFilters = {
        page: extra.page || this.page,
        dups: this.dups,
        multi: this.multi,
        active: this.showActiveListings,
        search_term: this.searchTerm,
        get_extra_fields: this.showTable,
        region: this.regionFilter,
      }
      if (!this.isEmpty(extra)) {
        for (const [key, value] of Object.entries(extra)) {
          if (value) {
            defaultFilters[key] = value
          }
        }
      }
      this.getListings(defaultFilters)
    },
    updateListing(listingId, listingData) {
      const index = this.listings.findIndex(l => l.id === listingId)
      this.listings[index] = { ...this.listings[index], ...listingData }
    },
  },
  mounted() {
    if (this.$route.query.multi) this.multi = true
    if (this.isContractor || this.isCleaner || this.isCleaningManager) {
      this.$router.push({ name: 'service-calls' })
    }
    const query = { multi: this.$route.query.multi }
    if (!this.$route.query.activeListings) {
      query.activeListings = true
    }
    if (!this.$route.query.page) {
      query.page = 1
    }
    if (Object.keys(query).length > 0) {
      this.$router.replace({ query })
    }
    if (!this.isInvestor) {
      this.$store.dispatch('getListingInfoDefs')
    }
    this.regionFilter = this.currentUser.region
      ? +this.currentUser.region
      : null
  },
  computed: {
    ...mapState('listings', ['listings', 'pagination']),
    ...mapState('regions', ['regions']),
    ...mapGetters(['currentUser']),
    listingRegions() {
      return this.regions.map(r => ({
        text: r.name,
        value: r.id,
      }))
    },
    filteredListings() {
      if (this.listings) {
        let res = []
        this.listings.forEach(listing => {
          if (listing.active === this.showActiveListings) {
            res.push(listing)
          }
        })
        res = orderBy(
          res.filter(r => !(r.container_multi_id && !r.listed)),
          'yearly_stats.health',
          'desc'
        )
        return res
      }
      return []
    },
  },

  components: {
    'listing-card': ListingCard,
    ListingsTable,
  },
  watch: {
    searchTerm: {
      handler: function () {
        this.page = 1
        this.debouncer.call(this)
      },
      deep: true,
    },
    regionFilter: {
      handler: function () {
        this.page = 1
        this.debouncer.call(this)
      },
      deep: true,
    },
    '$route.query': {
      handler({ activeListings, page, dups, multi }, old) {
        if (isEmpty(old)) {
          return
        }
        this.showActiveListings = activeListings
          ? activeListings === 'true'
          : true
        this.page = +page || 1
        this.dups = dups ? 'dups' : null
        this.getListings({
          page: this.page,
          dups,
          multi: multi,
          active: this.showActiveListings,
          search_term: this.searchTerm,
          region: this.regionFilter,
        })
      },
    },
  },

  created() {
    this.debouncer = debounce(this.getProperties, 750)
  },
}
</script>
