<template>
  <v-tabs v-model="paramsTab">
    <v-tab href="#pre-check-ins">{{ $t('Check-In/Out') }}</v-tab>
    <v-tab v-if="isManagementRolesUser" href="#store"
      >{{ $t('Purchases') }}
    </v-tab>
    <v-tab href="#requests">{{ $t('Requests') }} </v-tab>
    <v-tab v-if="isAdmin" href="#settings"
      >{{ $t('Checkin & Store settings') }}
    </v-tab>
    <v-tab-item
      v-if="paramsTab === 'pre-check-ins'"
      id="pre-check-ins"
      class="ma-4"
    >
      <pre-check-ins />
    </v-tab-item>
    <v-tab-item v-if="paramsTab === 'store'" id="store" class="ma-4">
      <purchase-table></purchase-table>
    </v-tab-item>
    <v-tab-item v-if="paramsTab === 'settings'" id="settings" class="ma-4">
      <digital-checkin />
    </v-tab-item>
    <v-tab-item v-if="paramsTab === 'requests'" id="requests" class="ma-4">
      <requests
        v-if="tasksCatalog.catalogItemSelect.length"
        :catalog="requestCatalogItemId"
      />
      <v-progress-linear
        v-else
        color="secondary"
        class="ma-0"
        :indeterminate="true"
      />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import PreCheckIns from 'components/crm/pre-check-ins.vue'
import Common_functions from 'components/mixins/common_functions'
import DigitalCheckin from 'components/guests/digital-checkin.vue'
import PurchaseTable from 'components/store/purchase-table.vue'
import Requests from 'components/guests/requests.vue'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'GuestsMgmt',
  mixins: [Common_functions, PermissionsMixin],
  components: {
    PurchaseTable,
    PreCheckIns,
    DigitalCheckin,
    Requests,
  },
  computed: {
    ...mapState(['tasksCatalog']),
    requestCatalogItemId() {
      const item = this.tasksCatalog.catalogItemSelect.find(
        c => c.department === 'store' && c.sub_topic === 'scheduling'
      )
      return item && item.id
    },
  },
  methods: {
    ...mapActions('reservation', ['fetchChannels']),
  },
  mounted() {
    this.fetchChannels()
  },
}
</script>
<style></style>
