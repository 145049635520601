<template>
  <div>
    <bank-matcher
      v-if="chosenTransaction"
      :central-bank-view="!listing"
      :change="attachChange"
      :listing-id="filters.listingId"
    />
    <v-card>
      <v-card-text>
        <v-row wrap class="bb-1 align-center">
          <v-col md="auto">
            <v-tabs
              v-model="selectedTab"
              class="pa-0 ma-0"
              @change="tabChanged"
            >
              <v-tab>Transactions</v-tab>
              <v-tab>All Due To Do</v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            {{ $t('Total') }}: {{ summary.total_amount }}</v-col
          >
          <v-col cols="auto">
            {{ $t('Reconciled') }}: {{ summary.total_reported }}</v-col
          >
          <v-col cols="auto">
            {{ $t('Dismissed') }}: {{ summary.total_dismissed }}</v-col
          >
          <v-col cols="auto">
            <v-file-input
              accept=".csv"
              label="CSV Upload"
              outlined
              prepend-inner-icon="mdi-note-text"
              :messages="['test']"
              prepend-icon=""
              dense
              @change="uploadAction"
            >
              <template #message>
                Click
                <a
                  class="info--text text-decoration-underline"
                  @click.prevent="downloadCsv"
                  >here</a
                >
                to download an expenses csv template
              </template>
            </v-file-input>
          </v-col>
        </v-row>

        <v-row
          v-if="
            !listing &&
            isAdmin &&
            hasAbility(['super-accountant', 'accounting'])
          "
          :key="selectedTab"
        >
          <v-col md="auto" cols="2">
            <property-picker
              :not-top-header="true"
              :after-change="listingChanged"
              :value="filters.listingId"
              label="Listing"
              :prepend-listings="listingsToPrepend"
              :clearable="true"
            />
          </v-col>
          <v-col md="auto" cols="2">
            <v-autocomplete
              :label="$t('Assignee')"
              dense
              outlined
              item-text="name"
              :items="usersWithCredits"
              item-value="id"
              :value="filters.userId"
              @change="assigneeChange"
            >
              <template #selection="{ item }">
                {{ item.name }}
              </template>
              <template
                #item="{ item }"
                class="listing-picker purple-icon mb-1 bb-1 pb-1"
              >
                <v-avatar v-if="item.picture">
                  <img :src="item.picture" />
                </v-avatar>

                <v-avatar v-else :color="roleColor(item)">
                  <span class="white--text text-h5">{{ initials(item) }}</span>
                </v-avatar>
                <span class="ml-3">
                  {{ item.name }}
                  <span class="grey--text">
                    - {{ item.bank_accounts_numbers }}
                  </span>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="auto" cols="2">
            <v-text-field
              v-model="filters.search"
              hide-details
              dense
              outlined
              label="Search By Reference"
              clearable
              @input="fetchTransactionsDebounce()"
            />
          </v-col>
          <v-col md="auto" cols="2">
            <v-select
              v-model="filters.chosenCredit"
              :items="companyAccountsItems"
              outlined
              dense
              label="Credit"
              clearable
              @change="chosenCreditChange"
            />
          </v-col>
        </v-row>

        <v-row v-if="selectedTab === 0">
          <v-col md="auto">
            <v-sheet color="grey lighten-4" class="rounded-pill mt-2">
              <v-chip-group
                v-model="filters.status"
                class="pl-2"
                @change="fetchTransactions(1)"
              >
                <v-chip
                  v-for="item in statuses"
                  :key="item"
                  :value="item"
                  :color="
                    filters.status === item
                      ? 'secondary darken-1'
                      : 'transparent'
                  "
                  class="flex-grow-1 justify-center"
                  small
                >
                  {{ item }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </v-col>
          <v-col md="auto" cols="2">
            <v-select
              v-model="filters.transactionType"
              label="Type"
              :items="['All', 'SPEND', 'RECEIVE']"
              class="cyan-icon"
              style="max-width: 100px"
              @change="transactionTypeChange($event)"
            />
          </v-col>
          <v-col md="auto" cols="5">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="parsedFrom"
                  label="From"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="filters.from"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                min="2018-01-01"
                @change="saveFrom"
              />
            </v-menu>
          </v-col>
          <v-col cols="5" md="auto">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="parsedTo"
                  label="To"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="filters.to"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                min="2018-01-01"
                @change="saveTo"
              />
            </v-menu>
          </v-col>
          <v-col cols="3" md="auto">
            <v-text-field
              ref="fromPriceInput"
              v-model="filters.fromPrice"
              prepend-icon="money"
              label="From price"
              required
              :rules="[required, isPositive]"
              @change="fromPriceChange($event)"
            />
          </v-col>
          <v-col cols="3" md="auto">
            <v-text-field
              ref="toPriceInput"
              v-model="filters.toPrice"
              prepend-icon="money"
              label="To price"
              required
              :rules="[required, isPositive]"
              @change="toPriceChange($event)"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="filters.chosenContact"
              :items="allContacts"
              label="Choose Contact"
              clearable
              @input="chosenContactChange($event)"
            />
          </v-col>
          <v-col md="auto" cols="4">
            <v-select
              v-model="filters.institutionName"
              label="Bank"
              :items="bankNames"
              class="cyan-icon"
              style="max-width: 100px"
              @change="institutionNameChange($event)"
            />
          </v-col>
        </v-row>

        <v-progress-circular
          v-show="listingLoading"
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        />
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="currentBankTransactions"
        class="elevation-1 text-sm-center"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, -1],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #item="{ item }">
          <tr>
            <td v-if="item.bank_account" :class="isAttached(item)">
              <span
                v-if="item.related_to"
                class="text-sm-center bolder purple-icon"
              >
                {{ item.related_to }} | </span
              ><span class="cyan-icon">{{
                item.bank_account.account_name
              }}</span>
            </td>
            <td class="text-sm-center" :class="isAttached(item)">
              {{ parseDate(item.transferred_at) }}
            </td>

            <td class="text-sm-center" :class="isAttached(item)">
              <div :class="typeClass(item.transaction_type)">
                <v-icon v-if="shouldWarn(item)" class="red-icon"
                  >warning</v-icon
                >
                {{ item.amount.toLocaleString('en') }}
                <span class="cyan-icon bolder">/</span>
                <span v-if="isStripeTransaction(item)">
                  <a
                    v-if="managementRoles && item.payout"
                    target="_blank"
                    :href="`https://dashboard.stripe.com/payouts/${item.payout.payout_id}`"
                    ><span> {{ calcReportedPayoutAmount(item) }}</span>
                  </a>
                </span>
                <span v-else>
                  {{ item.extra_data.attached_to_sum.toLocaleString('en') }}
                </span>
              </div>
              <div v-if="isStripeTransaction(item)">
                <v-tooltip color="white" top>
                  <template #activator="{ on }">
                    <span class="orange--text" v-on="on"
                      >({{
                        (
                          item.payout.reserved +
                          item.payout.cart_amount +
                          item.payout.stripe_fee
                        ).toLocaleString('en')
                      }})
                      <v-icon x-small>fas fa-question-circle</v-icon>
                    </span>
                  </template>
                  <payout-summary :payout="item.payout" />
                </v-tooltip>
              </div>
            </td>

            <td class="text-sm-center" :class="isAttached(item)">
              <span v-if="itemContact">({{ itemContact(item) }})</span>
              {{ item.reference }}
            </td>
            <td class="text-sm-center grey-text" :class="isAttached(item)">
              {{ item.institution_name }}
            </td>
            <td class="text-sm-center grey-text" :class="isAttached(item)">
              {{ item.bank_account_id }}
            </td>
            <td class="text-sm-center" :class="isAttached(item)">
              <v-icon v-if="item.attached_to" class="cyan-icon"
                >done_all
              </v-icon>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    v-if="item.attached_to"
                    small
                    class="icon alt help-icon"
                    v-on="on"
                    >fas fa-question-circle
                  </v-icon>
                </template>
                <v-layout wrap style="min-width: 300px">
                  <v-flex
                    xs12
                    style="border-bottom: 1px dotted white"
                    class="pb-1"
                  >
                    Attached to
                  </v-flex>
                </v-layout>
                <v-layout
                  v-if="!isEmpty(item.extra_data.attached_to)"
                  wrap
                  style="min-width: 300px"
                >
                  <v-flex xs12>
                    <v-layout
                      wrap
                      class="center-text"
                      style="border-bottom: 1px dotted white"
                    >
                      <v-flex xs3>Type</v-flex>
                      <v-flex xs3>Amount</v-flex>
                      <v-flex xs3>Date</v-flex>
                      <v-flex xs3>Info</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    v-for="(attachement, index) in item.extra_data.attached_to"
                    :key="index"
                    xs12
                    style="border-bottom: 1px dotted white"
                    class="pb-1"
                  >
                    <v-layout wrap class="center-text">
                      <v-flex xs3>{{ attachementType(attachement) }}</v-flex>
                      <v-flex xs3>{{ attachementPrice(attachement) }}</v-flex>
                      <v-flex xs3
                        >{{ parseDate(attachementDate(attachement)) }}
                      </v-flex>
                      <v-flex xs3>{{ attachementDesc(attachement) }}</v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-tooltip>
              <dismiss-reason-modal
                v-if="!item.attached_to"
                :dismissed="item.manual_dismiss"
                :item="item"
                type="BankTransaction"
                @after-action="onDismiss"
              />
            </td>
            <td class="text-center" :class="isAttached(item)">
              <v-switch
                hide-details
                dense
                readonly
                class="d-inline-block pa-0 ma-0"
                :disabled="
                  !hasAbility('bank-transactions-due-to-do') &&
                  !hasAbility('super-accountant')
                "
                :value="item.due_to_do"
                @click="dueToDoChanged(item)"
              />
              <ConfirmationModal
                ref="DueToDoConfirmationModal"
                text="Are you sure?"
                @action="toggleDueToDo"
              />
            </td>
            <td class="text-center" :class="isAttached(item)">
              <v-icon
                small
                class="mr-2"
                style="cursor: pointer"
                @click="openMatch(item)"
              >
                search
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import BankMatcher from 'components/bank-matcher.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import ConfirmationModal from 'components/modals/confirmation-modal'
import PropertyPicker from 'components/property-picker'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import DismissReasonModal from 'components/dismiss-reason-modal'
import { debounce, sumBy } from 'lodash'
import PayoutSummary from 'components/stripe/payout-summary'

export default {
  components: {
    PayoutSummary,
    PropertyPicker,
    ConfirmationModal,
    BankMatcher,
    DismissReasonModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: ['listing'],
  data: function () {
    return {
      selectedTab: 0,
      selectedItem: null,
      bankNames: [],
      headers: [
        { text: 'Related to', align: 'center', sortable: false },
        { text: 'Transferred at', align: 'center', sortable: false },
        { text: 'Total/Attached', align: 'center', sortable: false },
        { text: 'Reference', align: 'center', sortable: false },
        { text: 'Bank name', align: 'center', sortable: false },
        { text: 'Account', align: 'center', sortable: false },
        { text: 'Is attached', align: 'center', sortable: false },
        { text: 'Due to do', align: 'center', sortable: false },
        { text: 'Actions', align: 'center', sortable: false },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 50,
        itemsLength: null,
      },
      menu: false,
      menu2: false,
      status: 'All',
      statuses: ['All', 'Dismissed', 'Reconciled', 'Not Matched'],
      filters: {},
      fetchTransactionsDebounce: debounce(this.fetchTransactions, 500),
      listingsToPrepend: [{ id: 'false', nickname: '- Company accounts -' }],
    }
  },
  mounted() {
    this.filters = this.getInitialFilters()
    this.fetchTransactions()
  },
  computed: {
    ...mapState([
      'currentBankTransactions',
      'transactionsSummary',
      'chosenTransaction',
    ]),
    ...mapGetters('users', ['usersList', 'usersMap']),
    usersWithCredits() {
      return this.usersList.filter(user => user.bank_accounts_numbers.length)
    },
    summary() {
      const { total_amount, total_reported, total_dismissed } =
        this.transactionsSummary || {}
      return {
        total_amount: total_amount ? total_amount.toLocaleString('en') : 0,
        total_reported: total_reported
          ? total_reported.toLocaleString('en')
          : 0,
        total_dismissed: total_dismissed
          ? total_dismissed.toLocaleString('en')
          : 0,
      }
    },
    allContacts() {
      let res = []
      this.currentBankTransactions.forEach(item => {
        if (
          item.contact_name &&
          item.contact_name !== 'listings expenses' &&
          item.contact_name !== '' &&
          item.contact_name !== 'generic'
        ) {
          res.push(item.contact_name)
        }
      })
      return res
    },
    parsedFrom() {
      return this.filters.from
        ? this.$moment(this.filters.from).format('YYYY/MM/DD')
        : ''
    },
    parsedTo() {
      return this.filters.to
        ? this.$moment(this.filters.to).format('YYYY/MM/DD')
        : ''
    },
    companyAccountsItems() {
      return this.$store.getters.companyAccounts.map(c => c.account_number)
    },
  },
  methods: {
    ...mapActions(['getBankTransactionsByFilter']),
    ...mapActions('bankAccounts', ['csvImportTransactions']),
    isStripeTransaction(item) {
      return (
        item.reference === 'ORIG CO NAME:STRIPE' &&
        item.contact_name === 'Stripe Deposit' &&
        item.payout
      )
    },
    calcReportedPayoutAmount(item) {
      const sum = item.reservations_bank_transactions.reduce((acc, rt) => {
        acc += sumBy(rt.payments, 'amount')
        return acc
      }, 0)
      return (
        sum +
        item.payout.reserved +
        item.payout.stripe_fee +
        item.payout.cart_amount
      ).toLocaleString('en')
    },
    tabChanged() {
      this.filters = this.getInitialFilters()
      this.fetchTransactions(1)
    },
    getInitialFilters() {
      const date = new Date()
      let userId = this.hasAbility(['super-accountant', 'accounting'])
        ? null
        : this.$store.state.user.id
      if (this.selectedTab === 0) {
        return {
          listingId: (this.listing || {}).id,
          userId: userId,
          chosenCredit: null,
          chosenContact: null,
          from: new Date(date.getFullYear(), date.getMonth() - 1, 1)
            .toISOString()
            .substr(0, 10),
          to: new Date(date.getFullYear(), date.getMonth(), 1)
            .toISOString()
            .substr(0, 10),
          transactionType: 'All',
          institutionName: 'All',
          fromPrice: 1,
          toPrice: 100000,
          attachedTo: 'All',
          status: 'All',
        }
      } else if (this.selectedTab === 1) {
        return {
          listingId: (this.listing || {}).id,
          userId: userId,
          chosenCredit: null,
          onlyDueToDo: true,
        }
      } else return {}
    },
    fetchTransactions(page) {
      if (page) this.pagination.page = page
      this.getBankTransactionsByFilter({
        page: this.pagination.page,
        perPage: this.pagination.itemsPerPage,
        search: this.filters.search,
        listingId: this.filters.listingId,
        chosenContact: this.filters.chosenContact,
        chosenCredit: this.filters.chosenCredit,
        from: this.filters.from,
        to: this.filters.to,
        transactionType: this.filters.transactionType,
        institutionName: this.filters.institutionName,
        fromPrice: this.filters.fromPrice,
        toPrice: this.filters.toPrice,
        userId: this.filters.userId,
        attachedTo: this.filters.attachedTo,
        status: this.filters.status,
        onlyDueToDo: this.filters.onlyDueToDo,
      }).then(res => {
        if (this.pagination.page === 1) {
          this.pagination.itemsLength = parseInt(res.pagi_info.count)
          this.bankNames = res.institution_names
        }
      })
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchTransactions()
      }
    },
    dueToDoChanged(item) {
      this.selectedItem = item
      this.$refs.DueToDoConfirmationModal.dialog = true
    },
    toggleDueToDo() {
      this.$store
        .dispatch('bankTransactionDueToDo', {
          id: this.selectedItem.id,
          value: !this.selectedItem.due_to_do,
        })
        .then(res => {
          if (res.data) {
            this.selectedItem.due_to_do = !this.selectedItem.due_to_do
            if (this.selectedItem.due_to_do) {
              this.selectedItem.manual_dismiss = true
            }
            this.$forceUpdate()
          }
        })
    },
    onDismiss() {
      this.fetchTransactions()
    },
    itemContact(item) {
      if (
        item.contact_name &&
        item.contact_name !== 'listings expenses' &&
        item.contact_name !== '' &&
        item.contact_name !== 'generic'
      ) {
        return item.contact_name
      }
    },
    listingChanged(val) {
      this.filters.listingId = val
      this.fetchTransactions(1)
    },
    assigneeChange(userId) {
      this.filters.userId = userId
      this.filters.chosenCredit = null
      this.fetchTransactions(1)
    },
    isAttached(item) {
      if (item.attached_to || item.manual_dismiss) {
        return 'green lighten-5'
      } else {
        return 'super-light-red'
      }
    },
    shouldWarn(item) {
      if (item.amount && item.extra_data.attached_to_sum) {
        return item.amount !== item.extra_data.attached_to_sum
      } else {
        return true
      }
    },
    attachChange() {
      this.fetchTransactions(this.pagination.page)
    },
    typeClass(type) {
      if (type === 'RECEIVE' || type === 'RECEIVE-TRANSFER') {
        return 'green-health'
      } else {
        return 'red-health'
      }
    },
    saveFrom(date) {
      this.$refs.menu.save(date)
      this.fetchTransactions(1)
    },
    saveTo(date) {
      this.$refs.menu2.save(date)
      this.fetchTransactions(1)
    },
    fromPriceChange(newVal) {
      if (this.$refs.fromPriceInput.validate()) {
        this.filters.fromPrice = newVal
        this.fetchTransactions(1)
      }
    },
    toPriceChange(newVal) {
      if (this.$refs.toPriceInput.validate()) {
        this.filters.toPrice = newVal
        this.fetchTransactions(1)
      }
    },
    chosenContactChange(newVal) {
      this.filters.chosenContact = newVal
      this.fetchTransactions(1)
    },
    chosenCreditChange() {
      this.fetchTransactions(1)
    },
    transactionTypeChange(newVal) {
      this.filters.transactionType = newVal
      this.fetchTransactions(1)
    },
    institutionNameChange(newVal) {
      this.filters.institutionName = newVal
      this.fetchTransactions(1)
    },
    openMatch: function (transaction) {
      this.$store.commit('updateChosenTransaction', transaction)
    },
    async uploadAction(file) {
      if (file) {
        await this.csvImportTransactions({ file })
        this.fetchTransactions()
      }
    },
    downloadCsv() {
      const rows = [['', '', '', '']]
      rows.unshift([
        'Date',
        'Description',
        'Amount',
        'Balance',
        'Transaction ID',
        'Account name',
      ])
      this.autoDownloadLocalCsv(rows, 'bank_transactions_template')
    },
  },
}
</script>
