<template>
  <v-container>
    <TrackPms v-if="$route.params.name === 'track'" />
    <component :is="$route.params.name" v-else />
  </v-container>
</template>

<script>
import Guesty from 'components/marketplace/guesty.vue'
import Xero from 'components/marketplace/xero.vue'
import Slack from 'components/marketplace/slack.vue'
import Stripe from 'components/marketplace/stripe.vue'
import Hostaway from 'components/marketplace/hostaway.vue'
import Optima from 'components/marketplace/optima.vue'
import Plaid from 'components/marketplace/plaid.vue'
import Quickbooks from 'components/marketplace/quickbooks.vue'
import Rentalsunited from 'components/marketplace/rentals-united.vue'
import Webhook from 'components/marketplace/webhook.vue'
import Boomcm from 'components/marketplace/boomcm.vue'
import Track from 'components/marketplace/track.vue'
import Pricelabs from 'components/marketplace/pricelabs.vue'
import Hostfully from 'components/marketplace/hostfully.vue'
import Aloware from 'components/marketplace/aloware.vue'
import Kross from 'components/marketplace/kross.vue'
import Superhog from 'components/marketplace/superhog.vue'
import Chekin from 'components/marketplace/chekin.vue'
import Spreedly from '@/components/marketplace/spreedly.vue'
import Seam from 'components/marketplace/seam.vue'
import Bridgerpay from 'components/marketplace/bridgerpay.vue'
import Wheelhouse from 'components/marketplace/wheelhouse.vue'

export default {
  name: 'IntegrationPage',
  components: {
    Kross,
    Aloware,
    Hostfully,
    Pricelabs,
    TrackPms: Track,
    Boomcm,
    Webhook,
    Rentalsunited,
    Quickbooks,
    Plaid,
    Optima,
    Hostaway,
    Stripe,
    Slack,
    Xero,
    Guesty,
    Superhog,
    Chekin,
    Spreedly,
    Seam,
    Bridgerpay,
    Wheelhouse,
  },
}
</script>

<style scoped></style>
