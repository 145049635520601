<template>
  <v-container fluid>
    <!-- Report Controls -->
    <v-row class="mb-4">
      <v-col cols="12" sm="4">
        <v-menu
          ref="startMenu"
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="dates.start"
              label="Start Date"
              type="date"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dates.start"
            no-title
            @input="startMenu = false"
          />
        </v-menu>
      </v-col>

      <v-col cols="12" sm="4">
        <v-menu
          ref="endMenu"
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="dates.end"
              label="End Date"
              type="date"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dates.end"
            no-title
            @input="endMenu = false"
          />
        </v-menu>
      </v-col>

      <v-col cols="12" sm="4">
        <v-select
          v-model="groupBy"
          :items="groupByOptions"
          label="Group By"
          clearable
          :disabled="selectedTab !== 2"
        />
      </v-col>
    </v-row>

    <!-- Report Type Tabs -->
    <v-card>
      <v-card-title class="text-h5">
        General Ledger Report
        <v-spacer />
      </v-card-title>

      <v-tabs v-model="selectedTab">
        <v-tab :value="0">Summary</v-tab>
        <v-tab :value="1">Detail</v-tab>
        <v-tab :value="2">Matrix</v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="selectedTab">
          <!-- Summary Report -->
          <v-tab-item :value="0">
            <v-data-table
              :headers="summaryHeaders"
              :items="summaryData.report?.accounts || []"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
              class="elevation-1"
            >
              <template #body.append>
                <tr class="grey lighten-4">
                  <td class="text-right font-weight-bold">Totals:</td>
                  <td></td>
                  <td class="text-right font-weight-bold">
                    {{ formatCurrency(summaryData.report?.totals?.debit || 0) }}
                  </td>
                  <td class="text-right font-weight-bold">
                    {{
                      formatCurrency(summaryData.report?.totals?.credit || 0)
                    }}
                  </td>
                  <td class="text-right font-weight-bold primary--text">
                    {{ formatCurrency(summaryData.report?.totals?.net || 0) }}
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>

          <!-- Detail Report -->
          <v-tab-item :value="1">
            <v-data-table
              :headers="detailHeaders"
              :items="detailData"
              :loading="loading"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, -1],
              }"
              class="elevation-1"
            >
              <template #no-data>
                No data available (Debug: items length =
                {{ detailData.length }})
              </template>
              <template #item="{ item }">
                <tr>
                  <td>{{ formatDate(item.date) }}</td>
                  <td>{{ item.account }}</td>
                  <td>{{ item.account_code }}</td>
                  <td>{{ item.description || '-' }}</td>
                  <td>{{ item.source || '-' }}</td>
                  <td class="text-right">{{ formatCurrency(item.debit) }}</td>
                  <td class="text-right">{{ formatCurrency(item.credit) }}</td>
                </tr>
              </template>
              <template #body.append>
                <tr class="grey lighten-4">
                  <td colspan="5" class="text-right font-weight-bold">
                    Totals:
                  </td>
                  <td class="text-right font-weight-bold">
                    {{ formatCurrency(detailTotals.debit) }}
                  </td>
                  <td class="text-right font-weight-bold">
                    {{ formatCurrency(detailTotals.credit) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>

          <!-- Matrix Report -->
          <v-tab-item :value="2">
            <v-data-table
              :headers="matrixHeaders"
              :items="matrixData.rows || []"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
              class="elevation-1"
            >
              <template #item="{ item }">
                <tr>
                  <td>{{ item.account }}</td>
                  <td>{{ item.code }}</td>
                  <td
                    v-for="city in Object.keys(item.cities)"
                    :key="city"
                    class="text-right"
                  >
                    {{ formatCurrency(item.cities[city]) }}
                  </td>
                  <td class="text-right font-weight-bold">
                    {{ formatCurrency(item.total) }}
                  </td>
                </tr>
              </template>
              <template #body.append>
                <tr class="grey lighten-4">
                  <td colspan="2" class="text-right font-weight-bold">
                    Totals:
                  </td>
                  <td
                    v-for="city in Object.keys(matrixTotals.cities)"
                    :key="city"
                    class="text-right font-weight-bold"
                  >
                    {{ formatCurrency(matrixTotals.cities[city]) }}
                  </td>
                  <td class="text-right font-weight-bold primary--text">
                    {{ formatCurrency(matrixTotals.total) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import axios from 'axios'
import { Toaster } from '@/utils/toaster'

export default {
  name: 'PnlReport',

  mixins: [CommonFunctions],

  data() {
    console.log('data() called')
    const today = new Date()
    const lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)

    return {
      isLoading: false,
      selectedTab: 0,
      dates: {
        start: lastMonth.toISOString().split('T')[0],
        end: today.toISOString().split('T')[0],
      },
      startMenu: false,
      endMenu: false,
      groupBy: null,
      groupByOptions: [
        { text: 'City', value: 'city' },
        { text: 'Market Source', value: 'market_source' },
        { text: 'Region', value: 'region' },
        { text: 'Zone', value: 'zone' },
      ],
      summaryData: {},
      detailData: [],
      matrixData: { columns: [], rows: [] },
      runningBalances: {},
    }
  },

  computed: {
    loading() {
      return this.isLoading || (this.$parent && this.$parent.loading)
    },

    formatDateRange() {
      return `${this.dates.start} - ${this.dates.end}`
    },

    cities() {
      if (!this.matrixData.columns) return []
      return this.matrixData.columns.filter(
        col => col !== 'Account' && col !== 'Code'
      )
    },

    dynamicHeaders() {
      const headers = [
        {
          text: 'Account',
          value: 'account',
          sortable: true,
          width: '300',
        },
      ]

      this.cities.forEach(city => {
        headers.push({
          text: city,
          value: `cities.${city}`,
          align: 'right',
          sortable: true,
        })
      })

      return headers
    },

    matrixItems() {
      if (!this.matrixData.rows) return []

      const accounts = {}

      this.matrixData.rows.forEach(item => {
        if (!accounts[item.account]) {
          accounts[item.account] = {
            account: item.account,
            account_type: item.account_type,
            cities: {},
          }
        }
        accounts[item.account].cities[item.code] = item.total
      })

      return Object.values(accounts).sort((a, b) => {
        if (a.account_type === b.account_type) {
          return a.account.localeCompare(b.account)
        }
        return a.account_type === 'revenue' ? -1 : 1
      })
    },

    summaryHeaders() {
      return [
        { text: 'Account', value: 'account', width: '30%' },
        { text: 'Code', value: 'account_code', width: '10%' },
        {
          text: 'Debit',
          value: 'debit',
          width: '15%',
          align: 'right',
          format: this.formatCurrency,
        },
        {
          text: 'Credit',
          value: 'credit',
          width: '15%',
          align: 'right',
          format: this.formatCurrency,
        },
        {
          text: 'Net Movement',
          value: 'net_movement',
          width: '15%',
          align: 'right',
          format: this.formatCurrency,
        },
        { text: 'Type', value: 'account_type', width: '15%' },
      ]
    },

    detailHeaders() {
      return [
        { text: 'Date', value: 'date', width: '10%' },
        { text: 'Account', value: 'account', width: '20%' },
        { text: 'Account Code', value: 'account_code', width: '10%' },
        { text: 'Description', value: 'description', width: '25%' },
        { text: 'Source', value: 'source', width: '15%' },
        {
          text: 'Debit',
          value: 'debit',
          width: '10%',
          align: 'right',
        },
        {
          text: 'Credit',
          value: 'credit',
          width: '10%',
          align: 'right',
        },
      ]
    },

    matrixHeaders() {
      if (!this.matrixData.columns) return []

      return this.matrixData.columns.map(col => ({
        text: col,
        value: col.toLowerCase(),
        align: col === 'Account' || col === 'Code' ? 'left' : 'right',
        width: col === 'Account' ? '30%' : col === 'Code' ? '10%' : null,
      }))
    },

    detailTotals() {
      return this.detailData.reduce(
        (totals, item) => {
          totals.debit += item.debit ? parseFloat(item.debit) : 0
          totals.credit += item.credit ? parseFloat(item.credit) : 0
          return totals
        },
        { debit: 0, credit: 0 }
      )
    },

    matrixTotals() {
      if (!this.matrixData.rows || this.matrixData.rows.length === 0) {
        return { cities: {}, total: 0 }
      }

      return this.matrixData.rows.reduce(
        (totals, row) => {
          // Add city totals
          Object.entries(row.cities).forEach(([city, amount]) => {
            const parsedAmount = amount ? parseFloat(amount) : 0
            totals.cities[city] = (totals.cities[city] || 0) + parsedAmount
          })
          // Add to grand total
          totals.total += row.total ? parseFloat(row.total) : 0
          return totals
        },
        { cities: {}, total: 0 }
      )
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return ''
      const d = new Date(date)
      const month = d.toLocaleString('default', { month: 'short' })
      const day = d.getDate()
      const year = d.getFullYear()
      return `${month} ${day}, ${year}`
    },

    formatCurrency(value) {
      if (!value && value !== 0) return ''
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    },

    calculateRunningBalance(item) {
      const key = `${item.account_code}-${item.date}`
      if (this.runningBalances[key]) return this.runningBalances[key]

      let balance = 0
      this.detailData.forEach(transaction => {
        if (
          transaction.account_code === item.account_code &&
          new Date(transaction.date) <= new Date(item.date)
        ) {
          balance += (transaction.credit || 0) - (transaction.debit || 0)
        }
      })

      this.runningBalances[key] = balance
      return balance
    },

    async fetchReport() {
      console.log('fetchReport called')
      this.isLoading = true
      try {
        const reportType = Number(this.selectedTab)

        console.log('Making API request with params:', {
          start_date: this.dates.start,
          end_date: this.dates.end,
          report_type: reportType,
          group_by: this.groupBy,
        })

        const { data } = await axios.get(
          '/api/ledger-transactions/pnl_report',
          {
            params: {
              start_date: this.dates.start,
              end_date: this.dates.end,
              report_type: reportType,
              group_by: this.groupBy,
            },
          }
        )

        console.log('API Response:', data)
        console.log('Selected Tab:', this.selectedTab)
        console.log('Selected Tab type:', typeof this.selectedTab)
        console.log('Report Type:', reportType)

        switch (Number(this.selectedTab)) {
          case 0:
            console.log('Processing summary data')
            this.summaryData = data
            break
          case 1:
            console.log('Processing detail data')
            console.log('Raw detail data:', data)
            this.detailData = data?.report || []
            console.log('Assigned detailData:', this.detailData)
            this.runningBalances = {}
            break
          case 2:
            console.log('Processing matrix data')
            this.matrixData = data.report || { columns: [], rows: [] }
            break
        }
      } catch (error) {
        console.error('API Error:', error)
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
        })
        Toaster.error('Failed to fetch P&L report')
      } finally {
        this.isLoading = false
      }
    },

    calculateColumnTotal(column) {
      if (!this.matrixData.rows) return 0
      return this.matrixData.rows.reduce((sum, row) => {
        return sum + (row.cities[column] || 0)
      }, 0)
    },

    calculateGrandTotal() {
      if (!this.matrixData.rows) return 0
      return this.matrixData.rows.reduce((sum, row) => {
        return sum + (row.total || 0)
      }, 0)
    },
  },

  created() {
    console.log('Component created')
  },

  mounted() {
    console.log('Component mounted')
    this.$nextTick(() => {
      this.fetchReport()
    })
  },

  watch: {
    selectedTab(newVal) {
      console.log('Tab changed to:', newVal)
      this.fetchReport()
    },

    'dates.start'() {
      console.log('Start date changed')
      this.fetchReport()
    },

    'dates.end'() {
      console.log('End date changed')
      this.fetchReport()
    },

    groupBy() {
      console.log('Group by changed')
      this.fetchReport()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table ::v-deep {
  td.text-right,
  th.text-right {
    text-align: right !important;
  }

  td {
    white-space: nowrap;
  }
}
</style>
