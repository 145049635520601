<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        class="text-h6 font-weight-bold primary--text d-flex justify-space-between align-center"
      >
        <div>{{ $t('Ledger Account Lines') }}</div>
        <v-spacer />
        <div class="d-flex align-center">
          <v-select
            v-model="groupBy"
            :items="groupByOptions"
            label="Group by"
            style="max-width: 200px"
            dense
            outlined
            hide-details
          />
        </div>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="filters.lineCode"
              label="Line Type"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="filters.objectType"
              :items="objectTypes"
              label="Source Type"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="filters.source"
              :items="reservationSources"
              label="Channel Source"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="filters.regionId"
              :items="regions"
              item-text="name"
              item-value="id"
              label="Region"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="filters.zoneId"
              :items="zones"
              item-text="name"
              item-value="id"
              label="Zone"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="filters.accountId"
              :items="accounts"
              item-text="name"
              item-value="id"
              label="Account"
              outlined
              dense
              clearable
              hide-details
              class="mb-4 account-select"
            >
              <template #item="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
              <template #selection="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <template v-if="groupBy">
        <div
          v-for="(items, groupName) in groupedLines"
          :key="groupName"
          class="group-section"
        >
          <v-list-item
            class="group-header grey lighten-4"
            dense
            @click="toggleGroup(groupName)"
          >
            <v-list-item-icon class="mr-2">
              <v-icon small>
                {{
                  expandedGroups[groupName]
                    ? 'mdi-chevron-down'
                    : 'mdi-chevron-right'
                }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                {{ groupName }}
                <span class="text-caption grey--text ml-2">
                  ({{ items.length }})
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-expand-transition>
            <div v-if="expandedGroups[groupName]">
              <v-data-table
                :headers="headers"
                :items="items"
                hide-default-header
                hide-default-footer
                class="group-items"
                dense
              >
                <template #item="{ item }">
                  <tr class="group-item">
                    <td>{{ item.line_code_display }}</td>
                    <td>
                      <v-chip
                        x-small
                        :color="
                          item.object_type === 'Reservation'
                            ? 'success'
                            : 'error'
                        "
                        text-color="white"
                      >
                        {{ item.object_type }}
                      </v-chip>
                    </td>
                    <td>{{ item.source || 'all' }}</td>
                    <td>{{ item.region?.name || 'all' }}</td>
                    <td>{{ item.zone?.name || 'all' }}</td>
                    <td>
                      <v-chip
                        x-small
                        :color="
                          item.default_type === 'credit' ? 'success' : 'error'
                        "
                        text-color="white"
                      >
                        {{ item.default_type }}
                      </v-chip>
                    </td>
                    <td>
                      <v-select
                        v-model="item.ledger_account_id"
                        :items="accounts"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                        :loading="updatingLine === item.id"
                        @change="updateAccount(item)"
                      >
                        <template #item="{ item: account }">
                          <div>
                            <strong>{{ account.name }}</strong>
                            <span class="grey--text text--darken-1 ml-2">
                              ({{ account.code }})
                            </span>
                          </div>
                        </template>
                        <template #selection="{ item: account }">
                          <div class="d-flex align-center">
                            {{ account.name }}
                            <span class="grey--text text--darken-1 ml-2">
                              ({{ account.code }})
                            </span>
                          </div>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="duplicateLine(item)"
                      >
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-expand-transition>
        </div>
      </template>

      <v-data-table
        v-else
        :headers="headers"
        :items="filteredLedgerLines"
        :loading="loading"
        :items-per-page="15"
        class="elevation-1 ledger-lines-table"
      >
        <template #item.line_code="{ item }">
          {{ item.line_code_display }}
        </template>

        <template #item.object_type="{ item }">
          <v-chip
            small
            :color="item.object_type === 'Reservation' ? 'success' : 'error'"
            text-color="white"
          >
            {{ item.object_type }}
          </v-chip>
        </template>

        <template #item.source="{ item }">
          {{ item.source || 'all' }}
        </template>

        <template #item.region="{ item }">
          {{ item.region?.name || 'all' }}
        </template>

        <template #item.zone="{ item }">
          {{ item.zone?.name || 'all' }}
        </template>

        <template #item.default_type="{ item }">
          <v-chip
            small
            :color="item.default_type === 'credit' ? 'success' : 'error'"
            text-color="white"
          >
            {{ item.default_type }}
          </v-chip>
        </template>

        <template #item.ledger_account="{ item }">
          <v-select
            v-model="item.ledger_account_id"
            :items="accounts"
            item-text="name"
            item-value="id"
            dense
            outlined
            hide-details
            :loading="updatingLine === item.id"
            @change="updateAccount(item)"
          >
            <template #item="{ item: account }">
              <div>
                <strong>{{ account.name }}</strong>
                <span class="grey--text text--darken-1 ml-2">
                  ({{ account.code }})
                </span>
              </div>
            </template>
            <template #selection="{ item: account }">
              <div class="d-flex align-center">
                {{ account.name }}
                <span class="grey--text text--darken-1 ml-2">
                  ({{ account.code }})
                </span>
              </div>
            </template>
          </v-select>
        </template>

        <template #item.recognised_at="{ item }">
          <v-select
            v-model="item.recognised_at"
            :items="getRecognisedAtOptions(item.object_type)"
            dense
            outlined
            hide-details
            @change="updateRecognisedAt(item)"
          />
        </template>

        <template #item.actions="{ item }">
          <v-btn small text color="primary" @click="duplicateLine(item)">
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <v-dialog v-model="showDuplicateDialog" max-width="600px">
        <v-card>
          <v-card-title>Duplicate Line Configuration</v-card-title>
          <v-card-text>
            <v-form ref="duplicateForm">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :value="duplicateForm.object_type"
                    label="Source Type"
                    outlined
                    disabled
                    dense
                    readonly
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :value="duplicateForm.line_code_display"
                    label="Line Type"
                    disabled
                    outlined
                    dense
                    readonly
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="duplicateForm.source"
                    :items="reservationSources"
                    label="Source"
                    outlined
                    dense
                    :rules="[
                      v =>
                        hasAtLeastOneAttribute ||
                        'At least one of Source, Region, or Zone must be specified',
                    ]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="duplicateForm.region_id"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                    label="Region"
                    outlined
                    dense
                    :rules="[
                      v =>
                        hasAtLeastOneAttribute ||
                        'At least one of Source, Region, or Zone must be specified',
                    ]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="duplicateForm.zone_id"
                    :items="zones"
                    item-text="name"
                    item-value="id"
                    label="Zone"
                    outlined
                    dense
                    :rules="[
                      v =>
                        hasAtLeastOneAttribute ||
                        'At least one of Source, Region, or Zone must be specified',
                    ]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="duplicateForm.ledger_account_id"
                    :items="accounts"
                    item-text="name"
                    item-value="id"
                    label="Account"
                    outlined
                    dense
                  >
                    <template #item="{ item }">
                      <div>
                        <strong>{{ item.name }}</strong>
                        <span class="grey--text text--darken-1 ml-2">
                          ({{ item.code }})
                        </span>
                      </div>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="duplicateForm.recognised_at"
                    :items="getRecognisedAtOptions(duplicateForm.object_type)"
                    label="Recognised At"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="showDuplicateDialog = false">Cancel</v-btn>
            <v-btn color="primary" @click="saveDuplicate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import Toaster from '@/utils/toaster'
import { mapState } from 'vuex'

export default {
  name: 'LedgerAccountLines',

  data: () => ({
    groupBy: null,
    expandedGroups: {},
    loading: false,
    ledgerLines: [],
    accounts: [],
    updatingLine: null,
    showDuplicateDialog: false,
    duplicateForm: {
      source: null,
      region_id: null,
      zone_id: null,
      ledger_account_id: null,
      recognised_at: null,
      object_type: null,
      line_code: null,
      line_code_display: null,
      default_type: null,
    },
    filters: {
      lineCode: '',
      objectType: null,
      source: null,
      accountId: null,
      regionId: null,
      zoneId: null,
    },
    headers: [
      {
        text: 'Line Type',
        value: 'line_code',
        sortable: true,
        width: '250',
      },
      {
        text: 'Source Type',
        value: 'object_type',
        sortable: true,
        width: '120',
      },
      {
        text: 'Source',
        value: 'source',
        sortable: true,
        width: '120',
      },
      {
        text: 'Region',
        value: 'region.name',
        sortable: true,
        width: '120',
      },
      {
        text: 'Zone',
        value: 'zone.name',
        sortable: true,
        width: '120',
      },
      {
        text: 'Type',
        value: 'default_type',
        sortable: true,
        width: '100',
      },
      {
        text: 'Assigned Account',
        value: 'ledger_account',
        sortable: false,
        width: '400',
      },
      {
        text: 'Recognised At',
        value: 'recognised_at',
        sortable: true,
        width: '150',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '80',
      },
    ],
    recognisedAtOptionsByType: {
      Reservation: [
        { text: 'At Confirmation', value: 'confirmed_at' },
        { text: 'At Check-in', value: 'check_in' },
        { text: 'At Check-out', value: 'check_out' },
      ],
      Expense: [{ text: 'At Payment', value: 'paid_at' }],
      StoreItemPurchase: [{ text: 'At Creation', value: 'created_at' }],
    },
    reservationSources: ['Airbnb', 'Booking.com', 'Direct', 'Manual'],
    groupByOptions: [
      { text: 'None', value: null },
      { text: 'Line Type', value: 'line_type' },
      { text: 'Source', value: 'source' },
      { text: 'Region', value: 'region' },
      { text: 'Zone', value: 'zone' },
    ],
  }),

  computed: {
    ...mapState('regions', ['regions']),
    ...mapState('zones', ['zones']),

    hasAtLeastOneAttribute() {
      return !!(
        this.duplicateForm.source ||
        this.duplicateForm.region_id ||
        this.duplicateForm.zone_id
      )
    },

    objectTypes() {
      return [...new Set(this.ledgerLines.map(line => line.object_type))]
    },

    filteredLedgerLines() {
      let filtered = [...this.ledgerLines]

      if (this.filters.lineCode) {
        const search = this.filters.lineCode.toLowerCase()
        filtered = filtered.filter(line =>
          line.line_code_display.toLowerCase().includes(search)
        )
      }

      if (this.filters.objectType) {
        filtered = filtered.filter(
          line => line.object_type === this.filters.objectType
        )
      }

      if (this.filters.source) {
        filtered = filtered.filter(line => line.source === this.filters.source)
      }

      if (this.filters.accountId) {
        filtered = filtered.filter(
          line => line.ledger_account_id === this.filters.accountId
        )
      }

      if (this.filters.regionId) {
        filtered = filtered.filter(
          line => line.region_id === this.filters.regionId
        )
      }

      if (this.filters.zoneId) {
        filtered = filtered.filter(line => line.zone_id === this.filters.zoneId)
      }

      filtered = filtered.sort((a, b) => {
        if (a.ledger_account && !b.ledger_account) return -1
        if (!a.ledger_account && b.ledger_account) return 1
        return a.line_code.localeCompare(b.line_code)
      })

      return this.isGrouped ? this.groupLines(filtered) : filtered
    },

    groupedHeaders() {
      return [
        {
          text: 'Line Type',
          value: 'line_code',
          sortable: true,
          width: '250',
        },
        {
          text: 'Source Type',
          value: 'object_type',
          sortable: true,
          width: '120',
        },
        {
          text: 'Configurations',
          value: 'configurations',
          sortable: false,
          width: '400',
        },
        {
          text: 'Type',
          value: 'default_type',
          sortable: true,
          width: '100',
        },
        {
          text: 'Default Account',
          value: 'ledger_account',
          sortable: false,
          width: '400',
        },
      ]
    },

    tableHeaders() {
      return this.isGrouped ? this.groupedHeaders : this.headers
    },

    groupedLines() {
      if (!this.groupBy) return {}

      return this.filteredLedgerLines.reduce((groups, line) => {
        let groupKey
        switch (this.groupBy) {
          case 'line_type':
            groupKey = line.line_code_display
            break
          case 'source':
            groupKey = line.source || 'Unspecified'
            break
          case 'region':
            groupKey = line.region?.name || 'Unspecified'
            break
          case 'zone':
            groupKey = line.zone?.name || 'Unspecified'
            break
          default:
            groupKey = 'Other'
        }

        if (!groups[groupKey]) {
          groups[groupKey] = []
        }
        groups[groupKey].push(line)
        return groups
      }, {})
    },
  },

  mounted() {
    this.fetchLedgerLines()
    this.fetchAccounts()
  },

  methods: {
    async fetchAccounts() {
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts || []
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },

    async fetchLedgerLines() {
      this.loading = true
      try {
        const response = await axios.get('/api/ledger-account-lines')
        this.ledgerLines = response.data.map(line => ({
          ...line,
          ledger_account_id: line.ledger_account?.id,
          region: line.region || null,
          zone: line.zone || null,
        }))
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.loading = false
      }
    },

    async updateAccount(item) {
      this.updatingLine = item.id
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          ledger_account_id: item.ledger_account_id,
        })
        Toaster.show([{ type: 'success', text: 'Account mapping updated' }])
        await this.fetchLedgerLines()
        this.$emit('line-updated')
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.updatingLine = null
      }
    },

    async updateSource(item) {
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          source: item.source,
        })
        Toaster.show([{ type: 'success', text: 'Source updated' }])
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },

    async updateRegion(item) {
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          region_id: item.region_id,
        })
        Toaster.show([{ type: 'success', text: 'Region updated' }])
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },

    async updateZone(item) {
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          zone_id: item.zone_id,
        })
        Toaster.show([{ type: 'success', text: 'Zone updated' }])
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },

    getRecognisedAtOptions(objectType) {
      return this.recognisedAtOptionsByType[objectType] || []
    },

    async updateRecognisedAt(item) {
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          recognised_at: item.recognised_at,
        })
        Toaster.show([{ type: 'success', text: 'Recognition timing updated' }])
        await this.fetchLedgerLines()
        this.$emit('line-updated')
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },

    duplicateLine(item) {
      this.duplicateForm = {
        ...item,
        source: item.source || null,
        region_id: item.region_id || null,
        zone_id: item.zone_id || null,
        ledger_account_id: null,
        recognised_at: item.recognised_at || null,
        object_type: item.object_type,
        line_code: item.line_code,
        line_code_display: item.line_code_display,
        default_type: item.default_type,
      }
      this.showDuplicateDialog = true
    },

    async saveDuplicate() {
      if (!this.$refs.duplicateForm.validate()) return
      if (!this.hasAtLeastOneAttribute) return

      try {
        const response = await axios.post('/api/ledger-account-lines', {
          source: this.duplicateForm.source,
          region_id: this.duplicateForm.region_id,
          zone_id: this.duplicateForm.zone_id,
          ledger_account_id: this.duplicateForm.ledger_account_id,
          recognised_at: this.duplicateForm.recognised_at,
          line_code: this.duplicateForm.line_code,
          object_type: this.duplicateForm.object_type,
          default_type: this.duplicateForm.default_type,
        })
        console.log(response.data)

        if (response.data) {
          Toaster.show([{ type: 'success', text: 'Line created successfully' }])
          this.showDuplicateDialog = false
          await this.fetchLedgerLines()
        }
      } catch (error) {
        console.log(error)
        Toaster.show([
          {
            type: 'error',
            text:
              error.response?.data?.response_message || 'Failed to create line',
          },
        ])
      }
    },

    groupLines(lines) {
      const groups = lines.reduce((acc, line) => {
        const key = `${line.line_code}_${line.object_type}`
        if (!acc[key]) {
          acc[key] = {
            line_code: line.line_code,
            line_code_display: line.line_code_display,
            object_type: line.object_type,
            default_type: line.default_type,
            configurations: [],
            base_account: null,
          }
        }

        // Find the base configuration (without source/region/zone)
        if (!line.source && !line.region_id && !line.zone_id) {
          acc[key].base_account = line.ledger_account
        } else {
          acc[key].configurations.push({
            source: line.source || 'all',
            region: line.region?.name || 'all',
            zone: line.zone?.name || 'all',
            account: line.ledger_account?.name,
          })
        }

        return acc
      }, {})

      return Object.values(groups).map(group => ({
        ...group,
        configurations: group.configurations
          .map(
            config =>
              `${config.source} / ${config.region} / ${config.zone} → ${
                config.account || 'Unassigned'
              }`
          )
          .join('\n'),
      }))
    },

    toggleGroup(groupName) {
      this.$set(this.expandedGroups, groupName, !this.expandedGroups[groupName])
    },
  },
}
</script>

<style scoped>
.v-card {
  width: 100%;
}

.v-data-table {
  width: 100%;
}

.account-text {
  font-size: 13px;
}

.account-select :deep(.v-select__selection) {
  font-size: 13px !important;
}

.ledger-lines-table {
  width: 100%;
}

:deep(.v-data-table__wrapper) {
  overflow-x: auto;
}

.configurations-text {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 12px;
  margin: 0;
  padding: 4px;
}

.group-section {
  border-bottom: 1px solid #e0e0e0;
}

.group-header {
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5 !important;
  }
}

.group-items {
  background-color: white;
  width: 100%;
}

.group-item {
  &:hover {
    background-color: #f5f5f5;
  }
  td {
    padding: 8px 16px;
    border-bottom: 1px solid #e0e0e0;
  }
}

:deep(.v-data-table) {
  width: 100% !important;
}
</style>
