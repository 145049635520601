<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card>
      <v-progress-linear v-if="loading" height="4" indeterminate />
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          `Business Model - ${model.name}`
        }}</v-toolbar-title>
      </v-toolbar>

      <v-tabs v-model="activeTab">
        <v-tab>General</v-tab>
        <v-tab>Expenses</v-tab>
        <v-tab>Formulas</v-tab>
        <v-tab>Custom Variables</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <!-- General Tab -->
        <v-tab-item>
          <v-container>
            <v-card flat>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.name"
                      label="Name"
                      dense
                      outlined
                      required
                      :rules="[required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="model.description"
                      dense
                      outlined
                      label="Description"
                    />
                  </v-col>
                  <v-col cols="12">
                    <multi-property-picker v-model="model.listing_ids" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-tab-item>

        <!-- Expense Types Tab -->
        <v-tab-item>
          <v-container>
            <expense-types
              :business-model-id="model.id"
              @update="handleExpenseTypesUpdate"
            />
          </v-container>
        </v-tab-item>

        <!-- Formulas Tab -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4" lg="6">
                <v-card flat>
                  <v-card-text>
                    <div class="d-flex align-center mb-4">
                      <div class="text-h6">{{ $t('Formula Settings') }}</div>
                      <formula-variables-tool-tip />
                      <v-spacer />
                      <v-switch
                        v-model="isFormulaEditable"
                        :label="$t('Edit Mode')"
                        dense
                        hide-details
                        class="mt-0 pt-0"
                      />
                    </div>
                    <v-row class="align-baseline">
                      <v-col cols="12">
                        <enhanced-formula-builder
                          v-model="model.net_income_formula"
                          label="Net Income Formula"
                          :variables="formulaVariables"
                          :editable="isFormulaEditable"
                        />
                      </v-col>
                      <v-col cols="12">
                        <enhanced-formula-builder
                          v-model="model.management_commission_formula"
                          label="Management Commission Formula"
                          :variables="formulaVariables"
                          :editable="isFormulaEditable"
                        />
                      </v-col>
                      <v-col cols="12">
                        <enhanced-formula-builder
                          v-model="model.owner_revenue_formula"
                          label="Owner Profit Formula"
                          :variables="formulaVariables"
                          :editable="isFormulaEditable"
                        />
                      </v-col>
                      <v-col cols="12">
                        <enhanced-formula-builder
                          v-model="model.owners_income_formula"
                          label="Channels Income Value (Channels)"
                          :variables="formulaVariables"
                          :hint="
                            !model.owners_income_formula
                              ? `Using system default (fare_accommodation)`
                              : null
                          "
                          :editable="isFormulaEditable"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="model.hide_channel_fee_expense"
                          label="Hide Channel Fee Expense"
                          hint="When enabled, channel fee expenses will be hidden from the owner statement"
                          persistent-hint
                          dense
                          class="mt-4"
                          :disabled="!isFormulaEditable"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="model.show_owner_cleaning"
                          :label="`Show Owner Cleaning (${
                            model.show_owner_cleaning ? 'Enabled' : 'Disabled'
                          })`"
                          persistent-hint
                          hint="When enabled, owner cleaning income will be shown in the owner statement"
                          dense
                          class="mt-4"
                          :disabled="!isFormulaEditable"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="save"
                      :disabled="!isFormulaEditable"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" lg="3" class="d-flex flex-column">
                <div class="sticky-preview">
                  <sample-reservation-data
                    :formula-variables="formulaVariables"
                    :sample-data="sampleReservation"
                    class="mb-4"
                  />
                  <pnl-preview
                    :model="model"
                    :sample-reservation="sampleReservation"
                    :calculated-values="calculatedValues"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Custom Variables Tab -->
        <v-tab-item>
          <v-container>
            <v-card flat>
              <v-card-text>
                <v-btn color="primary" class="mb-4" @click="addCustomVariable">
                  Add Variable
                </v-btn>

                <v-data-table
                  :headers="variableHeaders"
                  :items="model.custom_fields"
                  class="elevation-1"
                >
                  <template #actions="{ item }">
                    <v-icon small class="mr-2" @click="editVariable(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteVariable(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Custom Variable Dialog -->
    <v-dialog v-model="variableDialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ editedVariable.id ? 'Edit' : 'New' }} Variable
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="editedVariable.name"
                  label="Name"
                  dense
                  outlined
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedVariable.variable_name"
                  label="Variable Name"
                  dense
                  outlined
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedVariable.description"
                  label="Description"
                  dense
                  outlined
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12">
                <enhanced-formula-builder
                  v-model="editedVariable.formula"
                  :variables="formulaVariables"
                />
              </v-col>
              <v-col cols="12">
                <filters-select
                  :value.sync="editedVariable.channels"
                  label="Channels"
                  :items="channelsItems"
                  :selection-func="item => item.text"
                  :messages="['Leave empty to apply to all channels']"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeVariableDialog">Cancel</v-btn>
          <v-btn color="primary" @click="saveVariable">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { formulaVariables } from '@/consts'
import FormulaVariablesToolTip from 'components/common/formula-variables-tooltip.vue'
import ExpenseTypes from 'components/expense-types.vue'
import EnhancedFormulaBuilder from 'components/common/enhanced-formula-builder.vue'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import { omit } from 'lodash/fp'
import FiltersSelect from 'components/form-fields/filters-select'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import PnlPreview from 'components/business-model/pnl-preview.vue'
import SampleReservationData from './sample-reservation-data.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BusinessModelDetails',
  mixins: [FormRulesMixin],
  components: {
    FormulaVariablesToolTip,
    ExpenseTypes,
    EnhancedFormulaBuilder,
    FiltersSelect,
    MultiPropertyPicker,
    PnlPreview,
    SampleReservationData,
  },

  props: {
    value: Boolean,
    model: {
      type: Object,
    },
  },

  data() {
    return {
      dialog: this.value,
      activeTab: 0,
      variableDialog: false,
      loading: false,
      isFormulaEditable: false,
      editedVariable: {
        id: null,
        name: '',
        variable_name: '',
        description: '',
        formula: '',
        channels: [],
      },
      variableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Variable Name', value: 'variable_name' },
        { text: 'Formula', value: 'formula' },
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      sampleReservation: {
        fare_accommodation: 600,
        cleaning_fee: 150,
        host_channel_fee: 90,
        processing_fee: 25,
        host_payout: 510,
        total_payout: 660,
        client_price: 750,
        net_income: 510,
        total_taxes: 75,
        commission_percent: 0.1,
        adr: 200,
        company_fees: 45,
        management_commission: 120,
        guests_count: 2,
        nights_count: 3,
      },
      calculatedValues: {
        channelsIncome: 0,
      },
    }
  },

  computed: {
    ...mapGetters('reservation', ['channelsItems']),

    formulaVariables() {
      return formulaVariables.concat(
        this.model.custom_fields.map(v => ({
          description: v.description,
          value: v.variable_name,
          category: 'Custom Variables',
        }))
      )
    },
  },
  mounted() {
    this.calculateFormulas()
  },
  methods: {
    close() {
      this.dialog = false
      this.$emit('input', false)
    },

    calculateFormulas() {
      const netIncome = this.calculateFormula(
        this.model.net_income_formula,
        this.sampleReservation
      )
      this.sampleReservation.net_income = netIncome
      const mgmt = this.calculateFormula(
        this.model.management_commission_formula,
        this.sampleReservation
      )
      this.sampleReservation.management_commission = mgmt

      this.calculatedValues.channelsIncome = this.calculateFormula(
        this.model.owners_income_formula || 'fare_accommodation',
        this.sampleReservation
      )
    },
    async save() {
      try {
        this.loading = true
        const payload = omit(
          ['created_at', 'updated_at', 'tenant_id'],
          this.model
        )
        await this.$store.dispatch('businessModel/updateBusinessModel', payload)
        this.$emit('change')
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },

    handleExpenseTypesUpdate(expenseTypes) {
      this.model.expense_types = expenseTypes
    },

    addCustomVariable() {
      this.editedVariable = {
        id: null,
        name: '',
        variable_name: '',
        description: '',
        formula: '',
        channels: [],
      }
      this.variableDialog = true
    },

    editVariable(variable) {
      this.editedVariable = {
        ...variable,
        channels: variable.channels || [],
      }
      this.variableDialog = true
    },

    deleteVariable(variable) {
      const index = this.model.custom_fields.findIndex(
        v => v.id === variable.id
      )
      if (index > -1) {
        this.model.custom_fields.splice(index, 1)
      }
    },

    saveVariable() {
      if (this.editedVariable.id) {
        const index = this.model.custom_fields.findIndex(
          v => v.id === this.editedVariable.id
        )
        if (index > -1) {
          this.model.custom_fields.splice(index, 1, {
            ...this.editedVariable,
          })
        }
      } else {
        this.editedVariable.id = Date.now() // Temporary ID
        this.model.custom_fields.push({ ...this.editedVariable })
      }
      this.save()
      this.closeVariableDialog()
    },

    closeVariableDialog() {
      this.variableDialog = false
      this.editedVariable = {
        id: null,
        name: '',
        variable_name: '',
        description: '',
        formula: '',
        channels: [],
      }
    },

    formatValue(value) {
      if (typeof value === 'number') {
        if (value % 1 === 0) {
          return value.toString()
        }
        return this.toMoney(value)
      }
      return value
    },

    calculateFormula(formula, values) {
      if (!formula) return 0

      try {
        // Create a safe evaluation context with only the values we provide
        const context = { ...values }

        // Replace all variable names with their values
        let evaluatableFormula = formula
        Object.keys(context).forEach(key => {
          const regex = new RegExp(`\\b${key}\\b`, 'g')
          evaluatableFormula = evaluatableFormula.replace(regex, context[key])
        })

        // Evaluate the formula
        return eval(evaluatableFormula)
      } catch (error) {
        console.error('Formula evaluation error:', error)
        return 0
      }
    },
  },

  watch: {
    value(val) {
      this.dialog = val
    },
    dialog(val) {
      if (!val) {
        this.$emit('input', val)
      }
    },
    model: {
      handler(newVal) {
        this.calculateFormulas(newVal)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.sticky-preview {
  position: sticky;
  top: 24px;
}
</style>
