<template>
  <v-card elevation="0">
    <v-card
      v-show="!hidePicker"
      outlined
      class="d-flex justify-space-between py-4 px-8 rounded-lg mb-4"
    >
      <pre-checkin-stat
        label="Total Purchases"
        :value="purchases.length"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        label="Total Amount"
        :value="totalAmount"
        color="var(--v-success600-base)"
      />
    </v-card>
    <v-row>
      <v-col v-show="!hidePicker" cols="auto">
        <date-picker
          v-model="from"
          label="From"
          :max="to"
          :min="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() - 20))
            ).substr(0, 10)
          "
          @change="getPurchases"
        />
      </v-col>
      <v-col v-show="!hidePicker" cols="auto">
        <date-picker
          v-model="to"
          label="To"
          :min="from"
          :max="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() + 10))
            ).substr(0, 10)
          "
          @change="getPurchases"
        />
      </v-col>
    </v-row>
    <v-data-table
      group-by="reservation_id"
      :headers="tableHeaders"
      :items="purchases"
      :items-per-page="-1"
      :loading="storePurchasesLoading"
      hide-default-footer
      class="overflow-auto"
    >
      <template #group.header="{ items }">
        <div class="d-flex pa-2 align-center white">
          <span class="font-weight-medium text-subtitle-1 mr-1">
            Reservation:
          </span>
          <a
            :href="`/dashboard/sales-chat/${items[0].reservation.conversation_guesty_id}`"
            target="_blank"
            >{{ items[0].reservation.confirmation_code }}
          </a>
          <v-divider vertical class="mx-3" />
          <div class="text-caption">
            <span class="font-weight-medium mr-1">Charged/Refunded:</span>
            <span class="success--text">{{ purchasesSum(items) }}</span> /
            <span class="warning--text">{{ refunded(items) }}</span>
          </div>
        </div>
      </template>
      <template #item="{ item }">
        <tr>
          <td>
            <v-icon v-if="item.extra_data && item.extra_data.category"
              >mdi-lightning-bolt</v-icon
            >
            <span v-else class="mr-1">
              {{ item.store_item.name }}
            </span>
            {{ quickItem(item) }}
            <a
              v-if="
                item.payment_intent &&
                item.payment_intent.status !== 'succeeded'
              "
              class="blue--text mr-1"
              :href="itemUrl(item)"
              target="_blank"
              >Payment
            </a>
            <v-chip
              v-if="
                item.payment_intent &&
                item.payment_intent.status === 'succeeded'
              "
              outlined
              small
              class="mr-1"
              color="green"
            >
              Paid
            </v-chip>
            <a
              v-if="item.listing_tasks[0]"
              class="blue--text mr-1"
              :href="taskUrl(item)"
              target="_blank"
              >Task
            </a>
          </td>
          <td class="text-center">
            {{ parseDate(item.requested_date) }}
          </td>
          <td class="text-center">
            {{ item.guest_name }}
          </td>
          <td class="text-center">
            {{ parseDate(item.reservation.check_in) }}
          </td>
          <td class="text-center">
            {{
              item.payment_intent?.status === 'succeeded'
                ? toMoney(item.amount_charged || item.total_amount, {
                    currency,
                  })
                : '-'
            }}
          </td>
          <td class="text-center">{{ item.refund_amount.toFixed(2) }}</td>
          <td class="text-center">
            <span v-if="item.dvr_amount">
              {{ toMoney(item.dvr_amount, { currency }) }}
            </span>
          </td>
          <td class="text-center">
            <span v-if="item.dvr_amount">
              {{ toMoney(item.investor_amount, { currency }) }}
            </span>
          </td>
          <td class="text-center">
            <span v-if="item.contractor_price">
              {{ toMoney(item.contractor_price, { currency }) }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ toMoney(item.tax, { currency }) }}
            </span>
          </td>
          <td class="text-center">
            {{ item.status }}
          </td>
          <td
            v-if="item.payment_intent && hasAbility('refund')"
            class="text-center"
          >
            <refund-dialog
              v-if="
                item.payment_intent.status === 'succeeded' ||
                item.payment_intent.status === 'requires_capture'
              "
              :type="
                item.payment_intent.status === 'succeeded'
                  ? 'refund'
                  : item.payment_intent.status === 'requires_capture'
                  ? 'capture'
                  : 'cancel'
              "
              :total="item.total_amount"
              :intent-id="item.payment_intent.payment_intent_id"
              :metadata="{ purchase_id: item.id }"
              @success="onSuccess"
            />
          </td>
          <td v-if="item.status === 'requested'">
            <v-btn
              color="primary"
              x-small
              depressed
              class="ms-1"
              :loading="item.loading"
              @click="createPayment(item)"
            >
              Create Payment
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-if="pagiLength > 1"
      v-model="page"
      :length="pagiLength"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-4"
      @input="getPurchases"
    />
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import RefundDialog from 'components/stripe/refund-dialog'
import sumBy from 'lodash/fp/sumBy'
import compose from 'lodash/fp/compose'
import flatMap from 'lodash/fp/flatMap'
import PreCheckinStat from '@/components/crm/pre-checkin-stat.vue'

import { mapActions, mapState } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker.vue'

const getRefunds = compose(
  sumBy('amount'),
  flatMap('refunds.data'),
  flatMap('charges.data')
)

export default {
  components: {
    DatePicker,
    RefundDialog,
    PreCheckinStat,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['reservationId', 'guestId', 'hidePicker'],
  data() {
    return {
      from: this.parseISODateWithOffset(new Date()).substr(0, 10),
      to: this.parseISODateWithOffset(new Date()).substr(0, 10),
      headers: [
        { text: 'Item', align: 'center', sortable: false },
        {
          text: 'Scheduled at',
          align: 'center',
          sortable: false,
        },
        { text: 'Guest', align: 'center', sortable: false },
        { text: 'Check-in', align: 'center', sortable: false },
        { text: 'Amount', align: 'center', sortable: false },
        { text: 'Refund', align: 'center', sortable: false },
        {
          text: 'Dvr',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Investor',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Contractor',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Tax',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          isAdmin: true,
        },
      ],
      page: 1,
    }
  },
  methods: {
    ...mapActions(['getStorePurchases', 'createPaymentIntent']),

    createPayment(item) {
      item.loading = true
      this.createPaymentIntent({
        item_id: item.store_item_id,
        reservation_id: item.reservation.confirmation_code,
        count: item.count,
        purchase_id: item.id,
      }).then(() => {
        this.getStorePurchases({
          from: this.from,
          to: this.to,
          guest_id: this.guestId,
          page: this.page,
        })
      })
    },
    getPurchases() {
      this.getStorePurchases({
        from: this.from,
        to: this.to,
        guest_id: this.guestId,
        page: this.page,
      })
    },
    purchasesSum(items) {
      return sumBy(
        'total_amount',
        items.filter(i => i.payment_intent?.status === 'succeeded')
      ).toFixed(2)
    },
    refunded(items) {
      const intents = items.map(i => i.payment_intent || 0)
      return (getRefunds(intents) / 100 || 0).toFixed(2)
    },
    onSuccess() {
      this.$store.dispatch('getStorePurchases', {
        guest_id: this.guestId,
      })
    },
    itemUrl(item) {
      return `${process.env.VUE_APP_TOURIST_PORTAL_URL}/store-checkout/${item.payment_intent.payment_intent_id}?externalCheckout=true`
    },
    taskUrl(item) {
      return `/dashboard/listing-task/${item.listing_tasks[0].id}`
    },
    quickItem(item) {
      if (!(item.extra_data && item.extra_data.category)) return
      const { category, description } = item.extra_data
      return `${category} - ${description}`
    },
  },
  mounted() {
    this.getPurchases()
  },
  computed: {
    ...mapState({
      purchases: state => state.store.storePurchases,
      storePurchasesLoading: state => state.store.storePurchasesLoading,
      pagination: state => state.store.storePurchasesPagination,
    }),
    pagiLength() {
      return Math.ceil(
        this.pagination.purchases_count / this.pagination.per_page
      )
    },
    tableHeaders() {
      return this.headers.filter(h => (h.isAdmin ? this.isAdmin : true))
    },
    currency() {
      return this.purchases[0]?.reservation?.currency || 'USD'
    },
    totalAmount() {
      return this.toMoney(
        sumBy(
          'total_amount',
          this.purchases.filter(i => i.payment_intent?.status === 'succeeded')
        ),
        { currency: this.currency }
      )
    },
  },
}
</script>
