<template>
  <form-dialog
    ref="createSmartRule"
    :title="$t('Create Smart Rule')"
    :max-width="900"
    @submit="createSmartRule"
  >
    <template #activator="{ on }">
      <v-btn color="primary" class="text-capitalize" elevation="0" v-on="on">
        {{ $t('Add Rule') }}
      </v-btn>
    </template>
    <v-divider />
    <v-col cols="12" sm="8">
      <p class="v-label">
        When this listing is booked, block the same dates for:
      </p>
      <property-picker
        :not-top-header="true"
        :after-change="listingChanged"
        :value="newSmartRule.target_id"
      />
    </v-col>
    <v-col cols="12" sm="4">
      <v-checkbox
        v-model="newSmartRule.both_directions"
        label="Both directions"
        class="mt-0"
        dense
        hide-details
      />
    </v-col>
    <v-col cols="12" sm="4">
      <v-checkbox
        v-model="newSmartRule.retrospective"
        label="Apply retrospectively"
        class="mt-0"
        dense
        hide-details
      />
    </v-col>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import PropertyPicker from 'components/property-picker'

export default {
  name: 'SmartRulesCreateModal',
  components: { FormDialog, PropertyPicker },
  mixins: [FormRules, FormattersMixin],
  props: ['listing'],
  data() {
    return {
      newSmartRule: {
        target_id: null,
        source_id: null,
        both_directions: true,
        retrospective: true,
      },
    }
  },

  computed: {},
  methods: {
    listingChanged(val) {
      this.newSmartRule.target_id = val
    },
    async createSmartRule() {
      this.$store.commit('updateLoading', true)
      await this.$store
        .dispatch('smartRule/createSmartRule', {
          listing_id: this.listing.id,
          smart_rule: {
            target_id: this.newSmartRule.target_id,
            source_id: this.listing.id,
            both_directions: this.newSmartRule.both_directions,
            retrospective: this.newSmartRule.retrospective,
          },
        })
        .finally(
          this.$store.commit('updateLoading', false),
          this.$emit('fetchRules')
        )
      this.$emit('fetchRules')
    },
  },
}
</script>

<style scoped></style>
