<template>
  <v-sheet>
    <v-form ref="updateListing" lazy-validation @submit.prevent="updateListing">
      <v-container>
        <div class="d-flex justify-space-between align-center my-3">
          <div class="text-h6">{{ $t('Finance information') }}</div>
          <v-btn small color="primary" type="submit">Save </v-btn>
        </div>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="listing.prices_info.security_deposit_type"
              value="5"
              label="Deposit Type"
              :items="depositTypesItems"
              outlined
              dense
              :rules="[required]"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="listing.prices_info.securityDepositFee"
              label="Deposit amount"
              type="number"
              :min="1"
              :rules="[required]"
              outlined
              dense
            >
              <template #prepend-inner
                ><div class="currency-sign">{{ currencySign }}</div></template
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="listing.actual_cleaning"
              label="Cleaning Fee"
              type="number"
              :min="1"
              :rules="[required]"
              outlined
              dense
            >
              <template #prepend-inner
                ><div class="currency-sign">{{ currencySign }}</div></template
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model.number="listing.prices_info.extra_person_fee"
              label="Extra person fee"
              type="number"
              :min="1"
              outlined
              dense
            >
              <template #prepend-inner
                ><div class="currency-sign">
                  {{ currencySign }}
                </div></template
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model.number="listing.prices_info.damage_waiver_amount"
              label="Damage Waiver Amount"
              type="number"
              outlined
              dense
              :hint="'This will only be available on the guest portal if enabled.'"
              :rules="[v => !v || v >= 0 || 'Amount must be positive']"
            >
              <template #prepend-inner
                ><div class="currency-sign">{{ currencySign }}</div></template
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="defaultTaxProfile"
              label="Charge Profile"
              :items="taxProfiles"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="businessModel"
              label="Business Model"
              :items="businessModels"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-1 font-weight-medium mt-4">
              Tax Registration Information
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="listing.business_tax_id"
              label="Business Tax ID (Optional)"
              outlined
              dense
              hint="Required for tax collection purposes"
              persistent-hint
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="listing.tot_registration_id"
              label="TOT Registration ID (Optional)"
              outlined
              dense
              hint="Tourism Occupancy Tax registration number"
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-container>
      <listing-cancellation-policy v-model="cancellationPolicyBreakdown" />
    </v-form>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { mapGetters } from 'vuex'
import ListingCancellationPolicy from 'components/listing/listing-cancellation-policy.vue'

export default {
  name: 'ListingChannelFinance',
  mixins: [FormRules, FormattersMixin],
  components: { ListingCancellationPolicy },
  props: ['listing'],
  data() {
    return {
      taxProfiles: [],
      businessModels: [],
      businessModel: this.listing.business_model_id,
      defaultTaxProfile: this.listing.tax_profile_id,
      cancellationPolicyBreakdown:
        this.listing.extra_info['cancellation'] || [],
    }
  },
  async mounted() {
    this.fetchData()
  },
  computed: {
    ...mapGetters(['depositTypes']),
    depositTypesItems() {
      return Object.entries(this.depositTypes || []).map(([value, text]) => ({
        text,
        value,
      }))
    },
  },
  methods: {
    async updateListing() {
      if (this.$refs.updateListing.validate()) {
        this.$store.commit('updateLoading', true)
        await this.$store
          .dispatch('listings/updateListing', {
            id: this.$route.params.id,
            payload: {
              actual_cleaning: this.listing.actual_cleaning,
              tot_registration_id: this.listing.tot_registration_id,
              business_tax_id: this.listing.business_tax_id,
              tax_profile_id: this.defaultTaxProfile,
              business_model_id: this.businessModel,
              prices_info: {
                extra_person_fee: this.listing.prices_info.extra_person_fee,
                securityDepositFee: this.listing.prices_info.securityDepositFee,
                security_deposit_type:
                  this.listing.prices_info.security_deposit_type,
                damage_waiver_amount:
                  this.listing.prices_info.damage_waiver_amount,
              },
              extra_info: {
                cancellation: this.cancellationPolicyBreakdown,
              },
            },
          })
          .finally(this.$store.commit('updateLoading', false))
      }
    },
    async fetchTaxProfiles() {
      try {
        const { data } = await axios.get('/api/tax-profiles/stats')
        this.taxProfiles = data.tax_profiles.map(t => ({
          text: t.name,
          value: t.id,
        }))
      } catch (e) {
        console.error(e)
      }
    },
    async fetchData() {
      this.loadingData = true
      await this.fetchTaxProfiles()
      const models = await this.$store.dispatch(
        'businessModel/fetchBusinessModels'
      )
      this.businessModels = models.map(m => ({
        text: m.name,
        value: m.id,
      }))
      this.loadingData = false
    },
    addPolicy() {
      this.cancellationPolicyBreakdown.push({
        from: '',
        to: '',
        amount: '',
      })
    },
    removePolicy(idx) {
      this.cancellationPolicyBreakdown.splice(idx, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.currency-sign {
  font-size: 24px;
  padding-right: 4px;
}
</style>
