<template>
  <div>
    <div class="calendar-wrapper">
      <v-tabs v-if="!listingId" v-model="activeTab">
        <v-tab>
          <v-icon small left>mdi-calendar-month</v-icon>
          Calendar
        </v-tab>
        <v-tab v-if="managementRoles && !isBoomForHosts">
          <v-icon small left>mdi-block-helper</v-icon>
          Blocks
        </v-tab>
        <v-tab v-if="managementRoles && showOverbookingsTab && !isBoomForHosts">
          <v-icon small left>mdi-calendar-multiple</v-icon>
          Overbookings
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item href="#calendar">
          <v-row
            v-if="managementRoles"
            class="align-center py-1 px-2 p-relative"
            style="z-index: 1"
          >
            <template v-if="!listingId">
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="searchTerm"
                  label="Filter by address"
                  clearable
                  outlined
                  dense
                  hide-details
                  @keyup="debouncedOnKeyUp"
                  @click:clear="clearSearch"
                />
              </v-col>
              <v-col v-if="!isInvestor" class="select-col" cols="6" md="2">
                <v-combobox
                  v-model="selectedTags"
                  :items="tags"
                  label="Filter by tags"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  outlined
                  dense
                  hide-details
                  single-line
                  clearable
                  @change="onFilterChange"
                />
              </v-col>
              <v-col v-if="!isInvestor" class="select-col" cols="6" md="1">
                <v-select
                  v-model="region"
                  dense
                  outlined
                  hide-details
                  label="Regions"
                  clearable
                  :items="listingRegions"
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                v-if="!isInvestor"
                class="select-col search-listings"
                cols="4"
                md="2"
              >
                <v-dialog v-model="dialog" width="1200">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="blue-grey"
                      dense
                      outlined
                      hide-details
                      label="Regions"
                      clearable
                      append-icon="search"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left small class="pr-1">search</v-icon>
                      Search Listings
                    </v-btn>
                  </template>
                  <v-card class="pa-3">
                    <listings-search v-if="dialog" />
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col class="d-flex align-center">
                <v-checkbox
                  v-model="listedOnly"
                  hide-details
                  class="mt-0"
                  :label="$t('Listed Only')"
                  @change="onFilterChange"
                />
              </v-col>
              <v-switch
                v-if="!listingId"
                label="Calendar V2"
                hide-details
                :input-value="true"
                class="ml-3 mt-0"
                @change="onCalendarChange"
              />
            </template>

            <v-col class="ml-auto px-2" md="auto">
              <v-btn small primary="info" outlined @click="goToday"
                >today</v-btn
              >
              <date-picker
                style="z-index: 100"
                :icon="true"
                class="date-picker"
                label="Date"
                text-field-class="mx-2"
                @change="goToDate"
              />
              <v-btn icon @click="onDateNavigation(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="font-weight-medium text-subtitle-1 mx-0">{{
                calApi ? calApi.view.title : ''
              }}</span>
              <v-btn icon @click="onDateNavigation(1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="isPersonnel"
            class="align-center py-1 px-2 p-relative"
            style="z-index: 1"
          >
            <v-col class="d-flex align-center">
              <v-checkbox
                v-model="listedOnly"
                hide-details
                class="mt-0"
                :label="$t('Listed Only')"
                @change="onFilterChange"
              />
            </v-col>
          </v-row>
          <conversation-drawer :keep-expanded="true" />
          <v-progress-linear
            v-if="listingsLoading"
            color="primary"
            indeterminate
          />
          <full-calendar
            id="multi-cal"
            ref="calendar"
            :options="calendarOptions"
          >
            <template #resourceLabelContent="{ resource }">
              <router-link
                :to="
                  isInvestor
                    ? `/dashboard/property/${resource.id}`
                    : `/dashboard/edit/${resource.id}`
                "
                :target="isMobile ? '_self' : '_blank'"
                class="text-decoration-none text-body-2 black950--text"
                :class="{
                  'small-text': isMobile,
                  'font-weight-black': resource.extendedProps.isHotel,
                  'font-weight-semibold': resource.extendedProps.isMultiUnit,
                }"
              >
                <span>
                  {{ resource.title }}
                </span>
              </router-link>
            </template>
            <template #slotLabelContent="{ date, text }">
              <div class="label-container">
                <div class="text-caption font-weight-medium black950--text">
                  {{ text }}
                </div>
                <div class="text-caption black950--text">
                  {{ parseDate(date, 'M.D', { local: true }) }}
                </div>
              </div>
            </template>
            <template #eventContent="{ event }">
              <div
                class="event-reservation-content d-flex align-center overflow-hidden px-2"
              >
                <v-badge
                  v-if="event.extendedProps.source"
                  :value="!!event.extendedProps.balanceDue"
                  color="error"
                  overlap
                  offset-y="13"
                  offset-x="12"
                  icon="mdi-currency-usd"
                >
                  <span class="white rounded-xl pa-1">
                    <v-icon small>{{
                      SOURCE_ICONS[event.extendedProps.source] || '$manual'
                    }}</v-icon>
                  </span>
                </v-badge>

                <v-tooltip
                  v-if="event.extendedProps.isOtaBlock"
                  max-width="300"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      class="ml-2 font-weight-medium"
                      v-on="on"
                    >
                      <v-icon small class="mr-1">fas fa-lock</v-icon>
                    </span>
                  </template>
                  <div>
                    <div
                      v-if="event.title"
                      class="font-weight-medium text-subtitle-2"
                    >
                      {{ event.title }}
                    </div>
                    <div class="text-caption">
                      OTA blocks appear on the booking calendar when specific
                      dates are blocked on external channels like Airbnb or
                      Booking.com. This can happen due to data migration or
                      manual adjustments made by users on our dashboard. Note
                      that these blocks reflect the status from the OTA and can
                      only be removed by creating a manual reservation on our
                      dashboard.
                    </div>
                  </div>
                </v-tooltip>
                <span v-else-if="event.extendedProps.isBlocked">
                  <v-icon small>fas fa-lock</v-icon>
                </span>
                <span
                  :title="event.title"
                  class="ml-2 font-weight-medium ellipsis-1"
                >
                  {{ event.title }}
                </span>
              </div>
            </template>
            <template #resourceLaneContent="{ resource }">
              <v-sheet
                class="d-flex justify-space-between p-absolute"
                width="100%"
                height="100%"
              >
                <v-sheet
                  v-for="(item, ind) in dateRangeArr"
                  :key="ind"
                  width="3.5%"
                  height="100%"
                  class="text-end text-caption d-flex align-center justify-center p-relative flex-column"
                  :class="{
                    'multi-unit': resource.extendedProps.isMultiUnit,
                    hotel: resource.extendedProps.isHotel,
                  }"
                >
                  <div v-if="resource.extendedProps.isHotel">
                    {{ getHotelAvailable(resource, item) }} /
                    {{ getHotelAll(resource) }}
                  </div>
                  <div v-else-if="resource.extendedProps.isMultiUnit">
                    {{ getMultiAvailable(resource, item) }} /
                    {{ resource.getChildren().length }}
                  </div>
                  <div v-else-if="canViewPrices">
                    {{
                      Math.round(
                        daysRates[resource.id]?.days_rates[item]?.price
                      ) || ''
                    }}
                  </div>
                  <div
                    v-if="
                      !resource.extendedProps.isMultiUnit &&
                      daysRates[resource.id]?.days_rates[item]?.minNights
                    "
                    class="min-nights"
                  >
                    <v-icon size="8" class="mr-1">fas fa-moon</v-icon
                    >{{ daysRates[resource.id]?.days_rates[item]?.minNights }}
                  </div>
                </v-sheet>
              </v-sheet>
            </template>
          </full-calendar></v-tab-item
        >
        <v-tab-item href="#blocks">
          <listings-blocks />
        </v-tab-item>
        <v-tab-item v-if="showOverbookingsTab" href="#overbookings">
          <reservation-manager :overbooking-only="true"
        /></v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import DatePicker from 'components/form-fields/date-picker'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConversationDrawer from 'components/crm/conversation-drawer.vue'
import ListingsBlocks from '../crm/listings-blocks.vue'
import ReservationManager from 'components/reservation-manager'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/fp/isEqual'
import TagsMixin from 'components/mixins/tags-mixin'
import { keyBy, sum, uniqBy } from 'lodash/fp'
import { mapGetters, mapState } from 'vuex'

import {
  processResources,
  processEvents,
  initializeScroller,
  SOURCE_ICONS,
  filterResources,
} from './utils'

export default {
  name: 'MultiCal',
  components: {
    FullCalendar,
    ConversationDrawer,
    ListingsBlocks,
    ReservationManager,
    DatePicker,
  },
  mixins: [
    FormattersMixin,
    DeviceMixin,
    CommonFunctions,
    PermissionsMixin,
    TagsMixin,
  ],
  props: ['listingId'],
  data() {
    return {
      SOURCE_ICONS,
      from: null,
      to: null,
      searchTerm: '',
      selectedTags: [],
      tags: [],
      region: null,
      activeTab: 0,
      cachedEvents: {},
      listings: [],
      daysRates: {},
      listingsLoading: false,
      resources: [],
      calApi: null,
      dialog: false,
      listedOnly: this.listingId ? false : true,
      debounceFetchEvents: null,
      debouncedOnKeyUp: debounce(this.onFilterChange, 100),
      calendarOptions: {
        height: 'auto',
        plugins: [resourceTimelinePlugin, interactionPlugin],
        initialView: 'resourceTimelineMonth',
        schedulerLicenseKey: '0765990167-fcs-1637703520',
        resourceOrder: '-resourceOrder,title',
        events: [],
        resources: [],
        views: {
          resourceTimelineMonth: {
            duration: { weeks: 8 },
          },
        },
        resourceLabelClassNames({ resource }) {
          const classNames = []
          const { isMultiUnit, isHotel } = resource.extendedProps
          if (isMultiUnit) classNames.push('multi-unit')
          if (isHotel) classNames.push('hotel')
          return classNames
        },
        slotLabelFormat: [{ weekday: 'short' }],
        headerToolbar: false,
        resourceAreaWidth: this.isMobile ? 120 : 175,
        eventClick: this.onEventClick,
        selectable: true,
        select: this.openBlockModal,
      },
    }
  },
  computed: {
    ...mapState('regions', ['regions']),
    ...mapGetters(['currentUser', 'weekStartsAt']),
    timezone() {
      return this.$store.state.app.configuration.timezone
    },
    listingRegions() {
      return this.regions.map(r => ({ text: r.name, value: r.id }))
    },
    canViewPrices() {
      return (
        !this.isCleaner &&
        !this.isContractor &&
        !this.isCleaningManager &&
        !this.isCleaningSupervisor
      )
    },
    isPersonnel() {
      return (
        this.isCleaner ||
        this.isContractor ||
        this.isCleaningManager ||
        this.isCleaningSupervisor
      )
    },
    showOverbookingsTab() {
      return this.$route.path === '/dashboard/multi-cal'
    },
    processedResources() {
      return processResources(this.listings)
    },
    dateRangeArr() {
      const { currentStart, currentEnd } = this.calApi.view
      const from = this.parseDate(currentStart, 'YYYY-MM-DD', { local: true })
      const to = this.parseDate(currentEnd, 'YYYY-MM-DD', { local: true })
      return this.generateDateRangeArray(from, to)
    },
  },
  methods: {
    onCalendarChange() {
      this.$router.push('/dashboard/multi-calendar?calendar_v2=true')
    },
    openBlockModal(data) {
      if (!this.canViewPrices || this.isInvestor) return
      this.$store.commit('showModal', {
        name: 'CalendarBlockModal',
        props: {
          from: data.startStr,
          to: data.endStr,
          note: '',
          listingId: +data.resource.id,
          isMultiUnit: data.resource.extendedProps.isMultiUnit,
          minNights: null,
          rates: this.daysRates[data.resource.id].days_rates,
          status: 'available',
          onChange: this.updateRates,
          onReservationCreate: this.onReservationCreate,
        },
      })
    },
    updateRates(listings) {
      const resources = []
      listings.forEach(listing => {
        this.daysRates[listing.id].days_rates = listing.days_rates
        const resource = this.calApi.getResourceById(listing.id)
        resources.push(resource)
      })
      this.fetchEvents(resources, true)
    },
    onFilterChange() {
      const query = {}
      if (this.region) query.region = this.region
      if (this.listedOnly) query.listedOnly = this.listedOnly
      if (!isEqual(this.$route.query, query)) {
        this.$router.push({ ...this.$route.query, query })
      }
      this.calendarOptions.resources = filterResources(
        this.processedResources,
        {
          searchTerm: this.searchTerm,
          selectedTags: this.selectedTags,
          region: this.region,
          listedOnly: this.listedOnly,
          listingId: this.listingId,
        }
      )
      this.getData()
    },
    clearSearch() {
      this.searchTerm = ''
      this.onFilterChange()
    },
    loadScroller() {
      initializeScroller({
        calendarId: 'multi-cal',
        calApi: this.calApi,
        onVisibleResourcesChange: resources => {
          this.debounceFetchEvents(resources)
        },
      })
      this.getData()
    },
    async fetchListings() {
      this.listingsLoading = true
      const response = await this.$store.dispatch(
        'listingCalendar/fetchMultiCalListings'
      )
      this.listings = response

      this.listingsLoading = false
      this.calendarOptions.resources = this.processedResources
      this.onFilterChange()
    },
    async fetchEvents(resources, forceFetch = false) {
      const listingIds = resources.map(resource => resource.id)
      const { currentStart, currentEnd } = this.calApi.view

      const from = this.parseDate(currentStart, 'YYYY-MM-DD', { local: true })
      const to = this.parseDate(currentEnd, 'YYYY-MM-DD', { local: true })

      if (!this.cachedEvents[`${from}-${to}`]) {
        this.cachedEvents[`${from}-${to}`] = []
      }
      const newListingIds = forceFetch
        ? listingIds
        : listingIds.filter(
            id => !this.cachedEvents[`${from}-${to}`].includes(id)
          )

      if (newListingIds.length === 0) {
        return console.log('All listings already cached for this date range')
      }
      this.listingsLoading = true

      this.cachedEvents[`${from}-${to}`].push(...newListingIds)
      const allRatesFetched = newListingIds.every(id => this.daysRates[id])

      const params = {
        listing_ids: newListingIds,
        from,
        to,
        exclude_rates: allRatesFetched,
      }

      const data = await this.$store.dispatch(
        'listingCalendar/fetchMultiCalDates',
        params
      )
      if (allRatesFetched) {
        data.listings = newListingIds.map(id => this.daysRates[id])
      }
      const existingEvents = forceFetch ? [] : this.calendarOptions.events
      const events = [...existingEvents, ...processEvents(data, from, to)]

      this.calendarOptions.events = uniqBy('id', events)
      this.daysRates = { ...this.daysRates, ...keyBy('id', data.listings) }
      this.listingsLoading = false
    },
    onEventClick({ event }) {
      if (event.extendedProps.isBlocked) {
        const data = {
          startStr: event.startStr,
          endStr: event.endStr,
          resource: {
            id: event.extendedProps.listingId,
            extendedProps: {
              isMultiUnit: event.extendedProps.isMultiUnit,
            },
          },
        }
        return this.openBlockModal(data)
      }
      const { events } = this.calendarOptions
      const reservation = events.find(e => e.id === +event.id)

      if (this.isInvestor || this.isCleaner || this.isCleaningManager) {
        return this.$store.commit('showModal', {
          name: 'ReservationCardModal',
          props: { reservation, timezone: this.timezone },
          isPersistent: false,
        })
      }
      this.$router.push({
        query: {
          reservation: reservation.reservation_guesty_id,
          conversation: reservation.conversation.guesty_id,
        },
      })
    },
    getMultiAvailable(resource, dataRangeItem) {
      return resource.getChildren().reduce((res, listing) => {
        const item = this.daysRates[listing.id]?.days_rates[dataRangeItem]
        if (item && item.status === 'available') res += 1
        return res
      }, 0)
    },
    getHotelAvailable(resource, dataRangeItem) {
      const hasMulti = resource.getChildren()[0].extendedProps.isMultiUnit
      if (!hasMulti) return this.getMultiAvailable(resource, dataRangeItem)

      const availables = resource.getChildren().map(multiResource => {
        return this.getMultiAvailable(multiResource, dataRangeItem)
      })
      return sum(availables)
    },
    getHotelAll(resource) {
      const hasMulti = resource.getChildren()[0].extendedProps.isMultiUnit
      if (!hasMulti) return resource.getChildren().length

      const multiLengths = resource
        .getChildren()
        .map(multiResource => multiResource.getChildren().length)
      return sum(multiLengths)
    },
    onDateNavigation(change) {
      if (change > 0) {
        this.calApi.next()
      } else {
        this.calApi.prev()
      }
      this.assignDates()
      // Pass false for skipRouteUpdate to ensure query updates
      this.getData()
      this.calApi.render()
    },
    goToday() {
      this.calApi.today()
      var scrollTime = this.$moment().format('HH:mm:ss')
      this.calApi.scrollToTime(scrollTime)
      this.assignDates()
      this.page = 1
      // Pass false for skipRouteUpdate to ensure query updates
      this.getData()
      this.calApi.render()
    },
    goToDate(date) {
      this.calApi.gotoDate(date)
      const scrollTime = this.$moment(date).format('HH:mm:ss')
      this.calApi.scrollToTime(scrollTime)
      this.assignDates()
      this.page = 1
      // Pass false for skipRouteUpdate to ensure query updates
      this.getData()
      this.calApi.render()
    },
    assignDates() {
      this.from = this.parseDate(this.calApi.view.currentStart, 'YYYY-MM-DD', {
        local: true,
      })
      this.to = this.parseDate(this.calApi.view.currentEnd, 'YYYY-MM-DD', {
        local: true,
      })
    },
    getData() {
      this.$nextTick(() => {
        const el = document.getElementById('multi-cal')
        el.dispatchEvent(new Event('scroll'))
      })
    },
  },
  async mounted() {
    this.calApi = this.$refs.calendar.getApi()
    const params = this.$route.query
    if (params.region) this.region = Number(params.region)
    if (params.listedOnly === 'false') this.listedOnly = false
    await this.fetchListings()
    this.loadScroller()
    this.debounceFetchEvents = debounce(this.fetchEvents, 100)
    this.fetchTags(true)
  },
}
</script>

<style scoped>
#multi-cal {
  overflow: auto;
  height: calc(100vh - 160px);
}
#multi-cal >>> .fc-day-today {
  background-color: #44a2a229 !important;
}
#multi-cal >>> .fc-timeline-event:not(.fc-event-end):after,
#multi-cal >>> .fc-timeline-event:not(.fc-event-start):before {
  display: none !important;
}
#multi-cal >>> .event-reservation {
  position: relative;
  transform: skew(-22deg) translateX(0px);
  margin: 7px -13px 0 22px;
}
#multi-cal >>> .reservation-bar {
  background-color: #7ebac0a8 !important;
}

#multi-cal >>> .reserved {
  background-color: #e3d2f5 !important;
}

#multi-cal >>> .confirmed {
  background: linear-gradient(
    135deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten1) 50%,
    var(--v-primary-base) 100%
  ) !important;
  background-size: 200% 200% !important;
}

#multi-cal >>> .canceled {
  background-color: #cf8787a8 !important;
}
#multi-cal >>> .event-reservation-content {
  height: 30px;
  transform: skew(22deg);
}

#multi-cal >>> .block-bg {
  border: none;
  background-color: rgba(64, 59, 59, 0.99);
}

#multi-cal >>> .availability-block {
  border: none;
  /*background-color: #eee; !* Light grey background *!*/
  background-image: repeating-linear-gradient(
    115deg,
    #ddd,
    #ddd 10px,
    #ffffff 10px,
    #ffffff 20px
  );
  color: black !important;
}
#multi-cal >>> .availability-block.fc-h-event .fc-event-main {
  color: black !important;
}

#multi-cal >>> .fc-timeline-header-row > .fc-day-today .label-container {
  background: var(--v-black950-base) !important;
  border-radius: 6px;
  width: 50px;
  color: white;
}
#multi-cal >>> .fc-timeline-lane-frame,
#multi-cal >>> .fc-datagrid-cell-frame {
  min-height: 53px;
}

#multi-cal >>> .fc-timeline-header-row > .fc-day-today .text-caption {
  color: white !important;
}
#multi-cal >>> .min-nights {
  font-size: 10px;
  display: flex;
}
.search-listings {
  max-width: 224px;
}
#multi-cal >>> .hotel {
  background-color: var(--v-light400-base) !important;
}

#multi-cal >>> .multi-unit {
  background-color: var(--v-light300-base) !important;
}
</style>
