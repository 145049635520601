<template>
  <v-card class="pnl-preview pa-4" outlined>
    <v-card-title class="text-h6 px-0">
      {{ $t('PnL Preview') }}
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" small class="ml-2" v-on="on">
            mdi-information
          </v-icon>
        </template>
        <span>{{ $t('Sample calculation based on average values') }}</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text class="px-0">
      <v-alert
        v-if="showNetIncomeWarning"
        dense
        text
        type="warning"
        class="mb-4"
      >
        <div class="text-caption">
          <strong>{{ $t('Potential Double Counting') }}</strong>
          <div v-if="!hideChannelFee">
            {{
              $t(
                'Your net_income formula may already include channel fee deductions. Consider hiding channel fees to avoid double-counting.'
              )
            }}
          </div>
          <div v-if="showOwnerCleaning">
            {{
              $t(
                'Your net_income formula may already include cleaning fee. Consider disabling owner cleaning to avoid double-counting.'
              )
            }}
          </div>
        </div>
      </v-alert>

      <!-- Income Section -->
      <div class="d-flex flex-column">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="text-subtitle-1 font-weight-medium">
            <v-icon color="success" small class="mr-2">
              mdi-arrow-up-circle
            </v-icon>
            {{ $t('Income') }}
          </div>
          <div class="success--text text-h6">
            {{ toMoney(totalIncome) }}
          </div>
        </div>

        <v-list dense class="transparent">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Channels') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-right">
              {{ toMoney(channelsIncome) }}
            </v-list-item-action>
          </v-list-item>

          <v-list-item v-if="showOwnerCleaning">
            <v-list-item-content>
              <v-list-item-title>{{ $t('Cleaning Fee') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-right">
              {{ toMoney(sampleReservation.cleaning_fee) }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <!-- Expenses Section -->
      <v-divider class="my-4" />

      <div class="d-flex flex-column">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="text-subtitle-1 font-weight-medium">
            <v-icon color="warning" small class="mr-2">
              mdi-arrow-down-circle
            </v-icon>
            {{ $t('Expenses') }}
          </div>
          <div class="warning--text text-h6">
            {{ toMoney(totalExpenses) }}
          </div>
        </div>

        <v-list dense class="transparent">
          <v-list-item v-if="!hideChannelFee">
            <v-list-item-content>
              <v-list-item-title>{{ $t('Channel Fee') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-right">
              {{ toMoney(channelFee) }}
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('Management Commission')
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-right">
              {{ toMoney(managementCommission) }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <!-- Net Section -->
      <v-divider class="my-4" />

      <div class="d-flex justify-space-between align-center">
        <div class="text-subtitle-1 font-weight-medium">
          {{ $t('Balance') }}
        </div>
        <div
          :class="netIncome >= 0 ? 'success--text' : 'warning--text'"
          class="text-h5 font-weight-bold"
        >
          {{ toMoney(netIncome) }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'PnlPreview',

  mixins: [FormattersMixin],

  props: {
    model: {
      type: Object,
      required: true,
    },
    sampleReservation: {
      type: Object,
      required: true,
    },
    calculatedValues: {
      type: Object,
      required: true,
    },
  },

  computed: {
    showOwnerCleaning() {
      return this.model.show_owner_cleaning
    },

    hideChannelFee() {
      return this.model.hide_channel_fee_expense
    },

    showNetIncomeWarning() {
      const hasNetIncome = this.model.owners_income_formula
        ?.toLowerCase()
        .includes('net_income')
      return hasNetIncome && (!this.hideChannelFee || this.showOwnerCleaning)
    },

    channelsIncomeSource() {
      return this.model.owners_income_formula
        ? this.$t('Custom Formula')
        : this.$t('Fare Accommodation')
    },

    channelsIncome() {
      return (
        this.calculatedValues.channelsIncome ||
        this.sampleReservation.fare_accommodation
      )
    },

    channelFee() {
      return this.sampleReservation.host_channel_fee
    },

    managementCommission() {
      return this.sampleReservation.management_commission || 0
    },

    totalIncome() {
      let total = this.channelsIncome
      if (this.showOwnerCleaning) {
        total += this.sampleReservation.cleaning_fee
      }
      return total
    },

    totalExpenses() {
      let total = this.managementCommission
      if (!this.hideChannelFee) {
        total += this.channelFee
      }
      return total
    },

    netIncome() {
      return this.totalIncome - this.totalExpenses
    },
  },

  methods: {
    toPercent(value) {
      return `${value}%`
    },
  },
}
</script>

<style lang="scss" scoped>
.pnl-preview {
  max-width: 400px;
  margin-top: 0;

  ::v-deep .v-list-item {
    min-height: 32px;
  }

  ::v-deep .v-list-item__title {
    font-size: 0.875rem;
  }
}
</style>
