<template>
  <v-container>
    <v-row>
      <v-col
        v-for="integration in channelManagers"
        :key="integration.id"
        cols="12"
        sm="6"
        md="4"
        xl="3"
      >
        <v-sheet
          height="320"
          elevation="2"
          class="d-flex flex-column align-center rounded-lg pa-6 ba-1 p-relative"
          :class="{
            'cursor-pointer': integration.name.toLowerCase() !== 'airbnb',
          }"
          @click="handleIntegrationClick(integration)"
        >
          <v-sheet
            width="100%"
            class="d-flex justify-space-between align-center mb-6"
          >
            <div class="text-h5 font-weight-medium text-capitalize">
              {{ integration.title }}
            </div>
            <v-icon small :color="integration.active ? 'success' : 'error'">
              fas fa-circle
            </v-icon>
          </v-sheet>

          <div class="flex-grow-1 d-flex align-center">
            <v-img
              :width="100"
              :src="
                require('@/assets/images/integrations/' +
                  integration.name.toLowerCase() +
                  '.png')
              "
              class=""
              contain
            />
          </div>

          <v-sheet width="100%" class="stats-grid">
            <div class="stat-item">
              <div class="text-subtitle-2 text-medium-emphasis">Published</div>
              <div class="text-h5 font-weight-medium success--text">
                {{ integration.stats.mapped_and_published || 0 }}
              </div>
            </div>
            <div class="stat-item">
              <div class="text-subtitle-2 text-medium-emphasis">Unmapped</div>
              <div class="text-h5 font-weight-medium warning--text">
                {{ integration.stats.unmapped || 0 }}
              </div>
            </div>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showChannelTable"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showChannelTable = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ selectedIntegration?.title }}
          </v-toolbar-title>
        </v-toolbar>

        <channel-management-table
          v-if="showChannelTable"
          :selected-channel-manager="selectedIntegration?.id"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ChannelManagementTable from '../channel-settings/channel-management-table.vue'

export default {
  name: 'DistributionPage',

  components: {
    ChannelManagementTable,
  },

  data() {
    return {
      showChannelTable: false,
      selectedIntegration: null,
      channelManagers: [],
    }
  },

  computed: {
    ...mapGetters(['marketplaceIntegrations', 'integrations']),
  },

  async mounted() {
    await this.loadChannelManagers()
  },

  methods: {
    ...mapActions('listings', ['getCombinedListings', 'getChannelManagers']),

    async loadChannelManagers() {
      try {
        const response = await this.getChannelManagers()
        this.channelManagers = response.data.data
      } catch (error) {
        console.error('Error loading channel managers:', error)
      }
    },

    handleIntegrationClick(integration) {
      if (integration.name.toLowerCase() === 'airbnb') {
        // TODO: Show Airbnb accounts management component
        return
      }

      this.selectedIntegration = integration
      this.showChannelTable = true
    },
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-top: 24px;
}

.stat-item {
  text-align: center;
}

.stat-item .text-subtitle-2 {
  font-size: 0.875rem;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.stat-item .text-h5 {
  font-size: 1.5rem;
  line-height: 1.2;
}
</style>
